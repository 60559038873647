import { useEffect } from 'react'
import { Modal, ProgressBar, Spinner } from 'react-bootstrap'

interface LoadingOverlayModalProps {
    show: boolean
    text?: string
    percent: number
}

export default function LoadingOverlayModal(props: LoadingOverlayModalProps) {
    useEffect(() => {
        console.debug('Mounted loading overlay modal!')
    }, [])

    return (
        <>
            <Modal
                className="d-flex justify-content-center align-items-center"
                contentClassName="bg-transparent border-0"
                backdropClassName="opacity-75 remotive-primary-90-background"
                show={props.show}
                backdrop="static"
            >
                <div className="text-center lexend-regular">
                    <p className="fs-5 m-3 text-light">{props.text === undefined ? 'Loading...' : props.text}</p>
                    {props.percent === 0 && <Spinner variant="white" />}
                    {props.percent > 0 && (
                        <ProgressBar
                            className={`remotive-primary-0-color m-2`}
                            label={`${props.percent}%`}
                            now={props.percent}
                            max={100}
                        />
                    )}
                </div>
            </Modal>
        </>
    )
}
