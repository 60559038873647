import { useEffect, useRef } from 'react'

export default function useInterval(callback: Function, intervalMs: number) {
    const savedCallback = useRef<Function>()

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback
    }, [callback])

    // Set up the interval.
    useEffect(() => {
        function tick() {
            if (savedCallback?.current !== undefined) {
                savedCallback.current()
            }
        }
        if (intervalMs !== null) {
            let id = setInterval(tick, intervalMs)
            return () => clearInterval(id)
        }
    }, [intervalMs])
}
