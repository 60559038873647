import { StoredDashboard } from './Types'

export function storeDashboard(recordingSessionId: string, dashboard: StoredDashboard) {
    try {
        const storage = window.sessionStorage
        const storageKey = dashboardStorageKey(recordingSessionId)
        storage.setItem(storageKey, JSON.stringify(dashboard))
    } catch (e: any) {}
}

export function getStoredDashboard(recordingSessionId: string) {
    const storage = window.sessionStorage
    const storageKey = dashboardStorageKey(recordingSessionId)
    const storedDashboard = storage.getItem(storageKey)
    if (storedDashboard !== null) {
        try {
            const dashboard: StoredDashboard = JSON.parse(storedDashboard)
            return dashboard
        } catch (e: any) {
            console.warn('Failed to parse StoredDashboard, removing the stored data for this key')
            storage.removeItem(storageKey)
        }
    }
    return undefined
}

const dashboardStorageKey = (recordingSessionId: string) => {
    return `analytics-dashboard-${recordingSessionId}`
}
