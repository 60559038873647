import { Accordion } from 'react-bootstrap'
import TerminalIcon from '@mui/icons-material/TerminalRounded'
import { ContentCopyRounded } from '@mui/icons-material'
import RLCard from './cards/RLCard'
import { ExternalLinkIcon } from '../assets/Icons'

export type CliHint = {
    title: string
    subtitle?: string
    command: string
}

interface CliHintContainerProperties {
    hints: Array<CliHint>
}

export default function CliHintContainer(props: CliHintContainerProperties) {
    const hint = (hint: CliHint) => {
        return (
            <div className={'text-start mt-2 mb-0 pb-1'} key={hint.command}>
                <p className={'remotive-font-md p-0 m-0'}>{hint.title}</p>
                {hint.subtitle && <p className={'remotive-font-sm text-secondary p-0 m-0'}>{hint.subtitle}</p>}
                <pre style={{ borderLeft: '3px solid #4375a5' }} className={'m-1 ms-0 p-1 text-wrap'}>
                    {hint.command}{' '}
                    <button
                        className="remotive-btn-sm border-0"
                        onClick={() => navigator.clipboard.writeText(hint.command)}
                    >
                        <ContentCopyRounded sx={{ fontSize: '1.1em' }} className="remotive-btn-copy" />
                    </button>
                </pre>
            </div>
        )
    }

    const getBody = () => {
        return (
            <div className={'flex-box justify-content-end w-100'}>
                <Accordion flush className="p-2 px-3">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <div className="d-flex align-items-center">
                                <TerminalIcon className="me-2" />
                                <p className={'remotive-font-md m-0 p-0'}>CLI Hints</p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className={'flex-box align-content-start'}>
                            <>
                                {props.hints.map(hint)}
                                <a
                                    className={' text-decoration-none'}
                                    href={'https://docs.remotivelabs.com/docs/remotive-cli'}
                                    target={'_blank'}
                                >
                                    <div className="d-flex align-items-center">
                                        <ExternalLinkIcon sx={{ fontSize: 15 }} className="me-2" />
                                        <p className="mb-0 remotive-font-sm text-start">CLI docs</p>
                                    </div>
                                </a>
                            </>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        )
    }

    return (
        <div className="mt-4">
            <RLCard minHeight={0} body={getBody()} />
        </div>
    )
}
