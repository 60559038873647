import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { object, string } from 'yup'
import { Modal, Form, InputGroup, Spinner } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import { CreateProjectServiceAccountDTO, Project } from '../../api/CloudApi/types'
import { ErrorIcon } from '../../assets/Icons'
import { CLOUD_RESOURCE_VALIDATION_REGEX_PATTERN } from '../../validation/CloudValidation'
import { Formik } from 'formik'
interface CreateProjectUserModalProps {
    currentProject: Project | undefined
    reloadServiceAccountsList: () => void
    show: boolean
    handleCloseFunction: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

const OWNER_ROLE = 'project/admin'
const EDITOR_ROLE = 'project/user'
const VIEWER_ROLE = 'project/viewer'

const SERVICE_ACCOUNT_SCHEMA = object().shape({
    name: string()
        .required('You must provide a name')
        .matches(new RegExp(CLOUD_RESOURCE_VALIDATION_REGEX_PATTERN), 'Not a valid service account name'),
})

export default function CreateProjectUserModal(props: CreateProjectUserModalProps) {
    const [selectedRole, setSelectedRole] = useState<'project/admin' | 'project/user' | 'project/viewer'>(VIEWER_ROLE)

    const [accountCreationState, setAccountCreationState] = useState<'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR'>(
        'NOT_STARTED'
    )

    useEffect(() => {
        console.debug('Mounted created service account modal!')
    }, [])

    useEffect(() => {
        if (accountCreationState === 'DONE') {
            closeModal()
            props.reloadServiceAccountsList()
        }
    }, [accountCreationState])

    const validateInput = (): boolean => {
        if (selectedRole === undefined) {
            toast.error('You must select a role')
            return false
        }
        return true
    }

    const resetState = () => {
        setSelectedRole(VIEWER_ROLE)
        setAccountCreationState('NOT_STARTED')
    }

    const createServiceAccountUser = async (name: string) => {
        const projectUid = props.currentProject?.uid
        if (validateInput() && selectedRole && projectUid) {
            setAccountCreationState('IN_PROGRESS')
            const dto = { name, roles: [selectedRole] } as CreateProjectServiceAccountDTO
            try {
                await CloudApi.createProjectServiceAccount(projectUid, dto)
                toast.success(`Successfully created the service account ${name}`)
                setAccountCreationState('DONE')
            } catch (err) {
                setAccountCreationState('ERROR')
                toast.error('Failed to create service account')
            }
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    const rolesList = () => {
        return (
            <>
                <div>
                    <Form.Check
                        type="radio"
                        checked={selectedRole === OWNER_ROLE}
                        className="remotive-font-md"
                        label="Owner"
                        onChange={() => setSelectedRole(OWNER_ROLE)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2"> Full project access and can delete project</p>
                </div>
                <div>
                    <Form.Check
                        type="radio"
                        className="remotive-font-md"
                        checked={selectedRole === EDITOR_ROLE}
                        label="Editor"
                        onChange={() => setSelectedRole(EDITOR_ROLE)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">Full project access</p>
                </div>
                <div>
                    <Form.Check
                        type="radio"
                        className="remotive-font-md"
                        checked={selectedRole === VIEWER_ROLE}
                        label="Viewer"
                        onChange={() => setSelectedRole(VIEWER_ROLE)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">
                        Can start/stop brokers, view all resources but not edit or delete any resources
                    </p>
                </div>
            </>
        )
    }

    const getModalContent = () => {
        const title = 'Create service account'
        switch (accountCreationState) {
            case 'IN_PROGRESS':
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Creating service account...</p>
                                <Spinner className="remotive-primary-50-color" size="sm" />
                            </div>
                        </Modal.Body>
                    </>
                )

            case 'ERROR':
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-3 mt-3 text-center">
                                <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                                <p className="fs-5 m-0">There was an issue creating the service account...</p>
                                <p className="remotive-font-sm text-secondary" style={{ marginTop: '-5px' }}>
                                    Please try again later
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <Formik
                                // After an error this is used to fake that these values has already been "touched"
                                initialTouched={{
                                    name: false,
                                }}
                                validationSchema={SERVICE_ACCOUNT_SCHEMA}
                                onSubmit={(event: any) => {
                                    createServiceAccountUser(event.name)
                                }}
                                initialValues={{
                                    name: undefined,
                                }}
                            >
                                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                    <Form noValidate onSubmit={handleSubmit}>
                                        <Form.Group>
                                            <div className="mb-4">
                                                <InputGroup size="sm">
                                                    <InputGroup.Text
                                                        className={`remotive-font-md bg-transparent border-0 w-25`}
                                                    >
                                                        Name
                                                    </InputGroup.Text>
                                                    <Form.Control
                                                        className="bg-white remotive-primary-40-border"
                                                        value={values.name}
                                                        name={'name'}
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        isValid={(!errors.name as boolean) && (touched.name as boolean)}
                                                        isInvalid={errors.name !== undefined}
                                                    />
                                                    <Form.Control.Feedback
                                                        className={'w-100 text-end remotive-font-sm'}
                                                        type="invalid"
                                                    >
                                                        <>{errors.name}</>
                                                    </Form.Control.Feedback>
                                                </InputGroup>
                                            </div>
                                            <div className="mb-2">
                                                <InputGroup size="sm">
                                                    <InputGroup.Text
                                                        className={`remotive-font-md bg-transparent border-0 w-25`}
                                                    >
                                                        <p className="h-100">Role</p>
                                                    </InputGroup.Text>
                                                    <div className="w-75">{rolesList()}</div>
                                                </InputGroup>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    className="btn remotive-btn remotive-btn-success align-center"
                                                    disabled={!isValid}
                                                    onClick={() => handleSubmit()}
                                                >
                                                    <div className="d-flex align-items-center text-light">
                                                        <p className="text-light m-0">Create</p>
                                                    </div>
                                                </button>
                                            </div>
                                        </Form.Group>
                                    </Form>
                                )}
                            </Formik>
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal bsPrefix="" show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
