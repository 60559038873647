import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Form, InputGroup, Spinner } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import { Project, UserInfoWithRoles } from '../../api/CloudApi/types'
import { ErrorIcon } from '../../assets/Icons'
import { toast } from 'react-toastify'

interface EditProjectUserModalProps {
    currentProject: Project | undefined
    selectedProjectUser: UserInfoWithRoles | undefined
    show: boolean
    handleCloseFunction: () => void
    reloadUsersList: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

enum Role {
    OWNER = 'project/admin',
    EDITOR = 'project/user',
    VIEWER = 'project/viewer',
}

enum RequestState {
    NOT_STARTED,
    IN_PROGRESS,
    DONE,
    ERROR,
}

export default function EditProjectUserModal(props: EditProjectUserModalProps) {
    const [selectedRole, setSelectedRole] = useState<Role>((props.selectedProjectUser?.roles[0] as Role) || Role.VIEWER)
    const [requestState, setRequestState] = useState<RequestState>(RequestState.NOT_STARTED)

    useEffect(() => {
        console.debug('Mounted edit project user modal!')
    }, [])

    useEffect(() => {
        setSelectedRole(props.selectedProjectUser?.roles[0] as Role)
    }, [props.show])

    useEffect(() => {
        if (requestState === RequestState.DONE) {
            closeModal()
            props.reloadUsersList()
        }
    }, [requestState])

    const validateInput = (): boolean => {
        if (props.selectedProjectUser === undefined) {
            alert('You select a user to edit')
            return false
        }
        if (selectedRole === undefined) {
            alert('You must select a role')
            return false
        }
        return true
    }

    const resetState = () => {
        setSelectedRole(Role.VIEWER)
        setRequestState(RequestState.NOT_STARTED)
    }

    const editProjectUser = async () => {
        const projectUid = props.currentProject?.uid
        if (validateInput() && props.selectedProjectUser && selectedRole && projectUid) {
            // TODO: Validate that at least one user has admin or that a user cannot downgrade from admin

            setRequestState(RequestState.IN_PROGRESS)
            const userInfo = { user: props.selectedProjectUser.user, roles: [selectedRole] } as UserInfoWithRoles
            try {
                await CloudApi.editProjectUser(projectUid, userInfo)
                setRequestState(RequestState.DONE)
            } catch (err) {
                setRequestState(RequestState.ERROR)
                toast.error('Failed to save user')
            }
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    const rolesList = () => {
        return (
            <>
                <div>
                    <Form.Check
                        type="radio"
                        checked={selectedRole === Role.OWNER}
                        className="remotive-font-md"
                        label="Owner"
                        onChange={() => setSelectedRole(Role.OWNER)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">
                        {' '}
                        Full project access, user management access and can delete projects. Note that the user need to
                        have the role as "Project Admin" in this organisation to be able to be owner of a project.
                    </p>
                </div>
                <div>
                    <Form.Check
                        type="radio"
                        className="remotive-font-md"
                        checked={selectedRole === Role.EDITOR}
                        label="Editor"
                        onChange={() => setSelectedRole(Role.EDITOR)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">Full access to manage project resources</p>
                </div>
                <div>
                    <Form.Check
                        type="radio"
                        className="remotive-font-md"
                        checked={selectedRole === Role.VIEWER}
                        label="Viewer"
                        onChange={() => setSelectedRole(Role.VIEWER)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">
                        Can start/stop brokers, view all resources but not edit or delete any resources
                    </p>
                </div>
            </>
        )
    }

    const getModalContent = () => {
        const title = 'Edit project user'
        switch (requestState) {
            case RequestState.IN_PROGRESS:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Editing user...</p>
                                <Spinner className="remotive-primary-50-color" size="sm" />
                            </div>
                        </Modal.Body>
                    </>
                )

            case RequestState.ERROR:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-3 mt-3 text-center">
                                <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                                <p className="fs-5 m-0">There was an issue editing the user...</p>
                                <p className="remotive-font-sm text-secondary" style={{ marginTop: '-5px' }}>
                                    Please try again later
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        Name
                                    </InputGroup.Text>
                                    <Form.Control
                                        disabled
                                        className="rounded border-light-gray"
                                        value={props.selectedProjectUser?.user.displayName || 'N/A'}
                                    />{' '}
                                </InputGroup>
                            </div>
                            <div className="mb-4">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        Email
                                    </InputGroup.Text>
                                    <Form.Control
                                        disabled
                                        className="rounded border-light-gray"
                                        value={props.selectedProjectUser?.user.email || 'N/A'}
                                    />{' '}
                                </InputGroup>
                            </div>
                            <div className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        <p className="h-100">Role</p>
                                    </InputGroup.Text>
                                    <div className="w-75">{rolesList()}</div>
                                </InputGroup>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn remotive-btn remotive-btn-success align-center"
                                    onClick={() => editProjectUser()}
                                >
                                    <div className="d-flex align-items-center text-light">
                                        <p className="text-light m-0">Save</p>
                                    </div>
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
