import { Card, Container } from 'react-bootstrap'
import { UserBillableUnitInfo } from '../api/CloudApi/types'
import { HomeIcon } from '../assets/Icons'
import { useEffect } from 'react'
import { PageDetails } from '../utils/pageDetails'

interface NotFoundProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    currentBillableUnit: UserBillableUnitInfo | undefined
}

export default function NotFound(props: NotFoundProps) {
    useEffect(() => {
        props.updateCurrentPageDetails({
            documentTitle: 'Page Not Found',
            productAnalyticsEventName: 'Page Not Found',
        })
    }, [])

    const homeButton = () => {
        if (props.currentBillableUnit !== undefined) {
            return (
                <a
                    href={`/orgs/${props.currentBillableUnit?.organisation.uid}`}
                    className={'text-light text-decoration-none'}
                >
                    <button className="btn remotive-btn remotive-btn-primary">
                        <>
                            <p className="mb-0 remotive-font-md">
                                {' '}
                                <HomeIcon sx={{ fontSize: 18 }} /> Home
                            </p>
                        </>
                    </button>
                </a>
            )
        }
        return (
            <>
                <a href={`/`} className={'text-light text-decoration-none'}>
                    <button className="btn remotive-btn remotive-btn-primary">
                        <>
                            <p className="mb-0 remotive-font-md">
                                {' '}
                                <HomeIcon sx={{ fontSize: 18 }} /> Home
                            </p>
                        </>
                    </button>
                </a>
            </>
        )
    }

    return (
        <>
            <Container className="">
                <div className={'row mb-4'}>
                    <div className="col-12 p-0">
                        <Card className="shadow rounded-4 border-0 m-5 p-5">
                            <Card.Body className="pb-0 pt-2">
                                <p className="fs-2">404 - Page not found</p>
                                <p>That page doesn't exist...</p>
                                {homeButton()}
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        </>
    )
}
