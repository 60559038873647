import { PlaybackStateFile } from './types'

export class PlaybackStateFileImpl implements PlaybackStateFile {
    private readonly _namespace: string
    private readonly _path: string
    private readonly _brokerUrl: string
    private readonly _isCloudBroker: boolean

    constructor(brokerUrl: string, isCloudBroker: boolean, path: string, namespace: string) {
        this._brokerUrl = brokerUrl
        this._isCloudBroker = isCloudBroker
        this._path = path
        this._namespace = namespace
    }

    path() {
        return this._path
    }

    namespace() {
        return this._namespace
    }

    downloadUrl() {
        if (!this._isCloudBroker) {
            const urlHttpServer = new URL(this._brokerUrl)
            urlHttpServer.port = '8081'
            return `${urlHttpServer.toString()}downloads?paths[]=${this._path}`
        }
    }
}
