import { useEffect, useState } from 'react'
import { Accordion, Card, Form, Spinner } from 'react-bootstrap'
import { ExternalLinkIcon, WarningIcon } from '../assets/Icons'
import CloudApi from '../api/CloudApi'
import { AgreementsAnswersRequest } from '../api/CloudApi/types'
import { IS_NEW_USER_PARAM } from '../utils/queryParams'
import { PageDetails } from '../utils/pageDetails'

interface AcceptAgreementProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
}

const PRIVACY_POLICY_LINK = 'https://remotivelabs.com/privacy-policy/'
const EULA_LINK = 'https://remotivelabs.com/license'

export default function AcceptAgreement(props: AcceptAgreementProps) {
    const [eulaAccepted, setEulaAccepted] = useState(false)
    const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    useEffect(() => {
        props.updateCurrentPageDetails({
            documentTitle: 'Terms of Service Agreement',
            productAnalyticsEventName: 'Terms of Service Agreement',
        })
    }, [])

    const eulaChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEulaAccepted(event.target.checked)
    }

    const privacyPolicyAcceptedChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPrivacyPolicyAccepted(event.target.checked)
    }

    const logo = () => {
        return (
            <>
                <div className="text-end me-3">
                    <img width="110" src="./remotive-labs-logo-pos@3x.png" />
                </div>
            </>
        )
    }

    const submitAgreements = () => {
        const submit = async () => {
            setIsSubmitting(true)
            const res = await CloudApi.postAgreementAnswers({
                eulaAccepted,
                privacyPolicyAccepted,
            } as AgreementsAnswersRequest)

            if (res.status === 200) {
                const response = await CloudApi.defaultUserBu()
                const homeUrl = CloudApi.getOrganisationHomeUrlFromUid(response.data.organisation.uid)
                window.location.replace(`${homeUrl}?${IS_NEW_USER_PARAM}=true`)
            }

            setIsSubmitting(false)
        }

        submit()
    }

    const enterCloudConsoleButton = () => {
        return (
            <>
                <div className="mt-5 mb-3">
                    {(!eulaAccepted || !privacyPolicyAccepted) && (
                        <div className="d-flex flex-row justify-content-center align-items-center mb-2">
                            <WarningIcon className="text-warning" sx={{ fontSize: 15 }} />
                            <p className="text-secondary remotive-font-xs m-0 ms-1">
                                You must accept both agreements before proceeding
                            </p>
                        </div>
                    )}
                    <button
                        disabled={!eulaAccepted || !privacyPolicyAccepted}
                        className="btn remotive-btn-success remotive-btn"
                        onClick={() => submitAgreements()}
                    >
                        {isSubmitting ? <Spinner size="sm" /> : 'I agree'}
                    </button>
                </div>
            </>
        )
    }

    const privacyPolicyAgreement = () => {
        return (
            <div className="d-flex flex-row align-items-start justify-content-between">
                <Form.Check
                    className="mt-2 ms-4 me-2"
                    type="checkbox"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => privacyPolicyAcceptedChanged(event)}
                />
                <Accordion defaultActiveKey={undefined} className="ms-2 mb-3 w-100 me-3">
                    <Accordion.Item eventKey="0" className="border-0">
                        <Accordion.Header>
                            <div className="d-flex flex-column">
                                <p className="m-0 p-0 remotive-font">
                                    I've read & accepted the <b>Privacy Policy</b>
                                </p>
                                <p className="m-0 p-0 remotive-font-xs text-secondary">
                                    Expand to read our <b>Privacy Policy</b>. Accept the agreement by clicking the
                                    checkbox to the left.
                                </p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="text-start ps-1">
                            <a className="remotive-font-md d-flex flex-row" target="blank" href={PRIVACY_POLICY_LINK}>
                                <ExternalLinkIcon sx={{ fontSize: 20 }} />
                                <p className="ms-2">Privacy Policy</p>
                            </a>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        )
    }

    const eulaAgreement = () => {
        return (
            <div className="d-flex flex-row align-items-start justify-content-between">
                <Form.Check
                    className="mt-2 ms-4 me-2"
                    type="checkbox"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => eulaChanged(event)}
                />{' '}
                <Accordion defaultActiveKey={undefined} className="ms-2 mb-3 w-100 me-3">
                    <Accordion.Item eventKey="0" className="border-0">
                        <Accordion.Header>
                            <div className="d-flex flex-column">
                                <p className="m-0 p-0 remotive-font">
                                    I've read & accepted the <b>EULA</b>
                                </p>
                                <p className="m-0 p-0 remotive-font-xs text-secondary">
                                    Expand to read our <b>End User License Agreement</b>. Accept the agreement by
                                    clicking the checkbox to the left.
                                </p>
                            </div>
                        </Accordion.Header>
                        <Accordion.Body className="text-start ps-1">
                            <a className="remotive-font-md d-flex flex-row" target="blank" href={EULA_LINK}>
                                <ExternalLinkIcon sx={{ fontSize: 20 }} />
                                <p className="ms-2">End User License Agreement</p>
                            </a>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        )
    }

    return (
        <>
            <div className="position-fixed">
                <video
                    crossOrigin="anonymous"
                    style={{ WebkitFilter: 'blur(8px)', objectFit: 'cover' }}
                    className="position-fixed w-100 h-100"
                    id="section-c6a9cb6-player"
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    preload={'none'}
                    src="https://remotivelabs.com/wp-content/uploads/2022/10/remotiveLabs.mp4"
                ></video>
                <div className="w-100 h-100 p-5 position-fixed remotive-primary-90-background opacity-75"></div>
            </div>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <div className="col-12  col-sm-10 col-md-8 col-lg-6 col-xxl-4">
                    <Card className="border-0 shadow-lg rounded-4 remotive-primary-5-background p-2 py-3 w-100 h-100">
                        {logo()}
                        <Card.Title className="mb-4 mt-3 fs-4 lexend-bold">Terms of Service</Card.Title>
                        {privacyPolicyAgreement()}
                        {eulaAgreement()}
                        {enterCloudConsoleButton()}
                    </Card>
                </div>
            </div>
        </>
    )
}
