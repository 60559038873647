import { ReactNode } from 'react'
import { Card } from 'react-bootstrap'

interface RLCardProps {
    body: ReactNode
    minHeight?: number
}

export default function RLCard(props: RLCardProps) {
    return (
        <Card className="shadow-sm rounded-4 border-0 mb-4" style={{ minHeight: props.minHeight ?? 400 }}>
            {props.body}
        </Card>
    )
}
