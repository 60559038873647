import { DateTimeFormatter, ZonedDateTime, ZoneId } from '@js-joda/core'

const DATE_FORMATTER = DateTimeFormatter.ofPattern('yyyy-MM-dd')

const DATETIME_FORMATTER = DateTimeFormatter.ofPattern('yyyy-MM-dd HH:mm')

export function formatAsDatetime(
    dateTime: string,
    localTime: boolean = true,
    formatter: DateTimeFormatter = DATETIME_FORMATTER
): string {
    let zdt = ZonedDateTime.parse(dateTime)
    if (localTime) {
        zdt = zdt.withZoneSameInstant(ZoneId.systemDefault())
    }
    return zdt.format(formatter)
}

export function formatAsDate(dateTime: string): string {
    return ZonedDateTime.parse(dateTime).format(DATE_FORMATTER)
}
