import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'

interface HelpOverlayModalProps {
    show: boolean
    text?: string
    handleCloseFunction: Function
}

export default function HelpOverlayModalModal(props: HelpOverlayModalProps) {
    useEffect(() => {
        console.debug('Mounted help overlay modal!')
    }, [])

    return (
        <>
            <Modal
                onHide={() => props.handleCloseFunction()}
                animation={false}
                className="d-flex align-items-center justify-content-center"
                contentClassName="bg-transparent border-0"
                backdropClassName="opacity-65 remotive-primary-90-background"
                show={props.show}
            >
                <div className="lexend-regular fixed-bottom d-flex justify-content-center align-items-end">
                    <p className="fs-6 m-3 remotive-primary-10-color">Click anywhere to dismiss</p>
                </div>
            </Modal>
        </>
    )
}
