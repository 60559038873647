export type LoginQuote = {
    quoteText: string
    quotee: string
}
export const loginQuotes: LoginQuote[] = [
    {
        quoteText: "It’s impressive how RemotiveLabs is challenging old ways of working and enabling virtual vehicle development.",
        quotee: 'Johan Müllern-Aspegren, Startup Catalyst Lead, AIE Nordics at Capgemini',
    },
    {
        quoteText: "Getting easy access to realistic vehicle data is critical for automotive prototyping. RemotiveCloud and RemotiveBroker can feed ProtoPie with real vehicle data which is great for concept validation.",
        quotee: 'Boram (Luke) Han, Product Manager at ProtoPie'
    },
    {
        quoteText: "For early software ECU verification, the RemotiveBroker setup enables automated testing and is at least 10 times more cost efficient than the HIL-rig setup.",
        quotee: "Lorenzo Della Torre, Software Test Automation Engineer at Aurobay"
    },
    {
        quoteText: "For prototyping new applications, the approach with RemotiveLabs is helping us populate the Android API with standardized signal data is really helpful.",
        quotee: "Peter Winzell, Software Engineer at Volvo Cars Corporation"
    }
]
