import { SignalEntry } from '../../../api/CloudApi/types'

export enum AnalyticsPanelType {
    SIGNAL_TIME_SERIES = 'SIGNAL_TIME_SERIES',
    MAP_WITH_ROUTE = 'MAP_WITH_ROUTE',
    TIME_DEVIATION = 'TIME_DEVIATION',
}

export const ANALYTICS_CHART_TYPE_KEY = 'analytics-chart-type-key'

export type SignalOption = {
    value: string
    label: string
}

export type PanelKey = {
    key: string
}

export type CompressedPanelKey = {
    k: string
}

export type TimeDeviationQueryResult = {
    name: string
    annotations: {
        expectedCycleTime: number
    }
    values: Array<Array<number>>
}

// Dashboard
export type StoredDashboard = {
    timeSeriesPanels: Array<TimeSeriesPanel>
    timeDeviationPanels: Array<TimeDeviationPanel>
    mapPanels: Array<MapPanel>
}

export type TimeDeviationPanel = {
    panelKey: PanelKey
    selectedSignals: Array<SignalOption>
}

export type TimeSeriesPanel = {
    panelKey: PanelKey
    selectedSignals: Array<SignalEntry>
    hiddenSignals: Array<SignalEntry>
}

export type MapPanel = {
    panelKey: PanelKey
    latitudeSignal: string | undefined
    longitudeSignal: string | undefined
}

// Compressed counter part to the dashboard
export type CompressedStoredDashboard = {
    tsp: Array<CompressedTimeSeriesPanel>
    tdp: Array<CompressedTimeDeviationPanel>
    mp: Array<CompressedMapPanel>
}

export type CompressedTimeDeviationPanel = {
    pk: PanelKey
    ss: Array<SignalOption>
}

export type CompressedTimeSeriesPanel = {
    pk: PanelKey
    ss: Array<CompressedSignalEntry>
    hs: Array<CompressedSignalEntry>
}

export type CompressedMapPanel = {
    pk: PanelKey
    las: string | undefined
    los: string | undefined
}

export type CompressedSignalEntry = {
    fn: string
    n: string
}

export type CompressedSignalOption = {
    v: string
    l: string
}