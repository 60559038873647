import CloudApi from '../api/CloudApi'

export default function LogoutButton() {
    const logout = () => {
        CloudApi.removeAuthKey()
        window.location.replace('/')
    }
    return (
        <button className="btn btn-danger remotive-btn-sm mb-0" onClick={() => logout()}>
            Logout
        </button>
    )
}
