import { NavLink } from 'react-router-dom'
import { Project, RecordingSession, UserBillableUnitInfo } from '../api/CloudApi/types'
import { ClickableArrowIcon, CopyIcon, DeleteIcon, LockedIcon } from '../assets/Icons'
import { formatAsDatetime } from '../utils/datetime'
import { Permission, hasPermission } from '../utils/permission'
import { PageState } from '../types/PageState'
import CloudApi from '../api/CloudApi'
import { toast } from 'react-toastify'
import { ProjectRoutes } from '../Routes'

interface RecordingListItemProps {
    recording: RecordingSession
    isLocked: boolean
    project: Project
    billableUnit: UserBillableUnitInfo
    pageStateFunction?: Function
    recordingStateFunction?: Function
    recordingSessionToCopyFunction?: Function
    useProjectNameInsteadOfFilesLength?: boolean
}

export default function RecordingListItem(props: RecordingListItemProps) {
    const getSubtitle = () => {
        return props.useProjectNameInsteadOfFilesLength ? (
            <>
                In project <b>{props.project.displayName}</b>
            </>
        ) : (
            `${props.recording.recordings.length} file(s)`
        )
    }

    const deleteRecordingSession = async (
        sessionId: string,
        pageStateFunction: Function,
        recordingStateFunction: Function
    ) => {
        if (window.confirm('Are you sure you want to delete this recording? You cannot undo this!')) {
            pageStateFunction(PageState.LOADING)
            try {
                await CloudApi.deleteRecordingSession(props.project.uid, sessionId)
                recordingStateFunction()
            } catch (err: any) {
                toast.error('Failed to delete recording')
                pageStateFunction(PageState.DONE)
            }
        }
    }

    const recordingListItem = (
        recording: RecordingSession,
        isLocked: boolean,
        project: Project,
        billableUnit: UserBillableUnitInfo
    ) => {
        return (
            <div className="d-flex align-items-center mb-1">
                <div className="col-7 col-md-8 p-1 border-end d-flex align-items-center justify-content-between">
                    <NavLink
                        reloadDocument={props.useProjectNameInsteadOfFilesLength}
                        to={isLocked ? '' : ProjectRoutes.toRecordingDetails(project.uid, recording.sessionId)}
                        className={
                            'w-100 text-dark text-decoration-none remotive-btn-no-bg d-flex align-items-center justify-content-between  text-truncate'
                        }
                    >
                        <div className="text-truncate">
                            <p className="remotive-font-md mb-0 text-truncate">{recording.displayName}</p>
                            <p className="remotive-font-sm text-secondary mb-0 text-truncate">{getSubtitle()}</p>
                        </div>
                        <div>
                            {isLocked && <LockedIcon className="mx-1" />}
                            <ClickableArrowIcon className="ms-2" />
                        </div>
                    </NavLink>
                </div>
                <div className="col-3 col-md-2 p-1 px-2 text-start">
                    <p className="m-0 remotive-font-md text-truncate">Uploaded</p>
                    <p className="remotive-font-sm text-secondary mb-0 text-truncate">
                        {formatAsDatetime(recording.uploaded)}
                    </p>
                </div>
                <div className="w-100 px-1 d-flex justify-content-end  align-items-center">
                    {props.pageStateFunction !== undefined && props.recordingStateFunction !== undefined && (
                        <button
                            className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                            disabled={!hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project)}
                            onClick={() =>
                                deleteRecordingSession(
                                    recording.sessionId,
                                    props.pageStateFunction!,
                                    props.recordingStateFunction!
                                )
                            }
                        >
                            <DeleteIcon sx={{ fontSize: 20 }} />
                        </button>
                    )}
                    {props.recordingSessionToCopyFunction !== undefined && (
                        <button
                            className={'ms-1 btn remotive-btn-no-bg p-1 px-0 border-0'}
                            disabled={!hasPermission(Permission.PROJECT_EDITOR_RECORDING, billableUnit, project)}
                            onClick={() => props.recordingSessionToCopyFunction!(recording.sessionId)}
                        >
                            <CopyIcon sx={{ fontSize: 20 }} />
                        </button>
                    )}
                </div>
            </div>
        )
    }

    return recordingListItem(props.recording, props.isLocked, props.project, props.billableUnit)
}
