import { Card } from 'react-bootstrap'
import LoadingContainer from '../../components/LoadingContainer'
import { Project, RecordingSession, UserBillableUnitInfo } from '../../api/CloudApi/types'
import { useRef, useState } from 'react'
import { ArrowNorthIcon, CrossIcon, EditIcon, SaveIcon } from '../../assets/Icons'
import { Permission, hasPermission } from '../../utils/permission'
import MDEditor from '@uiw/react-md-editor'
import rehypeSanitize from 'rehype-sanitize'
import CloudApi from '../../api/CloudApi'
import { toast } from 'react-toastify'
import { PageState } from '../../types/PageState'
import { MarkdownPreviewRef } from '@uiw/react-markdown-preview'

interface NotesTabProps {
    currentRecording: RecordingSession | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    showDemoModal: boolean
    setShowDemoModal: (newValue: boolean) => void
    showHelpModal: boolean
    setShowHelpModal: (newValue: boolean) => void
    setPageState: (pageState: PageState) => void
    setRecordingSession: () => void
}

export function NotesTab(props: NotesTabProps) {
    const [isEditDescriptionActive, setIsEditDescriptionActive] = useState<boolean>(false)
    const [editedMarkdown, setEditedMarkdown] = useState<string>()
    const markdownElementRef = useRef<MarkdownPreviewRef | null>(null)

    const HIGHLIGHT_HELP_OBJECT_STYLE = props.showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}
    const HIGHLIGHT_DEMO_OBJECT_STYLE = props.showDemoModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}

    const editDescription = () => {
        props.setShowHelpModal(false)
        props.setShowDemoModal(false)
        setIsEditDescriptionActive(true)
    }

    const helpTextEditButton = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    props.showHelpModal ? 'block' : 'none'
                }`}
                style={{ ...HIGHLIGHT_HELP_OBJECT_STYLE, right: 1, marginTop: 10 }}
            >
                <div className="d-flex justify-content-end">
                    <ArrowNorthIcon sx={{ fontSize: 30 }} className="mb-2" />
                </div>
                <p className="text-light text-start">
                    Use this edit icon to change <br />
                    the description of this <br />
                    recording session
                </p>
            </div>
        )
    }

    const saveEditedDescription = async (editedMarkdown: string) => {
        const projectUid = props.currentProject?.uid
        const recordingSessionId = props.currentRecording?.sessionId
        const displayName = props.currentRecording?.displayName
        if (projectUid && recordingSessionId !== undefined && displayName !== undefined) {
            try {
                props.setPageState(PageState.SAVING)
                await CloudApi.editRecordingSession(projectUid, recordingSessionId, displayName, editedMarkdown)
                setIsEditDescriptionActive(false)
                toast.success(`Successfully saved recording details`)
                props.setRecordingSession() // Fetch recording session again with the updated details
            } catch (err) {
                toast.error(`Failed to edit recording session, please try again`)
                props.setPageState(PageState.DONE)
            }
        }
    }

    const editMarkdownButton = () => {
        if (isEditDescriptionActive) {
            return (
                <>
                    <button
                        className="btn remotive-btn-sm remotive-btn-primary m-0 me-1"
                        onClick={() => {
                            setIsEditDescriptionActive(false)
                            setEditedMarkdown(props.currentRecording?.description || 'Error')
                        }}
                    >
                        <CrossIcon className="align-top" sx={{ fontSize: 17 }} /> Cancel
                    </button>
                    <button
                        className="btn remotive-btn-sm remotive-btn-success m-0"
                        onClick={() => {
                            saveEditedDescription(editedMarkdown || props.currentRecording?.description || 'Error')
                        }}
                    >
                        <SaveIcon className="align-top" sx={{ fontSize: 17 }} /> Save
                    </button>
                </>
            )
        }
        return (
            <>
                <button
                    style={HIGHLIGHT_HELP_OBJECT_STYLE}
                    disabled={
                        !hasPermission(
                            Permission.PROJECT_EDITOR_RECORDING,
                            props.currentBillableUnit,
                            props.currentProject
                        )
                    }
                    className="btn p-1 px-1 remotive-btn-sm remotive-btn-no-bg border-0 m-0 position-relative"
                    onClick={() => editDescription()}
                >
                    <EditIcon className="align-top" sx={{ fontSize: 17 }} />
                </button>
            </>
        )
    }

    const markdownField = () => {
        if (isEditDescriptionActive) {
            return (
                <div className="mt-2" data-color-mode="light">
                    <MDEditor
                        height={500}
                        maxHeight={1100}
                        value={editedMarkdown || props.currentRecording?.description}
                        onChange={setEditedMarkdown}
                        preview="live"
                        previewOptions={{ className: 'lexend-regular', rehypePlugins: [rehypeSanitize] }}
                        components={{
                            toolbar: (command, disabled, executeCommand) => {
                                if (command.keyCommand === 'code') {
                                    return (
                                        <button
                                            aria-label="Insert code"
                                            disabled={disabled}
                                            onClick={(evn) => {
                                                evn.stopPropagation()
                                                executeCommand(command, command.groupName)
                                            }}
                                        >
                                            {'</>'}
                                        </button>
                                    )
                                }
                            },
                        }}
                    />
                </div>
            )
        }
        return (
            <div className="mt-1 mb-2" data-color-mode="light">
                <MDEditor.Markdown
                    ref={markdownElementRef}
                    style={{ position: 'absolute', overflow: 'clip', width: '98%', height: "100%" }}
                    className="lexend-regular bg-transparent"
                    source={props.currentRecording?.description || ''}
                    rehypePlugins={[rehypeSanitize]}
                />
            </div>
        )
    }

    const descriptionSection = () => {
        return (
            <>
                <div className="text-break px-2 ps-3">{markdownField()}</div>
            </>
        )
    }

    const detailsContainer = () => {
        if (props.currentRecording !== undefined) {
            return (
                <>
                    <div className="text-end">
                        {editMarkdownButton()}
                        {helpTextEditButton()}
                    </div>
                    <div>{descriptionSection()}</div>
                </>
            )
        }
        return <LoadingContainer />
    }

    return (
        <Card
            className="shadow-sm rounded-4 border-0 text-start mb-4 mb-a"
            style={{
                minHeight: '300px',
                height:
                    markdownElementRef.current !== null && markdownElementRef.current.mdp.current !== null
                        ? markdownElementRef.current.mdp.current.scrollHeight + 75
                        : 300,
            }}
        >
            <Card.Body className="p-1 ">{detailsContainer()}</Card.Body>
        </Card>
    )
}
