import { FrameEntry } from '../../../api/CloudApi/types'
import {
    CompressedMapPanel,
    CompressedSignalEntry,
    CompressedStoredDashboard,
    CompressedTimeDeviationPanel,
    CompressedTimeSeriesPanel,
    MapPanel,
    StoredDashboard,
    TimeDeviationPanel,
    TimeSeriesPanel,
} from './Types'

export function compressDashboard(dashboard: StoredDashboard): CompressedStoredDashboard {
    return {
        tsp: dashboard.timeSeriesPanels?.map((it) => {
            return {
                pk: it.panelKey,
                ss: it.selectedSignals?.map((signal) => {
                    return { fn: signal.frameName, n: signal.name } as CompressedSignalEntry
                }),
                hs: it.hiddenSignals?.map((signal) => {
                    return { fn: signal.frameName, n: signal.name } as CompressedSignalEntry
                }),
            } as CompressedTimeSeriesPanel
        }),
        tdp: dashboard.timeDeviationPanels?.map((it) => {
            return { pk: it.panelKey, ss: it.selectedSignals } as CompressedTimeDeviationPanel
        }),
        mp: dashboard.mapPanels?.map((it) => {
            return { pk: it.panelKey, las: it.latitudeSignal, los: it.longitudeSignal } as CompressedMapPanel
        }),
    } as CompressedStoredDashboard
}

export function decompressDashboard(
    compressedDashboard: CompressedStoredDashboard,
    selectableSignals: Array<FrameEntry>
): StoredDashboard {
    return {
        timeSeriesPanels: (compressedDashboard.tsp ?? []).map((it) => {
            return {
                panelKey: it.pk,
                selectedSignals: it.ss
                    .map((signal) => {
                        return selectableSignals
                            .find((frameEntry) => frameEntry.name === signal.fn)
                            ?.signals.find((signalEntry) => signalEntry.name === signal.n)
                    })
                    .filter((signal) => signal !== undefined),
                hiddenSignals: it.hs
                    .map((signal) => {
                        return selectableSignals
                            .find((frameEntry) => frameEntry.name === signal.fn)
                            ?.signals.find((signalEntry) => signalEntry.name === signal.n)
                    })
                    .filter((signal) => signal !== undefined),
            } as TimeSeriesPanel
        }),
        timeDeviationPanels: (compressedDashboard.tdp ?? []).map((it) => {
            return { panelKey: it.pk, selectedSignals: it.ss } as TimeDeviationPanel
        }),
        mapPanels: (compressedDashboard.mp ?? []).map((it) => {
            return { panelKey: it.pk, latitudeSignal: it.las, longitudeSignal: it.los } as MapPanel
        }),
    } as StoredDashboard
}
