import { useState, useEffect } from 'react'
import './assets/css/App.css'
import { Route, Routes, useSearchParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Login from './pages/Login'
import Home from './pages/Home'
import LicenseList from './pages/LicenseList'
import UserList from './pages/UserList'
import BrokerList from './pages/BrokerList'
import ProjectManagement from './pages/ProjectManagement'
import RecordingDetails from './pages/RecordingDetails'
import RecordingsList from './pages/RecordingsList'
import SignalDatabaseList from './pages/SignalDatabaseList'
import NotFound from './pages/NotFound'
import { AuthenticatedUser, Project, UserBillableUnitInfo } from './api/CloudApi/types'
import CloudApi from './api/CloudApi'
import BrokerDetails from './pages/BrokerDetails'
import Admin from './pages/Admin'
import useScript from './hooks/useScript'
import { isDemo } from './utils/demo'
import { PageDetails } from './utils/pageDetails'
import Agreement from './pages/Agreement'
import OrganisationSettings from './pages/OrganisationSettings'
import OrganisationToBeDeleted from './pages/OrganisationToBeDeleted'
import { PageRoutes } from './Routes'
import { formattedToastMessage } from './utils/toast'
import useInterval from './hooks/useInterval'
import useIsTabActive from './hooks/useIsTabActive'
import { NOTIFY_USER_HAS_ACCOUNT_PARAM } from './utils/queryParams'
import { useProductAnalyticsClient } from './utils/ProductAnalytics'

function App() {
    const [billableUnits, setBillableUnits] = useState<Array<UserBillableUnitInfo>>([])
    const [currentBillableUnit, setCurrentBillableUnit] = useState<UserBillableUnitInfo>()
    const [currentProject, setCurrentProject] = useState<Project>()
    const [currentUser, setCurrentUser] = useState<AuthenticatedUser>()
    const [currentPageDetails, setCurrentPageDetails] = useState<PageDetails>()
    
    const productAnalyticsClient = useProductAnalyticsClient({
        user: currentUser,
        billableUnit: currentBillableUnit,
    })

    useEffect(() => {
        document.title = currentPageDetails?.documentTitle || 'RemotiveCloud'
        if (currentPageDetails?.productAnalyticsEventName) {
            productAnalyticsClient.page(currentPageDetails?.productAnalyticsEventName)
        }
    }, [currentPageDetails])

    useEffect(() => {
        if (currentUser) {
            productAnalyticsClient.identify(currentUser)
        }
    }, [currentUser])

    const updateCurrentPageDetails = (pageDetails: PageDetails) => {
        setCurrentPageDetails(pageDetails)
    }

    const [searchParams] = useSearchParams()
    const notifyUserAlreadyHasAccount = searchParams.get(NOTIFY_USER_HAS_ACCOUNT_PARAM)

    const urlPath = window.location.pathname

    const isTabActive = useIsTabActive()

    useEffect(() => {
        if (isTabActive) {
            userTokenTtlCheck()
        }
    }, [isTabActive])

    useInterval(() => {
        userTokenTtlCheck()
    }, 15 * 60 * 1_000) //every 15 minutes

    const userTokenTtlCheck = async () => {
        if (currentUser !== undefined) {
            const ttlResponse = await CloudApi.getTokenTtl()
            console.debug(
                `This session will end in ${ttlResponse.data.ttl} seconds and return the user to the login page`
            )
        }
    }

    const isAdminPage = () => {
        return urlPath === PageRoutes.ADMIN
    }

    const isNotFoundPage = () => {
        return urlPath === PageRoutes.NOT_FOUND
    }

    const isLoginPage = () => {
        return urlPath === PageRoutes.LOGIN
    }

    const isAgreementPage = () => {
        return urlPath === PageRoutes.AGREEMENT
    }

    const isScheduledForDeletionPage = () => {
        return urlPath === PageRoutes.SCHEDULED_FOR_DELETION
    }

    // Feedback form
    useScript(
        '//cdn.jsdelivr.net/npm/@betahuhn/feedback-js/dist/feedback-js.min.js',
        true,
        new Map([
            [
                'data-feedback-opts',
                '{ "endpoint": "https://remotive-feedback-handler-sglnqbpwoa-ez.a.run.app/feedback", "id": "RemotiveCloud", "emailField": true,"emailPlaceholder": "Email (optional)","inputPlaceholder": "Your feedback here... \\n\\nAdd your email if you don\'t want to be anonymous.","failedMessage": "Please try submitting your feedback again. If this keeps happening, contact us at support@remotivelabs.com","position": "right", "background": "white", "color": "#05315a", "primary": "#05315a","types": {"general": {"text": "Improvement","icon": "&bull;"},"bug": {"text": "Bug","icon": "&bull;"}}}',
            ],
        ]),
        !isDemo() && !isLoginPage() && !isAgreementPage() && !isScheduledForDeletionPage()
    )

    // Hubspot tracking
    useScript(
        '//js-eu1.hs-scripts.com/25376619.js',
        true,
        new Map([
            ['id', 'hs-script-loader'],
            ['type', 'text/javascript'],
        ]),
        isDemo()
    )

    // Cookie consent
    useScript(
        '//consent.cookiebot.com/uc.js',
        true,
        new Map([
            ['id', 'Cookiebot'],
            ['type', 'text/javascript'],
            ['data-cbid', '27dd0904-9072-478e-98db-ce6274935970'],
            ['data-blockingmode', 'auto'],
        ]),
        isDemo()
    )

    useEffect(() => {
        console.debug('Mounted app!')
        const initialOrganisationUid = urlPath.startsWith('/orgs/') ? urlPath.split('/')[2] : undefined
        const initialProjectUid = urlPath.startsWith('/p/') ? urlPath.split('/')[2] : undefined
        if (!isLoginPage()) {
            setInitialAppState(initialOrganisationUid, initialProjectUid)
        }
    }, [])

    const setInitialAppState = async (
        initialOrganisationUid: string | undefined,
        initialProjectUid: string | undefined
    ) => {
        forceValidRoute(initialOrganisationUid, initialProjectUid)
        const organisations = (await CloudApi.listOrganisations()).data
        const { currentBillableUnit, currentProject } = getCurrentBuAndProject(
            organisations,
            initialOrganisationUid,
            initialProjectUid
        )
        forceValidRoute(currentBillableUnit?.organisation.uid, currentProject?.uid)
        const user = (await CloudApi.whoami()).data
        setCurrentUser(user)
        setBillableUnits(organisations)
        setCurrentBillableUnit(currentBillableUnit)
        setCurrentProject(currentProject)
        if (notifyUserAlreadyHasAccount) {
            toast.info(
                formattedToastMessage(
                    'You already have an account!',
                    `You tried to sign up with ${user?.email}, but you already have an account on our platform.`
                ),
                { autoClose: 20_000, position: 'top-center' }
            )
        }
    }

    const forceValidRoute = (organisationUid: string | undefined, projectId: string | undefined) => {
        if (
            organisationUid === undefined &&
            projectId === undefined &&
            !isNotFoundPage() &&
            !isAdminPage() &&
            !isAgreementPage() &&
            !isScheduledForDeletionPage()
        ) {
            console.warn(`Could not find a page for path: ${urlPath}, forcing user to ${PageRoutes.NOT_FOUND}`)
            window.location.replace(PageRoutes.NOT_FOUND)
        }
    }

    const getCurrentBuAndProject = (
        organisations: Array<UserBillableUnitInfo>,
        initialOrganisationUid: string | undefined,
        initialProjectUid: string | undefined
    ): { currentBillableUnit: UserBillableUnitInfo | undefined; currentProject: Project | undefined } => {
        if (initialOrganisationUid === undefined && initialProjectUid !== undefined) {
            // Project is set in URL but no organisation, if the project exists in any organisation we will default to that organisation
            const currentBillableUnit = organisations.find(
                (organisation) =>
                    organisation.projects.filter((project) => project.uid === initialProjectUid).length > 0
            )
            const currentProject = currentBillableUnit?.projects.find((project) => project.uid === initialProjectUid)
            if (currentBillableUnit && currentProject) {
                return {
                    currentBillableUnit,
                    currentProject,
                }
            }
        } else if (initialOrganisationUid !== undefined && initialProjectUid === undefined) {
            // Organisation is set in URL but no project, if the organisation exists we default to the first project
            const organisation = organisations.find((org) => org.organisation.uid === initialOrganisationUid)
            if (organisation) {
                return {
                    currentBillableUnit: organisation,
                    currentProject: organisation.projects[0],
                }
            }
        }
        return {
            currentBillableUnit: undefined,
            currentProject: undefined,
        }
    }

    return (
        <>
            <div className="App lexend-regular">
                <Routes>
                    <Route
                        path={PageRoutes.ADMIN}
                        element={
                            <Admin updateCurrentPageDetails={updateCurrentPageDetails} currentUser={currentUser} />
                        }
                    />
                    <Route
                        path={PageRoutes.AGREEMENT}
                        element={<Agreement updateCurrentPageDetails={updateCurrentPageDetails} />}
                    />
                    <Route
                        path={PageRoutes.SCHEDULED_FOR_DELETION}
                        element={
                            <OrganisationToBeDeleted
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                currentUser={currentUser}
                            />
                        }
                    />

                    <Route
                        path={PageRoutes.LOGIN}
                        element={<Login updateCurrentPageDetails={updateCurrentPageDetails} />}
                    />
                    <Route
                        path={PageRoutes.PROJECT_BROKERS}
                        element={
                            <BrokerList
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentUser={currentUser}
                                currentProject={currentProject}
                                currentBillableUnit={currentBillableUnit}
                                projects={currentBillableUnit?.projects || []}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.PROJECT_BROKER_DETAILS}
                        element={
                            <BrokerDetails
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentUser={currentUser}
                                currentBillableUnit={currentBillableUnit}
                                currentProject={currentProject}
                                projects={currentBillableUnit?.projects || []}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.PROJECT_SETTINGS}
                        element={
                            <ProjectManagement
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentUser={currentUser}
                                currentProject={currentProject}
                                currentBillableUnit={currentBillableUnit}
                                projects={currentBillableUnit?.projects || []}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.PROJECT_RECORDINGS}
                        element={
                            <RecordingsList
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentUser={currentUser}
                                currentProject={currentProject}
                                currentBillableUnit={currentBillableUnit}
                                projects={currentBillableUnit?.projects || []}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.PROJECT_RECORDING_DETAILS}
                        element={
                            <RecordingDetails
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentUser={currentUser}
                                currentProject={currentProject}
                                currentBillableUnit={currentBillableUnit}
                                projects={currentBillableUnit?.projects || []}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.PROJECT_SIGNAL_DATABASES}
                        element={
                            <SignalDatabaseList
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentUser={currentUser}
                                currentBillableUnit={currentBillableUnit}
                                currentProject={currentProject}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.ORGANISATION_HOME}
                        element={
                            <Home
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentBillableUnit={currentBillableUnit}
                                currentProject={currentProject}
                                currentUser={currentUser}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.ORGANISATION_LICENSES}
                        element={
                            <LicenseList
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentBillableUnit={currentBillableUnit}
                                currentProject={currentProject}
                                currentUser={currentUser}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.ORGANISATION_USERS}
                        element={
                            <UserList
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentUser={currentUser}
                                currentBillableUnit={currentBillableUnit}
                                currentProject={currentProject}
                            />
                        }
                    />
                    <Route
                        path={PageRoutes.ORGANISATION_SETTINGS}
                        element={
                            <OrganisationSettings
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                billableUnits={billableUnits}
                                currentUser={currentUser}
                                currentBillableUnit={currentBillableUnit}
                                currentProject={currentProject}
                            />
                        }
                    />

                    {/* This route will catch anything that we haven't defined and send the user to the "not found" page */}
                    <Route
                        path="*"
                        element={
                            <NotFound
                                updateCurrentPageDetails={updateCurrentPageDetails}
                                currentBillableUnit={currentBillableUnit || billableUnits[0]}
                            />
                        }
                    />
                </Routes>

                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    bodyClassName={'lexend-regular remotive-font-md'}
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
            </div>
        </>
    )
}

export default App
