import {
    ClientId,
    Configuration,
    Empty,
    FileDescription,
    FileDescriptions,
    FileUploadChunkRequest,
    Frames,
    License,
    LicenseInfo,
    Mode,
    NameSpace,
    NetworkServicePromiseClient,
    PlaybackConfig,
    PlaybackInfo,
    PlaybackInfos,
    PlaybackMode,
    Signal as GrpcSignal,
    SignalId,
    SignalIds,
    Signals,
    SubscriberConfig,
    SystemServicePromiseClient,
    TrafficServicePromiseClient,
    CountByFrameId,
    FramesDistribution,
    FramesDistributionConfig,
} from 'remotivelabs-grpc-web-stubs'

import {
    BrokerDetails,
    ConfigurationInfo,
    CreateRecordingSession,
    Frame,
    FullDiskError,
    Namespace,
    PlaybackState,
    PlaybackStateFile,
    RecordingState,
    Signal,
    SignalType,
    SignalValue,
    Subscription,
} from './types'
import { sha256 } from 'js-sha256'
import { ClientReadableStream, Metadata } from 'grpc-web'
import { PlaybackStateFileImpl } from './PlaybackStateFileImpl'
import { PlaybackStateImpl } from './PlaybackStateImpl'
import { CombinedPlaybackState } from './CombinedPlaybackState'

const randomString = () => (Math.random() + 1).toString(36).substring(2)
export default class BrokerApi {
    readonly brokerUrl: string
    readonly brokerAccessToken: string | undefined
    readonly isCloudBroker: boolean

    private _onSignalStreamEndCallback: () => void
    private _onPlaybackStreamEndCallback: () => void
    private _clientId: string

    private _stream: ClientReadableStream<Signals> | undefined = undefined
    private _frameDistributionStream: ClientReadableStream<FramesDistribution> | undefined = undefined
    private _pendingSubscriptions: Array<Subscription> = []
    private _subscriptions: Array<Subscription> = []

    private playbackListeners: Array<(playback: PlaybackState) => void> = []
    private recordingListeners: Array<(playback: RecordingState) => void> = []

    private _networkServiceClient: NetworkServicePromiseClient
    private _trafficServiceClient: TrafficServicePromiseClient
    private _systemServiceClient: SystemServicePromiseClient

    /*
    Files available on the broker but not playing comes as the first PlaybackState when registering
    with a broker. This can be stored here in the availableFiles
     */
    private availableFiles: Array<PlaybackStateFile> | undefined
    private currentPlaybackMode = new CombinedPlaybackState()
    private currentRecordingPlaybackMode = new CombinedPlaybackState()
    private dropIncomingSignals = false

    constructor(
        brokerDetails: BrokerDetails,
        onPlaybackStreamEndCallback: () => void,
        onSignalStreamEndCallback: () => void
    ) {
        this.brokerUrl = brokerDetails.brokerUrl
        this.brokerAccessToken = brokerDetails.brokerAccessToken
        this.isCloudBroker = brokerDetails.isCloudBroker()
        this._onPlaybackStreamEndCallback = onPlaybackStreamEndCallback
        this._onSignalStreamEndCallback = onSignalStreamEndCallback
        this._clientId = randomString()

        this._networkServiceClient = new NetworkServicePromiseClient(this.brokerUrl)
        this._trafficServiceClient = new TrafficServicePromiseClient(this.brokerUrl)
        this._systemServiceClient = new SystemServicePromiseClient(this.brokerUrl)
    }

    subscribeToFrameDistribution = (
        namespaceName: string,
        onDataCallback: (data: Array<CountByFrameId>, receivedTimestampMs: number) => void,
        onEndCallback: () => void
    ) => {
        if (this._frameDistributionStream !== undefined) {
            this._frameDistributionStream.cancel()
            console.debug('Killing previous frame distribution stream and setting up a new one')
        }
        const config = new FramesDistributionConfig()
        const namespace = new NameSpace()

        namespace.setName(namespaceName)
        config.setNamespace(namespace)

        this._frameDistributionStream = this._networkServiceClient.subscribeToFramesDistribution(
            config,
            this.authenticationHeaders()
        )

        this._frameDistributionStream.on('data', (data: FramesDistribution) => {
            try {
                onDataCallback(data.getCountsbyframeidList(), Date.now())
            } catch (e) {
                console.debug(
                    `Received frame frequency distribution data, but an error occurred error=${JSON.stringify(data)}`
                )
            }
        })

        this._frameDistributionStream.on('end', () => {
            console.debug('Frame frequency distribution subscription ended')
            onEndCallback()
        })

        this._frameDistributionStream.on('error', (error) => {
            console.debug(`Received frame frequency distribution error=${JSON.stringify(error)}`)
        })
    }

    addPendingSubscription = (
        signalId: SignalId,
        onSignalCallback: (signalValue: SignalValue, signalType: SignalType) => void,
        onEndCallback: () => void = () => {}
    ) => {
        const subscriptionId = randomString()
        const subscripton = {
            signalId,
            subscriptionId,
            onSignalCallback,
            onEndCallback,
        } as Subscription
        this._pendingSubscriptions.push(subscripton)
        return subscriptionId
    }

    removePendingSubscription = (subscriptionId: String) => {
        this._pendingSubscriptions = [
            ...this._pendingSubscriptions.filter((subscription) => subscription.subscriptionId !== subscriptionId),
        ]
    }

    listPendingSubscriptions = () => {
        return this._pendingSubscriptions
    }

    addSubscription = (
        signalId: SignalId,
        onSignalCallback: (signalValue: SignalValue, signalType: SignalType) => void,
        onEndCallback: () => void = () => {}
    ) => {
        const subscriptionId = randomString()
        const subscripton = { signalId, subscriptionId, onSignalCallback, onEndCallback } as Subscription
        this._subscriptions.push(subscripton)
        this.refreshSubscriptions()
        return subscriptionId
    }

    removeSubscription = (subscriptionId: String) => {
        this._subscriptions = [
            ...this._subscriptions.filter((subscription) => subscription.subscriptionId !== subscriptionId),
        ]
        this.refreshSubscriptions()
    }

    listSubscriptions = (): Array<Subscription> => {
        return this._subscriptions
    }

    createCombinedSubscribeConfig = (): SubscriberConfig => {
        const masterSubscriberConfig = new SubscriberConfig()
        const signalIds = new SignalIds()
        signalIds.setSignalidList(this._subscriptions.map((subscription) => subscription.signalId))
        const cId = new ClientId()
        cId.setId(this._clientId)
        masterSubscriberConfig.setSignals(signalIds)
        masterSubscriberConfig.setClientid(cId)
        masterSubscriberConfig.setOnchange(
            this._subscriptions.some((subscription) => subscription.callbackOnlyOnChange)
        )
        return masterSubscriberConfig
    }

    refreshSubscriptions = () => {
        if (this._subscriptions.length > 0) {
            const combinedSubscriberConfig = this.createCombinedSubscribeConfig()
            if (this._stream !== undefined) {
                console.debug('Killing old stream')
                this._stream.cancel()
            }

            console.debug('Creating new stream, reapplying all subscriptions')
            this._stream = this._networkServiceClient.subscribeToSignals(
                combinedSubscriberConfig,
                this.authenticationHeaders()
            )

            this._stream.on('data', (data) => {
                try {
                    this.broadcastSignalsToClientSubscribers(data)
                } catch (e) {
                    console.debug(`Received broker signal data, but an error occurred error=${JSON.stringify(data)}`)
                }
            })

            this._stream.on('metadata', (metadata) => {
                console.debug(`Received broker metadata=${JSON.stringify(metadata)}`)
            })

            this._stream.on('end', () => {
                console.debug('Signal subscription ended')

                // Global
                this._onSignalStreamEndCallback()

                // For each signal subscriber
                this._subscriptions.forEach((subscription) => {
                    if (subscription.onEndCallback) {
                        subscription.onEndCallback()
                    }
                })
            })

            this._stream.on('status', (status) => {
                console.debug(`Received broker status=${JSON.stringify(status)}`)
            })

            this._stream.on('error', (error) => {
                console.debug(`Received broker error=${JSON.stringify(error)}`)
            })
        }
    }

    applyPendingSubscriptions = () => {
        this._subscriptions = [...this._pendingSubscriptions, ...this._subscriptions]
        this._pendingSubscriptions = []
        this.refreshSubscriptions()
    }

    signalValueCallback = (grpcSignal: GrpcSignal, callback: (signal: SignalValue, signalType: SignalType) => void) => {
        if (!this.dropIncomingSignals) {
            if (this.isRawSignal(grpcSignal)) {
                this.handleRawSignal(grpcSignal, callback)
            } else if (this.isNumberSignal(grpcSignal)) {
                this.handleNumberSignal(grpcSignal, callback)
            } else if (this.isArbitrationSignal(grpcSignal)) {
                this.handleArbitrationSignal(grpcSignal, callback)
            } else {
                console.log(`Unknown signal type ${grpcSignal.getPayloadCase()}`)
            }
        }
    }

    handleNumberSignal = (grpcSignal: GrpcSignal, callback: (signal: SignalValue, signalType: SignalType) => void) => {
        const { name, namespace, timestamp } = this.extractCommonSignalInfo(grpcSignal)
        let signalValue: SignalValue | undefined = undefined
        switch (grpcSignal.getPayloadCase()) {
            case GrpcSignal.PayloadCase.DOUBLE:
                signalValue = new SignalValue(grpcSignal.getDouble(), name, timestamp, namespace)
                break
            case GrpcSignal.PayloadCase.INTEGER:
                signalValue = new SignalValue(grpcSignal.getInteger(), name, timestamp, namespace)
        }
        if (signalValue) {
            callback(signalValue, SignalType.NUMBER)
        }
    }

    handleArbitrationSignal = (
        grpcSignal: GrpcSignal,
        callback: (signal: SignalValue, signalType: SignalType) => void
    ) => {
        const { name, namespace, timestamp } = this.extractCommonSignalInfo(grpcSignal)
        const signalValue = new SignalValue('Header', name, timestamp, namespace)
        callback(signalValue, SignalType.ARBITRATION)
    }

    handleRawSignal = (grpcSignal: GrpcSignal, callback: (signal: SignalValue, signalType: SignalType) => void) => {
        const rawArray = Array.from(grpcSignal.getRaw_asU8())
        const { name, namespace, timestamp } = this.extractCommonSignalInfo(grpcSignal)
        const signalValue = new SignalValue(rawArray, name, timestamp, namespace)
        callback(signalValue, SignalType.RAW)
    }

    broadcastSignalsToClientSubscribers = (res: Signals) => {
        res.getSignalList().forEach((grpcSignal) => {
            const signalId = grpcSignal.getId()
            const name = signalId?.getName() as string
            const namespace = signalId?.getNamespace()?.getName() as string
            const callbacks = this._subscriptions
                .filter((it) => it.signalId.getName() === name && it.signalId.getNamespace()?.getName() === namespace)
                .map((subscription) => subscription.onSignalCallback)
            callbacks.forEach((callback) => this.signalValueCallback(grpcSignal, callback))
        })
    }

    isRawSignal = (grpcSignal: GrpcSignal) => {
        return grpcSignal.getRaw_asU8().length !== 0
    }

    isArbitrationSignal = (grpcSignal: GrpcSignal) => {
        return grpcSignal.getArbitration()
    }

    isNumberSignal = (grpcSignal: GrpcSignal) => {
        return (
            grpcSignal.getPayloadCase() === GrpcSignal.PayloadCase.DOUBLE ||
            grpcSignal.getPayloadCase() === GrpcSignal.PayloadCase.INTEGER
        )
    }

    extractCommonSignalInfo = (grpcSignal: GrpcSignal) => {
        const name = grpcSignal.getId()?.getName() as string
        const namespace = grpcSignal.getId()?.getNamespace()?.getName() as string
        const timestamp = grpcSignal.getTimestamp() / 1000
        return { name, namespace, timestamp }
    }

    authenticationHeaders = (): Metadata => {
        return {
            // Access token must also be sent in x-api-key header at the moment, the broker looks for the auth token in the old api-key field
            ...(this.brokerAccessToken !== undefined ? { 'x-api-key': this.brokerAccessToken } : {}),
            /* We can't use the standard Authorization header because it conflicts with the header that GCP Cloud Run uses to authenticate. 
            The symptom is 401 errors being sent to this client for the first few minutes of the cloud run containers lifetime. */
            //...(this.brokerAccessToken !== undefined ? { 'x-authorization': `Bearer ${this.brokerAccessToken}` } : {}),
            deadline: '' + (new Date().getMilliseconds() + 5000),
        }
    }

    initializePlaybackStream = async () => {
        console.log('Setup current playing mode')
        this.listenForPlayback((_playing) => {
            if (!this.availableFiles) {
                this.availableFiles = _playing.map((p) => (p as PlaybackStateImpl).files()).flat()
            } else {
                _playing.forEach((p) => {
                    const playing = p as PlaybackStateImpl
                    if (
                        playing.isRecording() ||
                        this.currentRecordingPlaybackMode.playbacks.filter((p) => p.isSame(playing)).length > 0
                    ) {
                        this.currentRecordingPlaybackMode.tryAddPlayback(playing)
                        const theNew = new CombinedPlaybackState()
                        theNew.playbacks = this.currentRecordingPlaybackMode.playbacks
                        this.currentRecordingPlaybackMode = theNew
                        this.updateRecordingListeners(this.currentRecordingPlaybackMode)
                        if (this.currentRecordingPlaybackMode.isStopped()) {
                            this.currentRecordingPlaybackMode = new CombinedPlaybackState()
                        }
                    } else {
                        this.currentPlaybackMode.tryAddPlayback(playing)

                        // TODO - Can we fix this weird stuff to get reactivity?!
                        const theNew = new CombinedPlaybackState()
                        theNew.playbacks = this.currentPlaybackMode.playbacks
                        this.currentPlaybackMode = theNew
                        if (this.currentPlaybackMode.files().length > 0) {
                            this.updatePlaybackListeners(theNew)
                        }
                    }
                })
            }
        })
    }

    updateRecordingListeners = (state: RecordingState) => {
        console.log(`is recording = ${state.isRecording()}`)
        this.recordingListeners.forEach((l) => l(state))
    }

    updatePlaybackListeners = (state: PlaybackState) => {
        this.playbackListeners.forEach((l) => l(state))
    }

    getNamespaces = async (): Promise<Array<Namespace>> => {
        const config = await this._systemServiceClient.getConfiguration(new Empty(), this.authenticationHeaders())
        return config.getNetworkinfoList().map((ni) => new Namespace(ni))
    }

    listSignals = async (namespace: string): Promise<Array<Frame>> => {
        const parentName = namespace //namespaceName.getNamespace().getName()
        const request = new NameSpace()
        request.setName(parentName)
        const response: Frames = await this._systemServiceClient.listSignals(request, this.authenticationHeaders())
        const frames = response.getFrameList()

        const frameGroup = frames.map((frame) => {
            const frameSignalId = frame.getSignalinfo()?.getId()
            const signalList = frame.getChildinfoList()
            return new Frame(
                frameSignalId?.getName(),
                frameSignalId?.getNamespace()?.getName(),
                frame.getSignalinfo()?.getMetadata(),
                signalList.map((signal) => {
                    const signalId = signal.getId()
                    return new Signal(signalId?.getName(), signal.getMetadata())
                })
            )
        })
        return frameGroup
    }

    record = async (session: CreateRecordingSession) => {
        if (this.currentRecordingPlaybackMode.isRecording()) {
            throw new Error('You must stop current recording first')
        }
        this.currentRecordingPlaybackMode = new CombinedPlaybackState()
        const mode = new PlaybackMode()
        mode.setMode(Mode.RECORD)

        const recordings = session.recordings.map(
            (r) => new PlaybackStateFileImpl(this.brokerUrl, this.isCloudBroker, r.filename, r.namespace)
        )

        const playback = new PlaybackStateImpl(mode, recordings, this.sendSinglePlaybackCommand)
        return await this.sendSinglePlaybackCommand(Mode.RECORD, playback)
    }

    /*
        This is some ugly shit going on.
        We really need to add a getRecordings() to the broker api
     */
    getAvailableFiles = async (): Promise<Array<PlaybackStateFile>> => {
        let stream = this._trafficServiceClient.playTrafficStatus(new Empty(), this.authenticationHeaders())

        // TODO - Remove these but som debug can be good a while
        console.log(' => getAvailableRecordings')

        return new Promise<Array<PlaybackStateFile>>((resolve, reject) => {
            stream.on('data', (res) => {
                const playbackInfoList = res.getPlaybackinfoList()
                stream.cancel()
                if (playbackInfoList.length === 0) {
                    console.log(' <= getAvailableRecordings (empty list)')

                    resolve([])
                }
                try {
                    const playbacks: PlaybackStateImpl[] = playbackInfoList
                        .map((row) => {
                            if (
                                row.getPlaybackconfig()?.getNamespace() &&
                                row.getPlaybackconfig()?.getFiledescription() &&
                                row.getPlaybackmode()
                            ) {
                                return new PlaybackStateImpl(
                                    row.getPlaybackmode() as PlaybackMode,
                                    [
                                        new PlaybackStateFileImpl(
                                            this.brokerUrl,
                                            this.isCloudBroker,
                                            row.getPlaybackconfig()?.getFiledescription()?.getPath() as string,
                                            row.getPlaybackconfig()?.getNamespace()?.getName() as string
                                        ),
                                    ],
                                    this.sendSinglePlaybackCommand
                                )
                            }
                            return undefined
                        })
                        .filter((playback) => playback !== undefined) as PlaybackStateImpl[]
                    const files = playbacks
                        // Never list recordings in progress
                        .filter((p) => !p.isRecording())
                        .map((p) => p.files())
                        .flat() as Array<PlaybackStateFileImpl>

                    resolve(files)
                    console.log(` <= getAvailableRecordings (size: ${files.length})`)
                } catch (e: any) {
                    console.error(e)
                }
            })

            stream.on('error', (err) => {
                reject(err)
            })

            stream.on('end', () => {})
        })
    }

    playFiles = async (files: PlaybackStateFile[], mode: Mode = Mode.PLAY) => {
        // Perhaps better to check if its stopped, otherwise throw an exception saying
        // that you need to stop before pausing?
        this.currentPlaybackMode.stop()
        await await new Promise((r) => setTimeout(r, 250)) // TODO remove this sleep and do a proper wait for isStopped() === true
        this.currentPlaybackMode = new CombinedPlaybackState()
        this.updatePlaybackListeners(this.currentPlaybackMode)
        const playbackStateFiles = files.map(
            (file) => new PlaybackStateFileImpl(this.brokerUrl, this.isCloudBroker, file.path(), file.namespace())
        )
        const playModeAsDefault = new PlaybackMode()
        playModeAsDefault.setMode(mode)
        const playback = new PlaybackStateImpl(playModeAsDefault, playbackStateFiles, this.sendSinglePlaybackCommand)
        this.sendSinglePlaybackCommand(mode, playback)
    }

    /**
     *
     * @param command 0 = play, 1 = pause, 2 = stop
     * @param client
     * @param mode
     * @param path
     * @param namespace
     * @returns {Promise<void>}
     */
    sendSinglePlaybackCommand = async (
        command: Mode,
        playback: PlaybackStateImpl,
        seekOffset: number | undefined = 0
    ) => {
        return this.sendAllPlaybackCommand(command, [playback], seekOffset)
    }

    sendAllPlaybackCommand = async (
        command: Mode,
        playbacks: Array<PlaybackStateImpl>,
        seekOffset: number | undefined = 0
    ) => {
        // eslint-disable-next-line no-undef
        const playbackInfos = new PlaybackInfos()
        //mode: api.default.Mode.PAUSE,
        //    register: 'playback',

        const playbackInfoArray = playbacks
            .map((playback) => {
                return playback.files().map((playing: PlaybackStateFile) => {
                    // eslint-disable-next-line no-undef
                    const playbackmode = new PlaybackMode()
                    // eslint-disable-next-line no-undef
                    const playbackInfo = new PlaybackInfo()

                    // eslint-disable-next-line no-undef
                    const config = new PlaybackConfig()
                    // eslint-disable-next-line no-undef
                    const desc = new FileDescription()
                    // eslint-disable-next-line no-undef
                    const namespace = new NameSpace()

                    desc.setPath(playing.path())
                    namespace.setName(playing.namespace())
                    config.setFiledescription(desc)
                    config.setNamespace(namespace)

                    if (command === Mode.SEEK) {
                        const offsetTimeUs = seekOffset === 0 ? 0 : seekOffset * 1000
                        playbackmode.setOffsettime(offsetTimeUs)
                    }
                    playbackmode.setMode(command)
                    playbackInfo.setPlaybackconfig(config)
                    playbackInfo.setPlaybackmode(playbackmode)
                    return playbackInfo
                })
            })
            .flat()

        //
        // We fake a seek playstate to make sure that listeners are aware of that seek is requested
        // since we cannot know if we will get that from the broker
        if (command === Mode.SEEK) {
            const offsetTimeUs = seekOffset === 0 ? 0 : seekOffset * 1000
            playbacks.map((state) => {
                state.playbackMode.setMode(Mode.SEEK)
                state.playbackMode.setOffsettime(offsetTimeUs)
                this.updatePlaybackListeners(state)
            })
        }

        playbackInfos.setPlaybackinfoList(playbackInfoArray)
        const response: PlaybackInfos = await this._trafficServiceClient.playTraffic(
            playbackInfos,
            this.authenticationHeaders()
        )
        const errors = response
            .getPlaybackinfoList()
            .filter((playbackInfo) => playbackInfo.getPlaybackmode()?.getErrormessage() !== '')
            .map((playbackInfo) => playbackInfo.getPlaybackmode()?.getErrormessage())

        if (errors.filter((error) => error == 'enospc').length > 0) {
            throw new FullDiskError()
        } else if (errors.length > 0) {
            throw new Error(errors[0])
        }

        return true
    }

    listenOnPlayback = (callback: (playback: PlaybackState) => void) => {
        this.playbackListeners.push(callback)
    }

    listenOnRecordings = (callback: (playback: RecordingState) => void) => {
        this.recordingListeners.push(callback)
    }

    listenForPlayback = (callback: (playback: Array<PlaybackState>) => void) => {
        let stream = this._trafficServiceClient.playTrafficStatus(new Empty(), this.authenticationHeaders())

        stream.on('data', (response) => {
            if (response.getPlaybackinfoList().length === 0) {
                return
            }
            try {
                const playbacks: PlaybackStateImpl[] = response
                    .getPlaybackinfoList()
                    .map((row) => {
                        if (
                            row.getPlaybackconfig()?.getNamespace() &&
                            row.getPlaybackconfig()?.getFiledescription() &&
                            row.getPlaybackmode()
                        ) {
                            return new PlaybackStateImpl(
                                row.getPlaybackmode() as PlaybackMode,
                                [
                                    new PlaybackStateFileImpl(
                                        this.brokerUrl,
                                        this.isCloudBroker,
                                        row.getPlaybackconfig()?.getFiledescription()?.getPath() as string,
                                        row.getPlaybackconfig()?.getNamespace()?.getName() as string
                                    ),
                                ],
                                this.sendSinglePlaybackCommand
                            )
                        }
                        return undefined
                    })
                    .filter((playback) => playback !== undefined) as PlaybackStateImpl[]
                callback(playbacks)
            } catch (e: any) {
                console.error(e)
            }
        })

        stream.on('error', (err) => {
            console.error('Playback Stream error: ', err)
            // To prevent callbacks when client have not registered any listeners, only the
            // internal thingy is registered
            // Consider improving this
            if (this.playbackListeners.length > 0 || this.recordingListeners.length > 0) {
                this._onPlaybackStreamEndCallback()
            }
        })

        stream.on('end', () => {
            console.log('Playback stream ended')
            // To prevent callbacks when client have not registered any listeners, only the
            // internal thingy is registered
            // Consider improving this
            if (this.playbackListeners.length > 0 || this.recordingListeners.length > 0) {
                this._onPlaybackStreamEndCallback()
            }
        })
    }

    doUploadFile = async (path: string, buf: Uint8Array, stepProgress: (i: number) => void): Promise<void> => {
        const CHUNK_SIZE = 500000

        const chunks = Math.ceil(buf.byteLength / CHUNK_SIZE)
        const req = new FileUploadChunkRequest()

        // TODO - Make sure we understand WHAT this is AND WHY? Its really hard to understand
        // This is the total upload time allowed, not for each chunk or anything.
        // From old client: This gives our roughly 10 s/mb (over a 4G network): But what do they mean with this?
        // Basically... 180000 ms = 3min would be the total time that the broker will allow the upload process to go on.
        req.setUploadtimeout(Math.max(Math.floor(buf.byteLength / 1000) * 10, 180000))
        req.setChunks(chunks)

        const desc = new FileDescription()
        desc.setPath(path)
        desc.setSha256(sha256(buf))
        req.setFiledescription(desc)

        for (let id = 0; id < chunks; id++) {
            req.setChunkid(id)

            const start = id * CHUNK_SIZE
            let end = start + CHUNK_SIZE
            if (end > buf.byteLength) {
                end = buf.byteLength
            }

            req.setChunk(buf.slice(start, end))
            const resp = await this._systemServiceClient.uploadFileChunk(req, this.authenticationHeaders())
            stepProgress(end - start)
            if (resp.getFinished()) {
                return
            } else if (resp.getCancelled()) {
                throw new Error(`Upload was cancelled.`)
            } else if (resp.getErrormessage() !== '') {
                throw new Error(`Upload failed. Server message: ${resp.getErrormessage()}`)
            }
        }
    }

    uploadFile = async (file: File, callback: (bytes: number, percent: number) => void): Promise<void> => {
        let totalBytes = 0
        let percent = 0

        const buf = await this.readFilePromise(file)
        const buff = new Uint8Array(buf)
        totalBytes += buf.byteLength

        let uploadFileName
        if (file.webkitRelativePath) {
            uploadFileName = file.webkitRelativePath.substring(file.webkitRelativePath.indexOf('/') + 1)
        } else {
            uploadFileName = file.name
        }

        return this.doUploadFile(uploadFileName, buff, (n: number) => {
            percent += (n / totalBytes) * 100
            callback(n, percent)
        })
    }

    reloadConfiguration = async (): Promise<ConfigurationInfo> => {
        try {
            // eslint-disable-next-line no-undef
            const c = new SystemServicePromiseClient(this.brokerUrl)
            const msg = await c.reloadConfiguration(new Empty(), this.authenticationHeaders())
            if (msg.getErrormessage() !== '') {
                throw new Error(msg.getErrormessage())
            } else {
                if (msg.getConfiguration()?.getInterfacesjson()) {
                    return {
                        jsonString: JSON.stringify(
                            JSON.parse(
                                new TextDecoder().decode(msg.getConfiguration()?.getInterfacesjson() as Uint8Array)
                            ),
                            null,
                            2
                        ),
                        infoMessage: msg.getConfiguration()?.getInterfacesinfo(),
                    }
                } else {
                    throw new Error('No configuration found')
                }
            }
        } catch (err) {
            throw err
        }
    }

    readFilePromise = (file: File): Promise<ArrayBuffer> => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.onload = () => {
                resolve(reader.result as ArrayBuffer)
            }
            reader.onerror = reject
            reader.readAsArrayBuffer(file)
        })
    }

    getLicence = (): Promise<LicenseInfo> => {
        return this._systemServiceClient.getLicenseInfo(new Empty(), this.authenticationHeaders())
    }

    getConfiguration = async (): Promise<Configuration> => {
        return this._systemServiceClient.getConfiguration(new Empty(), this.authenticationHeaders())
    }

    getInterfacesJsonAsString = async (): Promise<ConfigurationInfo> => {
        const conf = await this._systemServiceClient.getConfiguration(new Empty(), this.authenticationHeaders())
        return {
            jsonString: JSON.stringify(
                JSON.parse(new TextDecoder().decode(conf.getInterfacesjson() as Uint8Array)),
                null,
                2
            ),
            infoMessage: conf.getInterfacesinfo(),
        }
    }

    applyLicense = (license: License): Promise<LicenseInfo> => this._systemServiceClient.setLicense(license) // Does this not need the auth header metadata option?

    upgrade = async (brokerTag: string | undefined, clientTag: string | undefined) => {
        let upgradestr = ''
        if (brokerTag) {
            upgradestr += `BEAMYTBROKER_TAG="${brokerTag}"\n`
            upgradestr += `REMOTIVEBROKER_TAG="${brokerTag}"\n`
        }
        if (clientTag) {
            upgradestr += `BEAMYWEBCLIENT_TAG="${clientTag}"\n`
            upgradestr += `REMOTIVEWEBAPP_TAG="${clientTag}"\n`
        }

        const buf = new TextEncoder().encode(upgradestr)
        // eslint-disable-next-line no-undef
        const req = new FileUploadChunkRequest()
        req.setChunks(1)
        // eslint-disable-next-line no-undef
        const desc = new FileDescription()
        desc.setPath('upgrade')
        desc.setSha256(sha256(buf))
        req.setFiledescription(desc)
        // eslint-disable-next-line no-undef
        //const client = new api.default.SystemServicePromiseClient(this.beamyServerIp)
        req.setChunkid(0)
        req.setChunk(buf)
        const resp = await this._systemServiceClient.uploadFileChunk(req, this.authenticationHeaders())
        if (resp.getFinished()) {
            //return {result: 'OK'}
        } else if (resp.getCancelled()) {
            return new Error('Upgrade request was cancelled')
        } else if (resp.getErrormessage() !== '') {
            throw new Error(`Upgrade request failed. Message from server: ${resp.getErrormessage()}`)
        }
    }

    batchDeleteFiles = (files: Array<PlaybackStateFile>) => {
        const fileDescriptions = new FileDescriptions()
        const fileDescriptionsList = files.map((file) => {
            const fileDescription = new FileDescription()
            return fileDescription.setPath(file.path())
        })

        fileDescriptions.setFiledescriptionsList(fileDescriptionsList)
        return this._systemServiceClient.batchDeleteFiles(fileDescriptions, this.authenticationHeaders())
    }

    dropSignals = (drop: boolean) => {
        this.dropIncomingSignals = drop
    }

    downloadAsString = (file: string): Promise<string | undefined> => {
        const desc = new FileDescription()
        const descs = new FileDescriptions()
        desc.setPath(file)
        descs.addFiledescriptions(desc)

        const response = this._systemServiceClient.batchDownloadFiles(descs, this.authenticationHeaders())
        return new Promise<string | undefined>((resolve, reject) => {
            let data: Uint8Array
            response
                .on('data', (resp) => {
                    data = resp.getChunk() as Uint8Array
                })
                .on('error', (error) => reject('error'))
                // no file found
                .on('end', () => (data ? resolve(new TextDecoder().decode(data)) : resolve(undefined)))
        })
    }
}
