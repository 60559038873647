import { useEffect, useState } from 'react'
import { Card } from 'react-bootstrap'
import ReCAPTCHA from 'react-google-recaptcha'
import CloudApi from '../api/CloudApi'
import { isDemo } from '../utils/demo'
import { LoginQuote, loginQuotes } from '../utils/loginPageQuotes'
import useInterval from '../hooks/useInterval'
import { useProductAnalyticsClient } from '../utils/ProductAnalytics'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../utils/toast'
import { ERROR_PARAM, REDIRECT_URL_PARAM, UNKNOWN_USER_PARAM_VALUE } from '../utils/queryParams'
import { PageDetails } from '../utils/pageDetails'

interface LoginProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
}

export default function Login(props: LoginProps) {
    const [loginCheckComplete, setLoginCheckComplete] = useState(false)
    const [currentLoginQuote, setCurrentLoginQuote] = useState<LoginQuote>(
        loginQuotes[Math.floor(Math.random() * loginQuotes.length)]
    )
    const [searchParams] = useSearchParams()

    useEffect(() => {
        props.updateCurrentPageDetails({
            documentTitle: 'Login',
            productAnalyticsEventName: 'Login',
        })
    }, [])

    useEffect(() => {
        const queryParamError = searchParams.get(ERROR_PARAM)
        console.log(queryParamError)
        if (queryParamError === UNKNOWN_USER_PARAM_VALUE) {
            toast.error(
                formattedToastMessage(
                    `Unknown user`,
                    `You tried to sign in with an account 
            that does not exist on our platform.`
                ),
                { autoClose: 20_000 }
            )
        }
    }, [searchParams])

    const productAnalyticsClient = useProductAnalyticsClient({
        user: undefined,
        billableUnit: undefined,
    })

    const requestIsFromCli = () => {
        const urlParams = new URLSearchParams(window.location.search)
        const redirectUrl = urlParams.get(REDIRECT_URL_PARAM)

        // We do not want automatic login if the redirect url is an external hrl since this is the cli
        return redirectUrl !== null && redirectUrl.startsWith('http')
    }

    useEffect(() => {
        if (CloudApi.hasAuthHeaderCookie()) {
            // We do not want automatic login if the redirect url is an external hrl since this is the cli
            if (!requestIsFromCli()) {
                CloudApi.defaultUserBu()
                    .then((res) => {
                        window.location.replace(CloudApi.getOrganisationHomeUrlFromUid(res.data.organisation.uid))
                    })
                    .catch((err) => setLoginCheckComplete(true))
            } else {
                setLoginCheckComplete(true)
            }
        } else if (!loginCheckComplete) {
            setLoginCheckComplete(true)
            console.log('no auth cookie found')
        }
    }, [])

    useInterval(() => {
        setCurrentLoginQuote(loginQuotes[(loginQuotes.indexOf(currentLoginQuote) + 1) % loginQuotes.length])
    }, 15_000)

    const onCaptchaChange = (value: string | null) => {
        console.log(value)
        if (value) {
            // Support for CLI in demo
            const urlParams = new URLSearchParams(window.location.search)
            const redirectUrl = urlParams.get(REDIRECT_URL_PARAM)
            if (redirectUrl) {
                window.location.replace(
                    `${CloudApi.getBackendURL()}/callback?token=${value}&redirectUrl=${redirectUrl}`
                )
            } else {
                window.location.replace(`${CloudApi.getBackendURL()}/callback?token=${value}`)
            }
        }
    }

    const login = () => {
        productAnalyticsClient.track('login')
        CloudApi.setLoginUrl(false)
    }

    const signUp = () => {
        productAnalyticsClient.track('signup')
        CloudApi.setLoginUrl(true)
    }

    const loginButton = () => {
        return isDemo() ? (
            <div className="d-flex flex-column align-items-center justify-content-center">
                <ReCAPTCHA onChange={onCaptchaChange} sitekey={process.env.REACT_APP_GOOGLE_SITE_KEY || ''} />
            </div>
        ) : (
            <div style={{ zIndex: 0 }} className="d-flex flex-column">
                <div>
                    <button className="btn remotive-btn-primary remotive-btn" onClick={login}>
                        <img src="https://img.icons8.com/color/24/000000/google-logo.png" className="me-2" />
                        Sign in with Google
                    </button>
                </div>
                <div className="mt-3">
                    <button className="btn remotive-btn-success remotive-btn-md" onClick={signUp}>
                        <img src="https://img.icons8.com/color/18/000000/google-logo.png" className="me-2" />
                        Sign up with Google
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            <div className="position-fixed">
                <video
                    crossOrigin="anonymous"
                    style={{ WebkitFilter: 'blur(8px)', objectFit: 'cover' }}
                    className="position-fixed w-100 h-100"
                    id="section-c6a9cb6-player"
                    autoPlay={true}
                    loop={true}
                    muted={true}
                    preload={'none'}
                    src="https://remotivelabs.com/wp-content/uploads/2022/10/remotiveLabs.mp4"
                ></video>
                <div className="w-100 h-100 p-5 position-fixed remotive-primary-90-background opacity-75"></div>
            </div>
            <div className="w-100 h-100 d-flex justify-content-center align-items-center">
                <div
                    style={{ zIndex: 1, maxWidth: 400, marginTop: '55vh' }}
                    className="d-none d-lg-block text-light text-start"
                >
                    <p className="fs-1 lexend-bold">”</p>
                    <p className="fs-5">
                        {currentLoginQuote?.quoteText} <br />
                        <span className="fs-6">{currentLoginQuote?.quotee}</span>
                    </p>
                </div>
                <div className="ms-lg-5">
                    <Card
                        style={{ maxWidth: 500 }}
                        className="m-2 border-0 rounded-5 shadow-lg remotive-primary-5-background px-1 px-md-5"
                    >
                        <div className="justify-content-center">
                            <div className="card-body">
                                <div style={{ textAlign: 'center', paddingTop: '3rem', paddingBottom: '3rem' }}>
                                    <div className="">
                                        <img width="200" src="./remotive-labs-logo-pos@3x.png" />
                                    </div>
                                </div>
                                <div className="m-2 mb-5">
                                    <p className="fs-4 m-0">
                                        Sign in to{' '}
                                        <span className=" lexend-bold">
                                            Remotive<span className="remotive-primary-50-color">Cloud</span>
                                        </span>
                                        {isDemo() ? <span className="lexend-bold remotive-font-md">Demo</span> : ''}
                                    </p>

                                    <p className="remotive-font-sm mb-1 text-secondary">
                                        {isDemo() ? (
                                            <>
                                                Complete the <b>reCAPTCHA</b> below to access the demo
                                            </>
                                        ) : (
                                            <>
                                                Sign up to access the cloud console, it's <b>free!*</b>
                                            </>
                                        )}
                                    </p>
                                </div>
                                {loginCheckComplete && loginButton()}
                                {!loginCheckComplete && <p>Please wait while trying to login...</p>}
                                <br />
                                <br />
                                <p className="remotive-font-md text-secondary">
                                    Want to know more?
                                    <br />
                                    Visit <a href="https://www.remotivelabs.com">remotivelabs.com</a> or contact{' '}
                                    <a href="mailto:hello@remotivelabs.com">hello@remotivelabs.com</a>
                                </p>
                                {!isDemo() && (
                                    <>
                                        <p className="remotive-font-sm text-secondary">
                                            We currently only support Google accounts, if you do not have one you can
                                            still use your existing business email as a Google account. How to
                                            accomplish this is explained{' '}
                                            <a
                                                href="https://support.google.com/accounts/answer/27441?hl=en"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                here
                                            </a>
                                        </p>
                                        <p className="remotive-font-xs">
                                            <b>*</b>The free plan currently includes four sample recordings of real
                                            vehicle data, the ability to upload one Candump, create one project and
                                            invite one collaborator to join your organization. Additional limitations
                                            may apply.
                                        </p>
                                    </>
                                )}
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </>
    )
}
