import { useState, useEffect } from 'react'
import { Modal, InputGroup, Form } from 'react-bootstrap'
import { FrameEntry, SignalEntry } from '../../../api/CloudApi/types'
import SignalItem from './SignalItem'
import WarningRounded from '@mui/icons-material/WarningRounded'
import { CloseRounded } from '@mui/icons-material'
import { PageState } from '../../../types/PageState'
import LoadingContainer from '../../../components/LoadingContainer'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../utils/toast'

interface SelectSignalsModalProps {
    show: boolean
    selectableSignalsWithParentFrame: Array<FrameEntry>
    selectedSignals: Array<SignalEntry>
    handleCloseFunction: () => void
    selectSignalsFunction: (signals: Array<SignalEntry>) => void
}

const TIME_SERIES_PANEL_SIGNAL_LIMIT = 6

export default function SelectSignalsModal(props: SelectSignalsModalProps) {
    const [signalsFilteredBySearch, setSignalsFilteredBySearch] = useState<Array<FrameEntry> | undefined>(undefined)
    const [selectedButNotAppliedSignals, setSelectedButNotAppliedSignals] = useState<Array<SignalEntry>>([])

    useEffect(() => {
        console.log('Mounting select signals modal!')
    }, [])

    useEffect(() => {
        if (props.show) {
            setSelectedButNotAppliedSignals(props.selectedSignals)
            setSignalsFilteredBySearch(props.selectableSignalsWithParentFrame)
        }
    }, [props.show, props.selectedSignals, props.selectableSignalsWithParentFrame])

    const searchOnChange = (event: any) => {
        const searchKeyInLowerCase = new String(event.target.value).toLowerCase()
        if (searchKeyInLowerCase.length >= 3) {
            const frameAfterSearch: Array<FrameEntry> = props.selectableSignalsWithParentFrame
                .map((frame) => {
                    if (
                        frame.name.toLowerCase().includes(searchKeyInLowerCase) ||
                        frame.comments?.toLowerCase().includes(searchKeyInLowerCase)
                    ) {
                        // // If frame name contains search key, return frame + all signals
                        return frame
                    } else {
                        // If frame doesn't contain search key, filter containing signals on search key and then return frame + signals
                        const signalsAfterNameSearch = frame.signals.filter((signal) =>
                            signal.name.toLowerCase().includes(searchKeyInLowerCase)
                        )
                        if (signalsAfterNameSearch.length > 0) {
                            return { ...frame, signals: signalsAfterNameSearch } as FrameEntry
                        }

                        // If signal doesn't contain the searh key, check description
                        const signalsAfterDescriptionSearch = frame.signals.filter((signal) => {
                            return signal.comments?.toLowerCase().includes(searchKeyInLowerCase)
                        })
                        if (signalsAfterDescriptionSearch.length > 0) {
                            return { ...frame, signals: signalsAfterDescriptionSearch } as FrameEntry
                        }
                    }
                    // No match
                    return undefined
                })
                .filter((frame) => frame !== undefined) as Array<FrameEntry>
            setSignalsFilteredBySearch(frameAfterSearch)
        }
        if (searchKeyInLowerCase.length === 0) {
            setSignalsFilteredBySearch(props.selectableSignalsWithParentFrame)
        }
    }

    const createSignalList = (frameParent: string, signals: Array<SignalEntry>, isDisabled: boolean) => {
        return signals.map((signal) => (
            <div key={`${frameParent}::${signal.name}::parent`}>
                <div className="ms-2">
                    <SignalItem
                        thisSignalEntry={signal}
                        selectedSignals={selectedButNotAppliedSignals}
                        removeSelectedSignalFunction={removeSelectedButNotAppliedSignal}
                        addSelectedSignalFunction={addSelectedButNotAppliedSignal}
                        isDisabled={isDisabled}
                    />
                </div>
            </div>
        ))
    }

    const createFrameAndSignalsList = () => {
        if (signalsFilteredBySearch && signalsFilteredBySearch.length > 0) {
            return signalsFilteredBySearch.map((frame) => {
                const isDisabled = frame.cnt <= 0
                return (
                    <div key={`${frame.name}::parent`} className="my-3 text-truncate">
                        <div className="remotive-font-md d-flex align-items-center">
                            <p className="m-0 lexend-bold">{`${frame.name}`} </p>
                            <div className="m-0 text-secondary remotive-font-sm">
                                {isDisabled && (
                                    <div className="d-flex align-items-center ms-3">
                                        <WarningRounded className="text-warning me-1" sx={{ fontSize: 12 }} />
                                        <p className="m-0">This frame is not present in this recording</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="d-flex flex-row align-items-end lh-1">
                            <p className="m-0 remotive-font-xs remotive-primary-50-color">
                                {frame.senders
                                    ? frame.senders.map((it, index) =>
                                          index === frame.senders.length - 1 ? it : `${it}, `
                                      )
                                    : 'N/A'}
                            </p>
                            <p className="m-0 ms-4 remotive-font-xs remotive-primary-50-color">
                                {frame.receivers
                                    ? frame.receivers.map((it, index) =>
                                          index === frame.receivers.length - 1 ? it : `${it}, `
                                      )
                                    : 'N/A'}
                            </p>
                            <p className="m-0 ms-4 remotive-font-xs remotive-primary-50-color">
                                {frame.cycleTime ? `${frame.cycleTime}ms` : 'N/A'}
                            </p>
                        </div>
                        <p className="m-0 remotive-font-sm text-secondary">{frame.comments}</p>
                        {createSignalList(frame.name, frame.signals, isDisabled)}
                    </div>
                )
            })
        }
        if (signalsFilteredBySearch !== undefined) {
            return (
                <div className="d-flex align-items-center justify-content-center">
                    <WarningRounded sx={{ fontSize: 20 }} className="text-warning me-2" />
                    <p className="my-5 remotive-font">No signals in this recording match the current query</p>
                </div>
            )
        }
        return (
            <>
                <LoadingContainer />
            </>
        )
    }

    const closeModal = () => {
        props.handleCloseFunction()
        setSignalsFilteredBySearch(props.selectableSignalsWithParentFrame)
    }

    const createPanels = () => {
        props.selectSignalsFunction(selectedButNotAppliedSignals)
        closeModal()
    }

    const addSelectedButNotAppliedSignal = (signalEntry: SignalEntry) => {
        if (selectedButNotAppliedSignals.length < TIME_SERIES_PANEL_SIGNAL_LIMIT) {
            setSelectedButNotAppliedSignals([...selectedButNotAppliedSignals, signalEntry])
        } else {
            toast.error(
                formattedToastMessage(
                    'Signal limit reached',
                    `You can not have more than ${TIME_SERIES_PANEL_SIGNAL_LIMIT} signals per time series panel. Add another panel to visualize more signals.`
                ),
                { autoClose: 10_000 }
            )
        }
    }

    const removeSelectedButNotAppliedSignal = (signalEntry: SignalEntry) => {
        setSelectedButNotAppliedSignals(
            selectedButNotAppliedSignals.filter(
                (it) => constructSignalNameKeyFromEntry(it) !== constructSignalNameKeyFromEntry(signalEntry)
            )
        )
    }

    const constructSignalNameKeyFromEntry = (signalEntry: SignalEntry) => {
        return constructSignalNameKey(signalEntry.name, signalEntry.frameName)
    }

    const constructSignalNameKey = (signalName: string, frameName: string) => {
        return `${frameName}::${signalName}`.toLowerCase()
    }

    const currentlySelectedSignals = () => {
        return selectedButNotAppliedSignals.map((signal) => (
            <div key={signal.name} className="me-1 mt-1">
                <div className="rounded remotive-primary-10-background p-1 px-2">
                    <div>
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="m-0 ms-1 remotive-font-sm">
                                {signal.name}{' '}
                                <span className="remotive-primary-60-color ms-1 remotive-font-xs">{`${
                                    signal.unit ?? 'unitless'
                                }`}</span>
                            </p>
                            <button
                                className="ms-2 btn remotive-btn-sm m-0 p-0 remotive-btn-no-bg"
                                onClick={() => removeSelectedButNotAppliedSignal(signal)}
                            >
                                <CloseRounded sx={{ fontSize: 20 }} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        ))
    }

    return (
        <>
            <>
                <Modal size="xl" className="col-12 lexend-regular" show={props.show} onHide={closeModal}>
                    <Modal.Header closeVariant="white" className="remotive-primary-70-background" closeButton>
                        <Modal.Title className="text-light">Select signals</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="pb-0">
                        <div className="mb-2">
                            <InputGroup>
                                <Form.Control
                                    autoFocus={props.show}
                                    placeholder={`Search, e.g ${
                                        props.selectableSignalsWithParentFrame?.[0]?.name ?? 'Speed'
                                    }`}
                                    aria-label="Small"
                                    aria-describedby="inputGroup-sizing-sm"
                                    onChange={searchOnChange}
                                />
                            </InputGroup>
                            <p className="m-0 ms-1 remotive-font-xs text-secondary">{`Showing ${
                                signalsFilteredBySearch
                                    ?.map((it) => it.signals.length)
                                    .reduce((accumulator, current) => accumulator + current, 0) ?? 0
                            } of ${props.selectableSignalsWithParentFrame
                                .map((it) => it.signals.length)
                                .reduce((accumulator, current) => accumulator + current, 0)} results`}</p>
                        </div>
                        <Form>
                            <div className="row">
                                <div className="m-0 col-6 mb-1">
                                    <p className="m-0 lexend-bold remotive-font">Name</p>
                                    <div className="d-flex flex-row align-items-end lh-1">
                                        <p className="m-0 remotive-font-sm remotive-primary-50-color">Senders</p>
                                        <p className="m-0 ms-4 remotive-font-sm remotive-primary-50-color">Receivers</p>
                                        <p className="m-0 ms-4 remotive-font-sm remotive-primary-50-color">
                                            Cycle time
                                        </p>
                                    </div>
                                </div>
                                <div className="m-0 col-2 mb-1 d-flex flex-column align-items-start">
                                    <p className="m-0 lexend-bold remotive-font">Unit</p>
                                    <div className=" lh-1">
                                        <p className="m-0 remotive-font-sm remotive-primary-50-color">From .dbc file</p>
                                    </div>
                                </div>
                                <div className="m-0 col-2 mb-1 d-flex flex-column align-items-start">
                                    <p className="m-0 lexend-bold remotive-font">Length</p>
                                    <div className=" lh-1">
                                        <p className="m-0 remotive-font-sm remotive-primary-50-color">From .dbc file</p>
                                    </div>
                                </div>
                                <div className="m-0 col-2 mb-1 d-flex flex-column align-items-start">
                                    <p className="m-0 lexend-bold remotive-font">Min - max</p>
                                    <div className=" lh-1">
                                        <p className="m-0 remotive-font-sm remotive-primary-50-color">From .dbc file</p>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div
                                    className="w-100 border-bottom border-top py-1"
                                    style={{ overflowY: 'scroll', maxHeight: '50vh' }}
                                >
                                    {createFrameAndSignalsList()}
                                </div>
                            </div>
                        </Form>
                    </Modal.Body>
                    <div className="border-0 p-3 text-start">
                        {/* We could tell the user about cost here */}
                        <p className="m-0 lexend-bold remotive-font-sm">Selected signals:</p>
                        <div className="d-flex justify-content-between flex-nowrap">
                            <div className="d-flex flex-wrap text-truncate">{currentlySelectedSignals()}</div>
                            <div className="d-flex align-self-end">
                                <button className="btn remotive-btn remotive-btn-primary me-3" onClick={closeModal}>
                                    Cancel
                                </button>
                                <button className="btn remotive-btn remotive-btn-success" onClick={createPanels}>
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        </>
    )
}
