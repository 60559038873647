import { Form, Modal, Spinner } from 'react-bootstrap'
import { Project, RecordingSession } from '../api/CloudApi/types'
import { useEffect, useState } from 'react'
import CloudApi from '../api/CloudApi'
import ErrorContainer from '../components/ErrorContainer'
import { CheckIcon, WarningIcon } from '../assets/Icons'
const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

interface ImportRecordingSessionProps {
    show: boolean
    currentProject?: Project
    recordingsInProject?: Array<RecordingSession>
    onRecordingImported: () => void
    onModalClose: (imported: boolean) => void
}

enum ImportState {
    NOT_STARTED,
    LOADING,
    ERROR,
    DONE,
}

export interface RecordingSessionInProgress {
    recordingSession: RecordingSession
    state: ImportState
    errorText?: string
}

export default function ImportRecordingSessionModal(props: ImportRecordingSessionProps) {
    const [importState, setImportState] = useState(ImportState.LOADING)
    const [errorText, setErrorText] = useState<string>()
    const [availableSampleRecordings, setAvailableSampleRecordings] = useState<Array<RecordingSession>>()
    const [recordingsToImport, setRecordingsToImport] = useState<Array<RecordingSessionInProgress>>()
    const [selectedRecordings, setSelectedRecordings] = useState<Array<string>>([])

    const importInProgress = (): boolean => {
        const state = recordingsToImport?.filter((r) => r.state !== ImportState.LOADING)
        return state?.length !== recordingsToImport?.length
    }

    const onRecordingSelectionChanged = (sessionId: string, isChecked: boolean) => {
        const copy = [...selectedRecordings]
        const index = copy.indexOf(sessionId)
        if (index !== -1) {
            // Removes from array
            const _ = copy.splice(index, 1)
        }
        if (isChecked) {
            copy.push(sessionId)
        }
        setSelectedRecordings(copy)
    }

    const isRecordingAlreadyInProject = (recordingSession: RecordingSession) => {
        const r = props.recordingsInProject?.filter((r) => r.sessionId === recordingSession.sessionId)
        return !!(r && r.length > 0)
    }

    const areAllRecordingsInProject = () => {
        const recordings = props.recordingsInProject?.map((r) => r.sessionId)
        return (
            availableSampleRecordings?.filter((r) => recordings?.includes(r.sessionId)).length ===
            availableSampleRecordings?.length
        )
    }

    const handleImportRecordings = async () => {
        if (selectedRecordings.length === 0) {
            // Should not happen
            console.log('No recordings selected')
        }
        const recordings = availableSampleRecordings?.filter((r) => selectedRecordings.includes(r.sessionId)) ?? []
        const inProgress: Array<RecordingSessionInProgress> = recordings.map((s) => ({
            recordingSession: s,
            state: ImportState.LOADING,
        }))

        setImportState(ImportState.LOADING)

        const done = (r: RecordingSessionInProgress) => {
            props.onRecordingImported()
            r.state = ImportState.DONE
            setRecordingsToImport([...inProgress!])
        }

        const error = (r: RecordingSessionInProgress, e: any) => {
            r.state = ImportState.ERROR
            r.errorText = e.message
            setRecordingsToImport([...inProgress!])
        }

        setRecordingsToImport(inProgress)

        const promises = inProgress!.map((r) =>
            CloudApi.copySampleRecordingSession(props.currentProject!.uid, r.recordingSession.sessionId)
                .then(() => done(r))
                .catch((e: any) => error(r, e))
        )
        //const results = await Promise.all(promises)
    }

    useEffect(() => {
        const fetchRecordings = async () => {
            try {
                setAvailableSampleRecordings((await CloudApi.listSampleRecordingSessions()).data)
                setImportState(ImportState.NOT_STARTED)
            } catch (e: any) {
                setImportState(ImportState.ERROR)
            }
        }

        fetchRecordings()
    }, [])

    useEffect(() => {
        if (!importInProgress()) {
            close(true)
        }
    }, [recordingsToImport])

    const doClose = (imported: boolean) => {
        props.onModalClose(false)
    }

    const close = (imported: boolean) => {
        setSelectedRecordings([])
        setRecordingsToImport(undefined)
        setErrorText(undefined)
        setImportState(ImportState.NOT_STARTED)
    }

    const getIndicator = (recordingInProgress: RecordingSessionInProgress) => {
        switch (recordingInProgress.state) {
            case ImportState.LOADING:
                return (
                    <div key={recordingInProgress.recordingSession.sessionId} className={'d-flex align-items-center'}>
                        <Spinner className={`remotive-primary-50-color`} size="sm" />
                        <p className={'p-0 m-0'}>
                            &nbsp;Importing {recordingInProgress.recordingSession.displayName}...
                        </p>
                    </div>
                )
            case ImportState.ERROR:
                return (
                    <ErrorContainer
                        key={recordingInProgress.recordingSession.sessionId}
                        errorText={`Failed to import ${recordingInProgress.recordingSession.displayName}`}
                        errorSubText={recordingInProgress.errorText}
                    />
                )
            case ImportState.DONE:
                return (
                    <p key={recordingInProgress.recordingSession.sessionId}>
                        {recordingInProgress.recordingSession.displayName} successfully imported
                    </p>
                )
        }
    }
    const getBody = () => {
        return (
            <>
                <Modal.Header
                    className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                    closeButton
                    closeVariant="white"
                >
                    <Modal.Title>Import sample recordings</Modal.Title>
                </Modal.Header>
                <Modal.Body className="lexend-regular">
                    <div className="h-100 mt-3 mb-3">
                        <Form>
                            <div key={`remotive-recording`} className="mb-3">
                                <p className={'border-bottom '}>
                                    Select the sample recordings that you want to import into your project
                                </p>
                                {importState === ImportState.NOT_STARTED &&
                                    availableSampleRecordings?.map((r) => (
                                        <Form.Check // prettier-ignore
                                            disabled={isRecordingAlreadyInProject(r)}
                                            type="switch"
                                            id={r.sessionId}
                                            key={r.sessionId}
                                            value={r.sessionId}
                                            label={`${r.displayName} ${
                                                isRecordingAlreadyInProject(r) ? '(Imported)' : ''
                                            }`}
                                            onChange={(e) =>
                                                onRecordingSelectionChanged(e.target.value, e.target.checked)
                                            }
                                        />
                                    ))}
                                {importState === ImportState.LOADING && recordingsToImport?.map((r) => getIndicator(r))}
                            </div>
                        </Form>
                    </div>
                    {importState === ImportState.ERROR && (
                        <ErrorContainer errorText="Could not copy recording" errorSubText={errorText} />
                    )}
                    {areAllRecordingsInProject() && <p>All sample recordings has been imported</p>}
                </Modal.Body>
                <Modal.Footer>
                    <div className={'d-flex flex-column'}>
                        {importState === ImportState.NOT_STARTED && (
                            <>
                                <div className={'text-end'}>
                                    <button
                                        className="btn remotive-btn remotive-btn-primary flex-grow-0"
                                        onClick={handleImportRecordings}
                                        disabled={selectedRecordings.length === 0}
                                    >
                                        Import
                                    </button>
                                </div>
                                <>
                                    {selectedRecordings.length === 0 && !areAllRecordingsInProject() && (
                                        <div className="d-flex flex-row justify-content-center align-items-center mb-2">
                                            <WarningIcon className="text-warning" sx={{ fontSize: 15 }} />
                                            <p className="text-secondary remotive-font-xs m-0 ms-1">
                                                No recordings selected
                                            </p>
                                        </div>
                                    )}
                                </>
                            </>
                        )}
                        {importState !== ImportState.NOT_STARTED && (
                            <button
                                className="btn remotive-btn remotive-btn-primary flex-shrink-1"
                                onClick={() => close(true)}
                                disabled={importInProgress()}
                            >
                                Close
                            </button>
                        )}
                    </div>
                </Modal.Footer>
            </>
        )
        // case ImportState.LOADING:
        //     return <LoadingContainer loadingText={'Importing recordings...'} infoText={'This may take a while'} />
    }

    return (
        <>
            <Modal show={props.show} onHide={() => doClose(true)}>
                {getBody()}
            </Modal>
        </>
    )
}
