import { useState } from 'react'
import { HelpIcon } from '../../assets/Icons'
import BookMeetingModal from '../modals/BookMeetingModal'
import { isDemo } from '../../utils/demo'

interface DemoHelpCardProps {
    title?: JSX.Element
    bodyContent?: JSX.Element
}

export default function DemoHelpCard(props: DemoHelpCardProps) {
    const [showBookMeetingModal, setShowBookMeetingModal] = useState(false)

    return (
        <>
            <div className="d-flex flex-column p-3 rounded remotive-primary-60-background text-light mb-4">
                <div className="mb-1 d-flex align-items-center">
                    <HelpIcon sx={{ fontSize: 40 }} className="me-3 remotive-success-50-color" />
                    {props.title}
                </div>
                <div className="d-flex flex-column flex-sm-row justify-content-between align-items-center">
                    <div>{props.bodyContent}</div>
                    <div className="d-flex flex-shrink-0">
                        {isDemo() && (
                            <button
                                className="btn remotive-btn-success remotive-btn-sm"
                                onClick={() => setShowBookMeetingModal(true)}
                            >
                                <b>Book a personal demo</b>
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <BookMeetingModal show={showBookMeetingModal} handleCloseFunction={() => setShowBookMeetingModal(false)} />
        </>
    )
}
