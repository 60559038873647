import { useEffect } from 'react'
import { useState } from 'react'
import { Modal, Form, InputGroup, Spinner, Row } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import { UserBillableUnitInfo, UserInfo, UserInfoWithRoles } from '../../api/CloudApi/types'
import { ErrorIcon } from '../../assets/Icons'
import { toast } from 'react-toastify'
import { object, string } from 'yup'
import { Formik } from 'formik'

interface CreateBuUserModalProps {
    currentBu: UserBillableUnitInfo | undefined
    show: boolean
    handleCloseFunction: () => void
    reloadUsersList: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

enum Role {
    ADMINISTRATOR = 'org/administrator',
    BILLING_VIEWER = 'org/billingViewer', // This roles is not used at the moment
    LICENSE_VIEWER = 'org/licenseViewer',
    PROJECT_EDITOR = 'org/projectEditor',
    MEMBER = 'org/member',
}

enum RequestState {
    NOT_STARTED,
    IN_PROGRESS,
    DONE,
    ERROR,
}

export default function CreateBuUserModal(props: CreateBuUserModalProps) {
    const [selectedEmail, setSelectedEmail] = useState<string>()
    const [selectedName, setSelectedName] = useState<string>()
    const [selectedRoles, setSelectedRoles] = useState<Array<Role>>([])

    const [requestState, setRequestState] = useState<RequestState>(RequestState.NOT_STARTED)

    useEffect(() => {
        console.debug('Mounted create organisation user modal!')
    }, [])

    useEffect(() => {
        if (requestState === RequestState.DONE) {
            closeModal()
            props.reloadUsersList()
        }
    }, [requestState])

    const validateInput = (): boolean => {
        if (selectedName === undefined) {
            alert('You must select a user')
            return false
        }
        if (selectedEmail === undefined) {
            alert('You must provide at least a broker name to create a new broker')
            return false
        }
        return true
    }

    const resetState = () => {
        setSelectedEmail(undefined)
        setSelectedName(undefined)
        setSelectedRoles([])
        setRequestState(RequestState.NOT_STARTED)
    }

    const toggleRole = (role: Role) => {
        if (selectedRoles.includes(role)) {
            setSelectedRoles([...selectedRoles.filter((storedRole) => storedRole !== role)])
        } else {
            setSelectedRoles([...selectedRoles, role])
        }
    }

    const createUser = async (selectedEmail: string, selectedName: string) => {
        const buUid = props.currentBu?.organisation.uid
        if (buUid) {
            setRequestState(RequestState.IN_PROGRESS)
            const user = { displayName: selectedName, email: selectedEmail } as UserInfo // uid is not needed for new users, it will be generated in backend
            const userInfo = { user, roles: [...selectedRoles, Role.MEMBER] } as UserInfoWithRoles
            try {
                await CloudApi.createBuUser(buUid, userInfo)
                setRequestState(RequestState.DONE)
            } catch (err) {
                setRequestState(RequestState.ERROR)
                toast.error('Failed to create user')
            }
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    const rolesList = () => {
        return (
            <>
                <div>
                    <Form.Check
                        type="checkbox"
                        checked={selectedRoles.includes(Role.ADMINISTRATOR)}
                        className="remotive-font-md"
                        label="Administrator"
                        onChange={() => toggleRole(Role.ADMINISTRATOR)}
                        style={{ marginBottom: '-4px' }}
                    />
                    <p className="remotive-font-sm text-secondary mb-2">
                        Full access. Can manage users, licenses and projects for this organisation.
                    </p>
                </div>
                <div>
                    <Form.Check
                        type="checkbox"
                        className="remotive-font-md"
                        checked={selectedRoles.includes(Role.LICENSE_VIEWER)}
                        label="License Viewer"
                        onChange={() => toggleRole(Role.LICENSE_VIEWER)}
                        style={{ marginBottom: '-4px' }}
                    />
                    <p className="remotive-font-sm text-secondary mb-2">Can see licenses for this organisation</p>
                </div>
                <div>
                    <Form.Check
                        type="checkbox"
                        className="remotive-font-md"
                        checked={selectedRoles.includes(Role.PROJECT_EDITOR)}
                        label="Project Admin"
                        onChange={() => toggleRole(Role.PROJECT_EDITOR)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">
                        Can create projects. The user that creates a project will automatically have full access rights.
                        The access right to manage (edit / delete) projects can be restricted per project
                    </p>
                </div>
                <div>
                    <Form.Check
                        type="checkbox"
                        className="remotive-font-md"
                        checked={true}
                        disabled
                        label="Member (default for all users)"
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">
                        All user can be invited to projects. To give more access rights, please choose additional roles.
                    </p>
                </div>
            </>
        )
    }

    const getModalContent = () => {
        const schema = object().shape({
            name: string().required().trim(),

            email: string().email().required().trim(),
        })

        const title = 'Add user to organisation'
        switch (requestState) {
            case RequestState.IN_PROGRESS:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Adding user...</p>
                                <Spinner className="remotive-primary-50-color" size="sm" />
                            </div>
                        </Modal.Body>
                    </>
                )

            case RequestState.ERROR:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-3 mt-3 text-center">
                                <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                                <p className="fs-5 m-0">There was an issue adding the user...</p>
                                <p className="remotive-font-sm text-secondary" style={{ marginTop: '-5px' }}>
                                    Please try again later
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <Formik
                                validationSchema={schema}
                                isInitialValid={false}
                                onSubmit={(event: any) => {
                                    createUser(event.email.trim(), event.name.trim())
                                }}
                                initialValues={{
                                    name: '',
                                    email: '',
                                }}
                            >
                                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                                    <>
                                        <Form noValidate onSubmit={handleSubmit}>
                                            <Row className={'mb-3'}>
                                                <Form.Group as={Row} controlId={'nameValidationId'}>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Text
                                                            className={`remotive-font-md bg-transparent border-0 w-25`}
                                                        >
                                                            Name
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            className="remotive-font-sm w-75"
                                                            type="text"
                                                            placeholder="E.g John Doe"
                                                            name={'name'}
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.name}
                                                            isInvalid={errors.name !== undefined}
                                                        />
                                                        <div className="w-25"></div>
                                                        <Form.Control.Feedback className={'w-75'} type="invalid">
                                                            <>{errors.name}</>
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                            <Row className={'mb-3'}>
                                                <Form.Group as={Row} controlId={'emailValidationId'}>
                                                    <InputGroup size="sm">
                                                        <InputGroup.Text
                                                            className={`remotive-font-md bg-transparent border-0 w-25`}
                                                        >
                                                            Email
                                                        </InputGroup.Text>
                                                        <Form.Control
                                                            className="remotive-font-sm w-75"
                                                            type="text"
                                                            placeholder="E.g john.doe@company.com"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            name={'email'}
                                                            value={values.email}
                                                            isInvalid={errors.email !== undefined}
                                                        />
                                                        <div className="w-25"></div>
                                                        <Form.Control.Feedback className={'w-75'} type="invalid">
                                                            <>{errors.email}</>
                                                        </Form.Control.Feedback>
                                                    </InputGroup>
                                                </Form.Group>
                                            </Row>
                                            <div className="mb-2">
                                                <InputGroup size="sm">
                                                    <InputGroup.Text
                                                        className={`remotive-font-md bg-transparent border-0 w-25`}
                                                    >
                                                        <p className="h-100">Role</p>
                                                    </InputGroup.Text>
                                                    <div className="w-75">{rolesList()}</div>
                                                </InputGroup>
                                            </div>
                                            <div className="d-flex justify-content-end">
                                                <button
                                                    disabled={!isValid || (!touched.name && !touched.email)}
                                                    className="btn remotive-btn remotive-btn-success align-center"
                                                    type={'submit'}
                                                >
                                                    <div className="d-flex align-items-center text-light">
                                                        <p className="text-light m-0">Save</p>
                                                    </div>
                                                </button>
                                            </div>
                                        </Form>
                                    </>
                                )}
                            </Formik>
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal bsPrefix="" show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
