import IframeResizer from 'iframe-resizer-react'
import { ExternalLinkIcon, FullscreenIcon, MinimizeFullscreenIcon, ReloadIcon } from '../assets/Icons'
import { BrokerContainer } from '../api/CloudApi/types'
import CloudApi from '../api/CloudApi'
import { isDemo } from '../utils/demo'
import { useEffect, useRef, useState } from 'react'
import { Fullscreen } from '@mui/icons-material'

interface BrokerAppWrapperProps {
    brokerContainer: BrokerContainer
}

const FULLSCREEN_STYLE = {
    paddingTop: 5,
    minHeight: '100%',
    minWidth: '100%',
    zIndex: 9999,
    top: 0,
    left: 0,
}

const CACHE_BUSTER_PARAMETER = Date.now()
const BROKER_APP_IFRAME_TOOLBAR_HEIGHT = 70

export default function BrokerAppWrapper(props: BrokerAppWrapperProps) {
    const BROKER_APP_IFRAME_START_Y_PIXEL =
        document.getElementById('prepare-for-playback-button')?.getBoundingClientRect().y ?? 273

    const [isFullScreen, setIsFullScreen] = useState<boolean>(false)

    const createBrokerAppBaseUrl = (isIframe: boolean) => {
        return CloudApi.getBrokerAppUrl(props.brokerContainer, isDemo(), undefined, isIframe)
    }

    const [brokerAppUrl, setBrokerAppUrl] = useState<string>(
        `${createBrokerAppBaseUrl(true)}&cache_buster=${CACHE_BUSTER_PARAMETER}`
    )

    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                setIsFullScreen(false)
            }
        }
        window.addEventListener('keydown', handleEsc)

        return () => {
            window.removeEventListener('keydown', handleEsc)
        }
    }, [])

    const goToUrlButton = () => {
        return (
            <>
                <div className="d-flex justify-content-center align-items-center flex-column me-2">
                    <a className="m-0 p-0" href={createBrokerAppBaseUrl(false)} target={'_blank'}>
                        <button className="btn remotive-btn-md px-2 remotive-btn-primary">
                            <div className="d-flex align-items-center">
                                <ExternalLinkIcon sx={{ fontSize: 13 }} />
                            </div>
                        </button>
                    </a>
                    <p className="d-none d-sm-block m-0 mt-1 remotive-font-xs">New tab</p>
                </div>
            </>
        )
    }

    const resizeButton = () => {
        return (
            <div style={{ marginTop: 1 }} className="d-flex justify-content-center align-items-center flex-column me-2">
                {isFullScreen ? (
                    <>
                        <button
                            onClick={() => setIsFullScreen(false)}
                            className="btn remotive-btn-md px-2 remotive-btn-primary"
                        >
                            <div className="d-flex align-items-center">
                                <MinimizeFullscreenIcon sx={{ fontSize: 13 }} />
                            </div>
                        </button>
                        <p className="d-none d-sm-block m-0 mt-1 remotive-font-xs">Minimize</p>
                    </>
                ) : (
                    <>
                        <button
                            onClick={() => setIsFullScreen(true)}
                            className="btn remotive-btn-md px-2 remotive-btn-primary"
                        >
                            <div className="d-flex align-items-center">
                                <FullscreenIcon sx={{ fontSize: 13 }} />
                            </div>
                        </button>
                        <p className="d-none d-sm-block m-0 mt-1 remotive-font-xs">Fullscreen</p>
                    </>
                )}
            </div>
        )
    }

    const reloadButton = () => {
        return (
            <>
                <div
                    style={{ marginTop: 1 }}
                    className="d-flex justify-content-center align-items-center flex-column me-2"
                >
                    <button
                        onClick={() => setBrokerAppUrl(`${createBrokerAppBaseUrl(true)}&cache_buster=${Date.now()}`)}
                        className="btn remotive-btn-md px-2 remotive-btn-primary"
                    >
                        <div className="d-flex align-items-center">
                            <ReloadIcon sx={{ fontSize: 13 }} />
                        </div>
                    </button>
                    <p className="d-none d-sm-block m-0 mt-1 remotive-font-xs">Reload</p>
                </div>
            </>
        )
    }

    return (
        <div
            className="d-flex flex-column remotive-main-background"
            style={isFullScreen ? { ...FULLSCREEN_STYLE, position: 'absolute' } : { marginTop: -30 }}
        >
            <div className="d-flex flex-row justify-content-start ms-2 pb-2 border-2 border-bottom">
                <div>{goToUrlButton()}</div>
                <div className="d-none d-sm-block">{resizeButton()}</div>
                <div>{reloadButton()}</div>
            </div>
            <IframeResizer
                onLoad={() => {
                    if (props.brokerContainer !== undefined) {
                        console.log(`Scrolling to ${BROKER_APP_IFRAME_START_Y_PIXEL}`)
                        window.scrollTo({ top: BROKER_APP_IFRAME_START_Y_PIXEL, behavior: 'smooth' })
                    }
                }}
                allow="clipboard-write"
                checkOrigin={true}
                src={brokerAppUrl}
                width="100%"
                maxHeight={window.innerHeight - BROKER_APP_IFRAME_TOOLBAR_HEIGHT}
                scrolling={true}
                minHeight={window.innerHeight - BROKER_APP_IFRAME_TOOLBAR_HEIGHT}
            />
        </div>
    )
}
