import { useCallback, useEffect, useRef, useState } from 'react'
import { GoogleMap, useJsApiLoader, Polyline, Marker } from '@react-google-maps/api'
import { Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { formattedToastMessage } from '../../../../utils/toast'
import { LatLonCoordinate } from '../../../../types/LatLonCoordinate'

type GoogleWorldMapProps = {
    coordinates: Array<LatLonCoordinate>
}

const ZOOM_LEVEL = 14
const SVG_MARKER = 'M 37 12.5 c 0 6.63 -5.37 12 -12 12 s -12 -5.37 -12 -12 S 18.37 0.5 25 0.5 s 12 5.37 12 12 Z'

export default function GoogleWorldMap(props: GoogleWorldMapProps) {
    const mapRef = useRef<google.maps.Map | null>(null)
    const [errorText, setErrorText] = useState<string | undefined>()

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_KEY || 'N/A',
    })

    useEffect(() => {
        console.log(`Mounting Google Maps map, ${mapRef.current}`)
    }, [])

    useEffect(() => {
        console.log(`loaded=${isLoaded}`)
    }, [isLoaded])

    useEffect(() => {
        console.log(`coordinates length=${props.coordinates.length}`)
    }, [props.coordinates])

    useEffect(() => {
        if (isLoaded && props.coordinates.length > 0) {
            setStartingPointMarker()
            drawDrivePath()
            setEndPointMarker()
        }
    }, [isLoaded, props.coordinates])

    useEffect(() => {
        if (errorText !== undefined) {
            toast.error(
                formattedToastMessage(
                    errorText,
                    'Double check the settings in the map panel and make sure that the selected latitude and longitude signals correspond to the actual latitude and longitude values.'
                ),
                { autoClose: 20_000 }
            )
        }
    }, [errorText])

    const onLoad = useCallback((mapInstance: google.maps.Map) => {
        const bounds = new google.maps.LatLngBounds()
        mapRef.current = mapInstance
        mapInstance.fitBounds(bounds)
    }, [])

    useEffect(() => {
        if (
            !!isLoaded &&
            props.coordinates?.[0]?.latitude !== undefined &&
            props.coordinates?.[0]?.longitude !== undefined
        ) {
            zoomAndCenterMarker()
        }
    }, [isLoaded, props.coordinates])

    const drawDrivePath = () => {
        console.log('Drawing drive path')
        const drivePath = new google.maps.Polyline({
            strokeColor: '#ff2527',
            strokeOpacity: 1.0,
            strokeWeight: 5,
            geodesic: true,
            path: props.coordinates.map((it) => {
                return { lat: it.latitude, lng: it.longitude }
            }),
        })
        drivePath.setMap(mapRef.current)
    }

    const setStartingPointMarker = () => {
        console.log('Setting start marker')
        const markerViewWithText = new google.maps.Marker({
            position: { lat: props.coordinates[0].latitude, lng: props.coordinates[0].longitude },
            title: 'Start',
        })
        markerViewWithText.setMap(mapRef.current)
    }

    const setEndPointMarker = () => {
        console.log('Setting stop marker')
        const markerViewWithText = new google.maps.Marker({
            position: {
                lat: props.coordinates[props.coordinates.length - 1].latitude,
                lng: props.coordinates[props.coordinates.length - 1].longitude,
            },
            title: 'Stop',
        })
        markerViewWithText.setMap(mapRef.current)
    }

    const zoomAndCenterMarker = () => {
        if (isLoaded) {
            const typedMap = mapRef.current as google.maps.Map
            if (!!typedMap) {
                typedMap.setCenter(
                    new google.maps.LatLng(props.coordinates[0].latitude, props.coordinates[0].longitude)
                )
                typedMap.setZoom(ZOOM_LEVEL)
            }
        }
    }

    return (
        <>
            {isLoaded && (
                <>
                    <GoogleMap
                        options={{ streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
                        mapContainerClassName="w-100 h-100"
                        onLoad={onLoad}
                    />
                </>
            )}
            {!isLoaded && (
                <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center">
                    <Spinner />
                    <p>Loading...</p>
                </div>
            )}
        </>
    )
}
