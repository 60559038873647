import { UserLicense } from '../../api/CloudApi/types'

interface LicenseCountProps {
    userLicenses: Array<UserLicense> | []
}

export const LicenseCounter = (props: LicenseCountProps) => {
    let activeLicenses: number = 0
    let expiredLicenses: number = 0
    const licenseActivityByUser = props.userLicenses.map((license) => {
        let someLicenseActive = false
        license.licenses.forEach((license) => {
            if (Date.parse(license.expires) > Date.now()) {
                activeLicenses += 1
                someLicenseActive = true
            } else {
                expiredLicenses += 1
            }
        })
        return someLicenseActive
    })
    return (
        <div className="remotive-primary-70-background py-2 px-3 rounded text-light">
            <div className="d-flex">
                <div className="d-flex flex-column align-items-center">
                    <p className="mb-0 remotive-font-sm">Users</p>
                    <div className="d-flex flex-wrap justify-content-center">
                        <p className="mb-0 remotive-font-xs me-2">{`Active: ${
                            licenseActivityByUser.filter((someLicenseActive) => someLicenseActive).length
                        }`}</p>
                        <p className="mb-0 remotive-font-xs">{`Inactive: ${
                            licenseActivityByUser.filter((someLicenseActive) => !someLicenseActive).length
                        }`}</p>
                    </div>
                </div>
                <div className="border-end ms-3 me-3"></div>
                <div className="d-flex flex-column align-items-center">
                    <p className="mb-0 remotive-font-sm">Licenses</p>
                    <div className="d-flex flex-wrap justify-content-center">
                        <p className="mb-0 remotive-font-xs me-2">{`Valid: ${activeLicenses}`}</p>
                        <p className="mb-0 remotive-font-xs">{`Expired: ${expiredLicenses}`}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}
