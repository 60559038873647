import { useEffect } from 'react'

export default function useScript(
    url: string,
    asyncAndDefer: boolean | undefined,
    options: Map<string, string>,
    isActive: boolean = true
) {
    useEffect(() => {
        if (isActive) {
            console.log(`Adding script from ${url}`)
            const head = document.querySelector('head')
            const script = document.createElement('script')
            script.setAttribute('async', `${asyncAndDefer}`)
            script.setAttribute('defer', `${asyncAndDefer}`)
            script.setAttribute('src', url)
            options.forEach((value, key) => {
                script.setAttribute(key, value)
            })
            head?.appendChild(script)
            return () => {
                head?.removeChild(script)
            }
        } else {
            console.log(`Skipping script from ${url}, it is not supposed to be active`)
        }
    }, [url])
}
