import { Card, Container, Form, Modal, ProgressBar, Spinner } from 'react-bootstrap'
import { useEffect, useRef, useState } from 'react'
import {
    AuthenticatedUser,
    Project,
    RecordingFile,
    RecordingSession,
    SignalDatabase,
    UserBillableUnitInfo,
} from '../api/CloudApi/types'
import NavigationBar from '../components/navigation/NavigationBar'
import CloudApi from '../api/CloudApi'
import {
    ArrowEastIcon,
    ArrowNorthIcon,
    ArrowSouthIcon,
    ClickableArrowIcon,
    CloudUploadIcon,
    CopyIcon,
    DeleteIcon,
    ErrorIcon,
    RecordingsIcon,
    RefreshIcon,
    ImportExportIcon,
    UploadIcon,
    UnlockIcon,
    LockedIcon,
} from '../assets/Icons'
import { NavLink } from 'react-router-dom'
import LoadingContainer from '../components/LoadingContainer'
import ErrorContainer from '../components/ErrorContainer'
import NotFoundContaner from '../components/NotFoundContainer'
import { toast } from 'react-toastify'
import { AxiosProgressEvent, AxiosRequestConfig } from 'axios'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { formatAsDatetime } from '../utils/datetime'
import HelpOverlayModalModal from '../components/modals/HelpOverlayModal'
import HelpTextBox from '../components/cards/DemoHelpCard'
import { isDemo, isGuidedDemo } from '../utils/demo'
import { hasPermission, Permission } from '../utils/permission'
import CopyRecordingSessionModal from './CopyRecordingSessionModal'
import ImportRecordingSessionModal from './ImportRecordingSessionModal'
import { OrganisationRoutes, ProjectRoutes } from '../Routes'
import { BreadCrumb, BreadCrumbs } from '../types/BreadCrumbs'
import CliHintContainer from '../components/CliHintContainer'
import RecordingListItem from '../components/RecordingListItem'
import { PageDetails } from '../utils/pageDetails'

interface RecordingsListProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined

    currentProject: Project | undefined
    projects: Array<Project>
    currentUser: AuthenticatedUser | undefined
}

enum PageState {
    LOADING,
    DONE,
    ERROR,
    UPLOADING,
    DELETING,
    UPLOAD_ERROR,
    DELETE_ERROR,
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'
const DEMO_RECORDING_WHITELIST = ['13303517729834103000'] // Turning Torso drivecycle session id

export default function RecordingsList(props: RecordingsListProps) {
    const dropzone = useRef<DropzoneRef | null>(null)

    useEffect(() => {
        if (props.currentProject?.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Recordings - ${props.currentProject?.displayName}`,
                productAnalyticsEventName: 'Project Recordings',
            })
        }
    }, [props.currentProject])

    const [recordings, setRecordings] = useState<Array<RecordingSession>>()
    const [processingRecordings, setProcessingRecordings] = useState<Array<RecordingFile>>()
    const [pageState, setPageState] = useState<PageState>(PageState.LOADING)
    const [uploadPercent, setUploadPercent] = useState<number>(0)
    const [showHelpModal, setShowHelpModal] = useState(false)
    const [showDemoModal, setShowDemoModal] = useState(isGuidedDemo())
    const [showRecordingTypeModal, setShowRecordingTypeModal] = useState(false)
    const [showCopyRecordingModal, setShowCopyRecordingModal] = useState(false)
    const [showImportSampleRecordingModal, setShowImportSampleRecordingModal] = useState(false)
    const [fileToUpload, setFileToUpload] = useState<File>()
    const [recordingFileTypeToUpload, setRecordingFileTypeToUpload] = useState<'remotive-recording' | 'candump'>(
        'remotive-recording'
    )
    const [recordingSessionToCopy, setRecordingSessionToCopy] = useState<string>()
    const [recordingDbcFileToUse, setRecordingDbcFileToUse] = useState<string>()
    const [dbcFiles, setDbcFiles] = useState<Array<string>>([])

    const highlightObject = (isHighlightActive: boolean) => {
        return isHighlightActive ? { zIndex: 9999, position: 'relative' as 'relative' } : {}
    }

    useEffect(() => {
        console.debug('Mounted recordings list page!')
    }, [])

    useEffect(() => {
        if (props.currentProject) {
            setRecordingsState()
        }
    }, [props.currentProject])

    useEffect(() => {
        if (recordingSessionToCopy) {
            setShowCopyRecordingModal(true)
        }
    }, [recordingSessionToCopy])

    useEffect(() => {
        // Check if there are any processing recordings without errors and keep refreshing the list of processing recordings until there are no more processing recordings
        if (
            processingRecordings &&
            processingRecordings.filter((recording) => recording.metadata?.message === undefined).length > 0
        ) {
            setTimeout(fetchProcessingRecordingsSilently, 1000)
        }
    }, [processingRecordings])

    const fetchProcessingRecordingsSilently = async () => {
        const projectUid = props.currentProject?.uid
        if (projectUid) {
            const processingRecordings = await CloudApi.listProcessingRecordingFiles(projectUid)
            setProcessingRecordings(processingRecordings.data)
            // Wait 200 ms and fetch all sessions again
            await new Promise((r) => setTimeout(r, 500))
            const processedRecordings = await CloudApi.listRecordingSessions(projectUid)
            setRecordings(processedRecordings.data)
        }
    }

    const setRecordingsState = async () => {
        const projectUid = props.currentProject?.uid
        if (projectUid) {
            const listAvailableSignalDatabases = async () => {
                const signDbs = await CloudApi.listSignalDatabases(projectUid)
                return signDbs.data
                    .filter((db: SignalDatabase) => db.type === 'candb')
                    .map((db: SignalDatabase) => db.name)
            }

            try {
                setPageState(PageState.LOADING)
                const [recordingsResponse, processingRecordingsResponse, signalDatabases] = await Promise.all([
                    CloudApi.listRecordingSessions(projectUid),
                    CloudApi.listProcessingRecordingFiles(projectUid),
                    listAvailableSignalDatabases(),
                ])
                setRecordings(recordingsResponse.data)
                setProcessingRecordings(processingRecordingsResponse.data)
                setDbcFiles(signalDatabases)
                setPageState(PageState.DONE)
            } catch (err) {
                setPageState(PageState.ERROR)
            }
        }
    }

    const deleteProcessingRecording = async (recordingFile: RecordingFile) => {
        const projectUid = props.currentProject?.uid
        if (projectUid) {
            if (window.confirm('Are you sure you want to delete this processing recording? You cannot undo this!')) {
                setPageState(PageState.LOADING)
                try {
                    await CloudApi.deleteProcessingRecordingFile(projectUid, recordingFile)
                    setRecordingsState()
                } catch (err: any) {
                    toast.error('Failed to delete recording')
                    setPageState(PageState.DONE)
                }
            }
        }
    }

    const uploadRecording = () => {
        if (dropzone.current) {
            dropzone.current.open()
        }
    }

    const onRecordingModalClose = () => {
        setShowRecordingTypeModal(false)
        setRecordingFileTypeToUpload('remotive-recording')
        setRecordingDbcFileToUse(undefined)
    }

    const noDbcFileSelected = () => {
        return (
            recordingFileTypeToUpload === 'candump' &&
            (recordingDbcFileToUse === undefined || recordingDbcFileToUse === 'Select DBC File')
        )
    }

    const chooseRecordingTypeModal = () => {
        return (
            <>
                <Modal show={showRecordingTypeModal} onHide={onRecordingModalClose}>
                    <Modal.Header
                        className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        closeButton
                        closeVariant="white"
                    >
                        <Modal.Title>Choose recording type</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className="lexend-regular">
                        <div className="h-100 mt-3 mb-3">
                            <Form>
                                <div key={`remotive-recording`} className="mb-3">
                                    <p className={'m-1 p-1 border-bottom '}>{fileToUpload?.name}</p>
                                    <Form.Check // prettier-ignore
                                        className={'m-2 remotive-font-md remotive-primary-70-color'}
                                        checked={recordingFileTypeToUpload === 'remotive-recording'}
                                        type={'radio'}
                                        id={`remotive-recording`}
                                        label={`RemotiveBroker recording`}
                                        name={'recording-type'}
                                        value={'remotive-recording'}
                                        onChange={(e: any) => setRecordingFileTypeToUpload(e.target.value)}
                                    />
                                    <Form.Check // prettier-ignore
                                        checked={recordingFileTypeToUpload === 'candump'}
                                        className={'m-2 remotive-font-md remotive-primary-70-color'}
                                        type={'radio'}
                                        id={`candump`}
                                        label={`Candump log file`}
                                        name={'recording-type'}
                                        value={'candump'}
                                        onChange={(e: any) => setRecordingFileTypeToUpload(e.target.value)}
                                    />
                                    {recordingFileTypeToUpload === 'candump' && (
                                        <div className={'m-2 remotive-font-md remotive-primary-70-color'}>
                                            <Form.Control
                                                as={'select'}
                                                id={'asdf'}
                                                className={'remotive-font-md remotive-primary-70-color'}
                                                onChange={(e: any) => setRecordingDbcFileToUse(e.target.value)}
                                                contentEditable={true}
                                            >
                                                <option>Select DBC File</option>
                                                {dbcFiles.map((option) => (
                                                    <option key={option}>{option}</option>
                                                ))}
                                            </Form.Control>
                                            <Form.Text id="dbcHelp" muted>
                                                Name of the DBC file to use with the candump log. This DBC file must
                                                exist in order to playback the recording.
                                            </Form.Text>
                                        </div>
                                    )}
                                </div>
                            </Form>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className={'d-flex flex-column flex-grow-0'}>
                            <button
                                className="btn remotive-btn remotive-btn-primary flex-shrink-1"
                                disabled={noDbcFileSelected()}
                                onClick={handleRecordingFileUpload}
                            >
                                Upload
                            </button>
                            {noDbcFileSelected() && (
                                <p className={'remotive-warning-90-color remotive-font-sm m-0'}>* Select a DBC file</p>
                            )}
                        </div>
                    </Modal.Footer>
                </Modal>
            </>
        )
    }

    const uploadSamplesButton = () => {
        if (hasPermission(Permission.PROJECT_EDITOR_RECORDING, props.currentBillableUnit, props.currentProject)) {
            return (
                <button
                    className="btn remotive-btn remotive-btn-primary m-2 d-flex align-items-center"
                    onClick={() => setShowImportSampleRecordingModal(true)}
                >
                    <ImportExportIcon className="me-2" />
                    <p className="m-0">Import sample recordings</p>
                </button>
            )
        }
    }

    const recordingDropzone = () => {
        if (hasPermission(Permission.PROJECT_EDITOR_RECORDING, props.currentBillableUnit, props.currentProject)) {
            return (
                <>
                    {helpTextUploadRecordingDropzone()}
                    <div className="d-flex" style={{ height: '47px !important', ...highlightObject(showHelpModal) }}>
                        <Dropzone
                            ref={dropzone}
                            multiple={false}
                            onDrop={(acceptedFiles) => handleRecordingFileUploadWithModal(acceptedFiles[0])}
                        >
                            {({ getRootProps, getInputProps }) => (
                                <div
                                    className="col-12 dropzone p-1 remotive-primary-0-background rounded-2 w-100 "
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <p className="m-0 remotive-font-md remotive-primary-70-color text-center">
                                        <CloudUploadIcon className="me-2" sx={{ fontSize: 35 }} /> Drag a file here or
                                        click to <b>upload a recording</b>
                                    </p>
                                </div>
                            )}
                        </Dropzone>
                    </div>
                </>
            )
        }
        return <></>
    }

    const handleRecordingFileUploadWithModal = async (file: File) => {
        setFileToUpload(file)
        setShowRecordingTypeModal(true)
        if (file.name.endsWith('.log')) {
            setRecordingFileTypeToUpload('candump')
        }
    }

    const handleRecordingFileUpload = async () => {
        setShowRecordingTypeModal(false)
        const projectUid = props.currentProject?.uid
        const file = fileToUpload
        setUploadPercent(0)
        const config = {
            onUploadProgress: function (progressEvent: AxiosProgressEvent) {
                setUploadPercent(Math.round((progressEvent.loaded * 100) / (progressEvent.total || 1)))
            },
        } as AxiosRequestConfig<EventTarget>

        if (file && projectUid) {
            try {
                setPageState(PageState.UPLOADING)
                await CloudApi.uploadRecordingSession(projectUid, file, recordingDbcFileToUse, config)
                setRecordingsState()
            } catch (err) {
                toast.error('Failed to upload recording')
                setPageState(PageState.DONE)
            }
        }
    }

    const handleListSampleRecordings = async () => {
        try {
            const recordings = await CloudApi.listSampleRecordingSessions()
            recordings.data.forEach((r) => console.log(r))
        } catch (e: any) {
            console.log(e)
        }
    }

    const listProcessingRecordings = () => {
        if (processingRecordings !== undefined && processingRecordings.length > 0)
            return (
                <>
                    <div className="mb-5">
                        {processingRecordings.map((recording) => {
                            const error = recording.metadata?.error
                            if (error === true) {
                                const message =
                                    recording.type === 'CANDUMP_LOG_RECORDING'
                                        ? 'Something went wrong while processing candump, make sure it is an uncompressed candump log file'
                                        : 'Something went wrong while processing, make sure that this ' +
                                          'is a recording from a RemotiveBroker'
                                return (
                                    <div
                                        key={recording.sessionId || recording.displayName}
                                        className="rounded remotive-primary-10-background m-1 mx-0 px-1"
                                    >
                                        <div className="d-flex justify-content-between mb-1">
                                            <div className="d-flex justify-content-start align-items-center flex-truncate">
                                                <div className="mx-3">
                                                    <ErrorIcon className="text-danger" sx={{ fontSize: 23 }} />
                                                </div>
                                                <div className="p-1 flex-truncate">
                                                    <p className="remotive-font-md mb-0 text-truncate flex-truncate">
                                                        {recording.displayName}
                                                    </p>
                                                    <p className="remotive-font-sm text-danger mb-0 text-truncate">
                                                        <b>{message}</b>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="px-1 d-flex justify-content-end align-items-center">
                                                <button
                                                    className={'remotive-btn-no-bg p-1 px-0 border-0'}
                                                    disabled={
                                                        !hasPermission(
                                                            Permission.PROJECT_EDITOR_RECORDING,
                                                            props.currentBillableUnit,
                                                            props.currentProject
                                                        )
                                                    }
                                                    onClick={() => deleteProcessingRecording(recording)}
                                                >
                                                    <DeleteIcon sx={{ fontSize: 20 }} />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div
                                    key={recording.sessionId}
                                    className="rounded remotive-primary-10-background m-1 mx-0 px-1"
                                >
                                    <div className="d-flex justify-content-between mb-1">
                                        <div className="d-flex justify-content-start align-items-center flex-truncate">
                                            <div className="mx-3 px-1">
                                                <Spinner size="sm" />
                                            </div>
                                            <div className="p-1 flex-truncate">
                                                <p className="remotive-font-md mb-0 text-truncate flex-truncate">
                                                    {recording.displayName}
                                                </p>
                                                <p className="remotive-font-sm text-secondary mb-0 text-truncate  flex-truncate">
                                                    <b>Processing...</b>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="px-1 d-flex justify-content-end align-items-center">
                                            <button
                                                className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                                                disabled={
                                                    !hasPermission(
                                                        Permission.PROJECT_EDITOR_RECORDING,
                                                        props.currentBillableUnit,
                                                        props.currentProject
                                                    )
                                                }
                                                onClick={() => deleteProcessingRecording(recording)}
                                            >
                                                <DeleteIcon sx={{ fontSize: 20 }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </>
            )
        return <></>
    }

    const listFinishedRecordings = () => {
        if (recordings !== undefined && props.currentProject !== undefined && props.currentBillableUnit !== undefined) {
            return (
                <>
                    {helpTextRecordingDetails()}
                    {demoTextRecordingDetails()}
                    {recordings
                        .sort((a, b) =>
                            isDemo()
                                ? // Will put unlocked recordings on top
                                  DEMO_RECORDING_WHITELIST.includes(a.sessionId)
                                    ? -1
                                    : 0
                                : Date.parse(b.uploaded) - Date.parse(a.uploaded)
                        )
                        .map((recording, index) => {
                            const isLocked = isDemo() ? !DEMO_RECORDING_WHITELIST.includes(recording.sessionId) : false
                            return (
                                <div
                                    key={recording.sessionId}
                                    style={index === 0 ? { ...highlightObject(showHelpModal || showDemoModal) } : {}}
                                    className={`position-relative rounded remotive-primary-10-background m-1 mx-0`}
                                >
                                    {isLocked && (
                                        <div className="position-absolute w-100 h-100 rounded remotive-neutral-50-background d-flex align-items-center opacity-50" />
                                    )}
                                    <div className="px-1">
                                        <RecordingListItem
                                            recording={recording}
                                            isLocked={isLocked}
                                            project={props.currentProject!}
                                            billableUnit={props.currentBillableUnit!}
                                            pageStateFunction={setPageState}
                                            recordingStateFunction={setRecordingsState}
                                            recordingSessionToCopyFunction={setRecordingSessionToCopy}
                                        />
                                    </div>
                                </div>
                            )
                        })}
                </>
            )
        }
        return <></>
    }

    const recordingsListBody = () => {
        if (recordings === undefined) {
            return <LoadingContainer />
        }
        if (recordings.length > 0 || (processingRecordings && processingRecordings.length > 0)) {
            return (
                <>
                    <div>{listProcessingRecordings()}</div>
                    <div>{listFinishedRecordings()}</div>
                    {recordingDropzone()}
                </>
            )
        }
        return (
            <div className="text-center">
                <NotFoundContaner
                    infoText="We couldn't find any recordings..."
                    secondaryText="Ready to upload your latest drive?"
                />

                {recordingDropzone()}
            </div>
        )
    }

    const helpTextRecordingDetails = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: -90, marginLeft: 130 }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <p className="text-light text-start m-0">
                            Click here to play or to view <br /> details about this recording
                        </p>
                        <ArrowSouthIcon sx={{ fontSize: 30 }} className="mt-2" />
                    </div>
                </div>
            </>
        )
    }

    const demoTextRecordingDetails = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showDemoModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: -95, marginLeft: 130 }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <p className="text-light text-start m-0 fs-6">
                            1. Click here to view details about <br />
                            this recording or to play it on a broker
                        </p>
                        <ArrowSouthIcon sx={{ fontSize: 30 }} className="mt-2" />
                    </div>
                </div>
            </>
        )
    }

    const helpTextUploadRecordingButton = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginLeft: -200 }}
                >
                    <div className="d-flex">
                        <p className="text-light text-start m-0">
                            Click this button to <br />
                            upload a recording
                        </p>
                        <ArrowEastIcon sx={{ fontSize: 30 }} className="ms-3" />
                    </div>
                </div>
            </>
        )
    }

    const helpTextUploadRecordingDropzone = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex justify-content-center flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: 60, marginLeft: 100 }}
                >
                    <div className="d-flex flex-column  align-items-center">
                        <ArrowNorthIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0">
                            You can also click or drag a file <br />
                            here to upload a recording
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const upgradeToRealAccountMessage = () => {
        return (
            <>
                <Card
                    className="remotive-success-60-background rounded-4 border-0 text-white p-3"
                    style={{ maxWidth: 350 }}
                >
                    <div className="d-flex flex-row justify-content-center align-items-center">
                        <UnlockIcon sx={{ fontSize: 50 }} className="me-3" />
                        <div>
                            <p className="m-0 fs-6">
                                <b>Want more?</b>
                            </p>
                            <p className="m-0 remotive-font-md">
                                <a
                                    className="link-light link-offset-1 link-underline-opacity-75 link-underline-opacity-100-hover"
                                    href="https://console.cloud.remotivelabs.com"
                                >
                                    Sign up
                                </a>{' '}
                                for a free account to access all recordings and upload your own!
                            </p>
                        </div>
                    </div>
                </Card>
            </>
        )
    }

    const demoMessage = () => {
        return (
            <HelpTextBox
                title={
                    <div className="text-start">
                        <p className="remotive-font-md mb-1 fw-bold">Here are some of our recorded drivecycles</p>
                        <p className="remotive-font-md mb-1 ">
                            Simply click a recording to replay it or to see more information about it.
                        </p>
                    </div>
                }
                bodyContent={
                    <div className="mt-1">
                        <p className={`text-start remotive-font-md m-0`}>1. Click an available recording</p>
                    </div>
                }
            />
        )
    }

    const getBody = () => {
        switch (pageState) {
            case PageState.LOADING:
                return <LoadingContainer />

            case PageState.UPLOAD_ERROR:
                return <ErrorContainer errorText="We couldn't upload your recording..." />

            case PageState.DELETE_ERROR:
                return <ErrorContainer errorText="We couldn't delete your recording..." />

            case PageState.ERROR:
                return <ErrorContainer errorText="We couldn't fetch your recordings..." />

            case PageState.DONE:
                return (
                    <>
                        {isDemo() && demoMessage()}
                        {recordingsListBody()}
                        {isDemo() && (
                            <div className="d-flex justify-content-center mt-4">{upgradeToRealAccountMessage()}</div>
                        )}
                    </>
                )

            case PageState.UPLOADING:
                return (
                    <>
                        <div className="text-center h-100 mt-5 mb-5">
                            <div className="mb-3">
                                <p className={`fs-5 m-1 mb-0 `}>Uploading recording...</p>
                                <Spinner className={`remotive-primary-50-color`} size="sm" />
                            </div>
                            <ProgressBar
                                className={`col-12 col-lg-8 offset-lg-2 remotive-primary-0-color`}
                                label={`${uploadPercent}%`}
                                now={uploadPercent}
                            />
                        </div>
                    </>
                )

            case PageState.DELETING:
                return (
                    <>
                        <div className="text-center h-100 mt-5 mb-5">
                            <p className={`fs-5 m-1`}>Deleting recording...</p>
                            <Spinner className={`remotive-primary-50-color`} size="sm" />
                        </div>
                    </>
                )

            default:
                return <LoadingContainer />
        }
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between align-items-center text-truncate">
                    <div className="d-flex align-items-center text-truncate">
                        <RecordingsIcon className="remotive-primary-50-color fs-3 me-1" />
                        <p className="fs-3 mb-0 lexend-light text-truncate">Recordings</p>
                    </div>
                    <div>
                        {helpTextUploadRecordingButton()}
                        <button
                            style={highlightObject(showHelpModal)}
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => {
                                uploadRecording()
                            }}
                        >
                            <UploadIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        <button
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                            onClick={() => {
                                setRecordings(undefined)
                                setRecordingsState()
                            }}
                        >
                            <RefreshIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        &nbsp;
                        <button
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => {
                                setShowImportSampleRecordingModal(true)
                            }}
                        >
                            <ImportExportIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
            ],
            current: {
                title: 'Recordings',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                setDemoOverlayFunction={setShowDemoModal}
                isDemoGuideActive={showDemoModal}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <Card className="shadow-sm border-0 rounded-4 text-start mb-4" style={{ minHeight: '400px' }}>
                        <Card.Body className="pb-3 pt-3">{getBody()}</Card.Body>
                    </Card>
                </div>
                <CliHintContainer
                    hints={[
                        {
                            title: 'List recordings',
                            command: `remotive cloud recordings list --project ${props.currentProject?.uid}`,
                        },
                        {
                            title: 'List recordings but print only recording id and display name',
                            command: `remotive cloud recordings list --project ${props.currentProject?.uid} | jq -r '.[] | "\\(.sessionId) - \\(.displayName)"'`,
                        },
                        {
                            title: 'Upload recording from a Remotive Broker',
                            command: `remotive cloud recordings upload ./recording_file --project ${props.currentProject?.uid}`,
                        },
                        {
                            title: 'Upload a Candump log file',
                            subtitle:
                                'When uploading a raw candump log you must also specify the dbc file to use. This dbc file must also be uploaded under signal databases',
                            command: `remotive cloud recordings upload ./my-candump_file.log --signal-database my-dbcfile.dbc  --recording-type candump --project ${props.currentProject?.uid}`,
                        },
                    ]}
                />
            </Container>
            <HelpOverlayModalModal
                handleCloseFunction={() => {
                    setShowHelpModal(false)
                    setShowDemoModal(false)
                }}
                show={showHelpModal || showDemoModal}
            />
            {chooseRecordingTypeModal()}
            <CopyRecordingSessionModal
                onModalClose={() => {
                    setShowCopyRecordingModal(false)
                    setRecordingSessionToCopy(undefined)
                }}
                show={showCopyRecordingModal}
                currentProject={props.currentProject}
                recordingSession={recordingSessionToCopy}
                projects={props.projects}
            />
            <ImportRecordingSessionModal
                show={showImportSampleRecordingModal}
                onModalClose={(imported: boolean) => {
                    setShowImportSampleRecordingModal(false)
                    if (imported) {
                        setRecordingsState()
                    }
                }}
                currentProject={props.currentProject}
                recordingsInProject={recordings}
                onRecordingImported={() => setRecordingsState()}
            />
        </div>
    )
}
