import GoogleWorldMap from './GoogleWorldMap'
import { useEffect, useRef, useState } from 'react'
import { CheckRounded, CloseRounded, WarningRounded } from '@mui/icons-material'
import SetLatLonModal from './SetLatLonModal'
import { Project } from '../../../../api/CloudApi/types'
import CloudApi from '../../../../api/CloudApi'
import { toast } from 'react-toastify'
import { SignalOption } from '../../../../types/SignalOption'
import { LatLonCoordinate } from '../../../../types/LatLonCoordinate'
import { ANALYTICS_CHART_TYPE_KEY, AnalyticsPanelType, MapPanel, PanelKey } from '../Types'
import { PageState } from '../../../../types/PageState'
import LoadingContainer from '../../../../components/LoadingContainer'
import ErrorContainer from '../../../../components/ErrorContainer'

interface MapPanelCardProps {
    updatePanel: (panel: MapPanel) => void
    removeThisPanelFunction: () => void
    currentProject: Project | undefined
    recordingSessionId: string | undefined
    panelKey: PanelKey
    availableSignalNames: Array<SignalOption>
    latitudeSignal?: string
    longitudeSignal?: string
}

enum LatitudeLongitude {
    LAT = 'latitude',
    LON = 'longitude',
}

function MapContainer(props: MapPanelCardProps) {
    const [showSetLatLonModal, setShowSetLatLonModal] = useState<boolean>(false)
    const typeToSelect = useRef<LatitudeLongitude>(LatitudeLongitude.LAT)
    const [longitudeSignal, setLongitudeSignal] = useState<string | undefined>(props.longitudeSignal)
    const [latitudeSignal, setLatitudeSignal] = useState<string | undefined>(props.latitudeSignal)
    const [coordinates, setCoordinates] = useState<Array<LatLonCoordinate>>()
    const [pageState, setPageState] = useState(PageState.DONE)

    useEffect(() => {
        if (props.recordingSessionId && (latitudeSignal !== undefined || longitudeSignal !== undefined)) {
            const panelToStore = { panelKey: props.panelKey, latitudeSignal, longitudeSignal } as MapPanel
            props.updatePanel(panelToStore)
        }
    }, [latitudeSignal, longitudeSignal])

    useEffect(() => {
        if (latitudeSignal !== undefined && longitudeSignal !== undefined) {
            fetchAndSetCoordinates()
        }
    }, [latitudeSignal, longitudeSignal])

    const fetchAndSetCoordinates = () => {
        CloudApi.getSignalsTimeseries(props.currentProject!.uid, props.recordingSessionId!, [
            latitudeSignal!,
            longitudeSignal!,
        ])
            .then((res) => {
                console.log(`Got res ${res.data}`)
                setPageState(PageState.DONE)
                //setSignalList(res.data)
                if (res.data.signals.length === 0) {
                    toast.warning('No signals found matching your query')
                }
                const longitudeValues =
                    res.data.signals.find((it) => it.name === longitudeSignal)?.signals.map((it) => it[1]) ?? []
                console.log(`Longitude sigs ${longitudeValues}`)
                const latitudeValues =
                    res.data.signals.find((it) => it.name === latitudeSignal)?.signals.map((it) => it[1]) ?? []
                console.log(`Latitude sigs ${latitudeValues}`)
                const coordinates = []
                for (let index = 0; index < Math.min(latitudeValues?.length, longitudeValues?.length); index++) {
                    const latLonCoordinate = {
                        latitude: latitudeValues[index],
                        longitude: longitudeValues[index],
                    } as LatLonCoordinate
                    coordinates.push(latLonCoordinate)
                }
                console.log(
                    `Coordinates ${coordinates.map((it) => {
                        return `lat=${it.latitude} lng=${it.longitude}`
                    })}`
                )
                setCoordinates(coordinates)
            })
            .catch((e: any) => {
                console.log(`LatLon error: ${e}`)
            })
    }

    const setLatLonSignal = (type: LatitudeLongitude, signalOption: SignalOption) => {
        switch (type) {
            case LatitudeLongitude.LAT:
                return setLatitudeSignal(signalOption.value)

            case LatitudeLongitude.LON:
                return setLongitudeSignal(signalOption.value)
            default:
                console.warn(`Trying to set unsupported latitude/longitude type, type=${type}`)
                break
        }
    }

    const chartTypeAttribute = { [ANALYTICS_CHART_TYPE_KEY]: AnalyticsPanelType.MAP_WITH_ROUTE }

    return (
        <>
            <>
                <div className="d-flex justify-content-between px-2 pt-2 pb-1" {...chartTypeAttribute}>
                    <div>
                        <p className="remotive-font-md lh-sm remotive-primary-60-color m-0">Driving Route</p>
                        <p className="remotive-font-xs lh-1 remotive-primary-40-color text-secondary m-0">
                            Select latitude and longitude signals to plot the driving route
                        </p>
                    </div>
                    <div>
                        <button
                            onClick={() => props.removeThisPanelFunction()}
                            className="btn m-0 p-0 remotive-btn-no-bg remotive-btn-sm"
                        >
                            <div className="d-flex align-items-center" title="Close entire panel">
                                <CloseRounded sx={{ fontSize: 24 }} />
                            </div>
                        </button>
                    </div>
                </div>
                <div className="w-100 h-100 mb-2">
                    {pageState === PageState.LOADING && <LoadingContainer />}
                    {pageState === PageState.ERROR && (
                        <ErrorContainer
                            errorSubText={'We encountered a problem while querying signal data.'}
                            errorText={'Signal data error'}
                        />
                    )}
                    {latitudeSignal !== undefined && longitudeSignal !== undefined ? (
                        <GoogleWorldMap coordinates={coordinates ?? []} />
                    ) : (
                        <div className="d-flex flex-column align-items-center justify-content-center h-100">
                            <p className="m-0 remotive-font d-flex align-items-center">Set latitude and longitude</p>
                            <p className="m-0 remotive-font-sm text-secondary">
                                Both latitude and longitude must be set to visualize a map
                            </p>
                            <div className="mt-2">
                                <button
                                    className={`button remotive-btn-md remotive-btn-${
                                        longitudeSignal === undefined ? 'primary' : 'success'
                                    } border-0 me-2`}
                                    title="Set longitude"
                                    onClick={() => {
                                        typeToSelect.current = LatitudeLongitude.LON
                                        setShowSetLatLonModal(true)
                                    }}
                                >
                                    {longitudeSignal === undefined ? (
                                        <>Set longitude</>
                                    ) : (
                                        <>
                                            <CheckRounded sx={{ fontSize: 17 }} /> Longitude is set
                                        </>
                                    )}
                                </button>

                                <button
                                    className={`btn remotive-btn-md remotive-btn-${
                                        latitudeSignal === undefined ? 'primary' : 'success'
                                    } border-0 me-2`}
                                    title="Set latitude"
                                    onClick={() => {
                                        typeToSelect.current = LatitudeLongitude.LAT
                                        setShowSetLatLonModal(true)
                                    }}
                                >
                                    {latitudeSignal === undefined ? (
                                        <>Set latitude</>
                                    ) : (
                                        <>
                                            <CheckRounded sx={{ fontSize: 17 }} /> Latitude is set
                                        </>
                                    )}
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </>

            {/* Modals below */}
            <SetLatLonModal
                type={typeToSelect.current}
                show={showSetLatLonModal}
                handleCloseFunction={() => setShowSetLatLonModal(false)}
                availableSignalNames={props.availableSignalNames}
                setLatLonSignalFunction={setLatLonSignal}
            />
        </>
    )
}

export default MapContainer
