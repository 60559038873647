import { Accordion, Button, Card, Dropdown, Form, InputGroup, OverlayTrigger, Popover, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import { FrameEntry, Project, RecordingSession, UserBillableUnitInfo } from '../../../api/CloudApi/types'
import { useEffect, useState } from 'react'
import CloudApi from '../../../api/CloudApi'
import { AddIcon, CopyIcon, DropdownIcon, DropupIcon, JitterIcon, MapIcon, ShareIcon } from '../../../assets/Icons'
import TimeDeviationContainer from './TimeDeviationContainer'
import TimeSeriesContainer from './TimeSeriesContainer'
import LoadingContainer from '../../../components/LoadingContainer'
import { Permission, hasPermission } from '../../../utils/permission'
import { SignalOption } from '../../../types/SignalOption'
import { PageState } from '../../../types/PageState'
import {
    PanelKey,
    StoredDashboard,
    TimeDeviationPanel,
    MapPanel,
    TimeSeriesPanel,
    CompressedStoredDashboard,
} from './Types'
import { getStoredDashboard, storeDashboard } from './storageUtils'
import MapContainer from './MapContainer'
import { useSearchParams } from 'react-router-dom'
import { formattedToastMessage } from '../../../utils/toast'
import { DASHBOARD_PARAM } from '../../../utils/queryParams'
import { compressDashboard, decompressDashboard } from './compressUtil'

interface AnalyticsTabProps {
    currentRecording: RecordingSession | undefined
    currentProject: Project | undefined
    currentBillableUnit: UserBillableUnitInfo | undefined
}

const COPY_MESSAGE_RESET_TIMER = 2000
const BACKEND_MAX_URL_LENGTH = 4096

export function AnalyticsTab(props: AnalyticsTabProps) {
    const [pageState, setPageState] = useState(PageState.LOADING)
    const [availableSignalNames, setAvailableSignalNames] = useState<Array<SignalOption>>()
    const [availableFrameEntries, setAvailableFrameEntries] = useState<Array<FrameEntry>>()

    const [showPopover, setShowPopover] = useState<boolean>(false)
    const [isUrlCopied, setIsUrlCopied] = useState<boolean>(false)

    const [dashboard, setDashboard] = useState<StoredDashboard | undefined>(undefined)
    const [shareableDashboardLink, setShareableDashboardLink] = useState<string | undefined>(undefined)

    const [showDropdown, setShowDropdown] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const queryParamDashboard = searchParams.get(DASHBOARD_PARAM)
        if (
            props.currentRecording?.sessionId &&
            availableSignalNames &&
            availableSignalNames.length > 0 &&
            availableFrameEntries &&
            availableFrameEntries.length > 0
        ) {
            if (queryParamDashboard) {
                try {
                    const parsedButCompressedDashboard: CompressedStoredDashboard = JSON.parse(queryParamDashboard)
                    const decompressedDashboard = decompressDashboard(
                        parsedButCompressedDashboard,
                        availableFrameEntries
                    )
                    searchParams.delete(DASHBOARD_PARAM)
                    setSearchParams(searchParams)
                    setDashboard(decompressedDashboard)
                } catch (error: any) {
                    console.error(error)
                    toast.error(
                        formattedToastMessage(
                            `Dashboard error`,
                            `There was an issue when opening the shared dashboard.`
                        ),
                        { autoClose: 20_000 }
                    )
                }
            } else {
                const dashboard = getStoredDashboard(props.currentRecording.sessionId)
                if (dashboard !== undefined) {
                    setDashboard(dashboard)
                }
            }
        }
    }, [availableSignalNames, availableFrameEntries])

    useEffect(() => {
        if (dashboard !== undefined && props.currentRecording?.sessionId !== undefined) {
            storeDashboard(props.currentRecording.sessionId, dashboard)
            setShareableDashboardLink(
                `${window.location.toString()}&${DASHBOARD_PARAM}=${urlEncodedDashboard(dashboard)}`
            )
        }
    }, [dashboard])

    useEffect(() => {
        if (isUrlCopied) {
            const timer = setTimeout(() => setIsUrlCopied(false), COPY_MESSAGE_RESET_TIMER)
            return () => clearTimeout(timer)
        }
    }, [isUrlCopied])

    useEffect(() => {
        if (props.currentProject !== undefined && props.currentRecording?.sessionId !== undefined) {
            assignSignalNamesToSelect()
            getFrameEntries()
        }
    }, [props.currentProject, props.currentRecording?.sessionId])

    useEffect(() => {
        console.log(`Page state is now ${pageState.toString()}`)
        if (pageState == PageState.CRUNCHING) {
            const interval = setInterval(async () => {
                try {
                    const names = await CloudApi.listSignalNames(
                        props.currentProject!.uid,
                        props.currentRecording!.sessionId
                    )
                    if (names.data.signals.length > 0) {
                        if (names.data.signals[0].occurrences > 100) {
                            await assignSignalNamesToSelect(PageState.DONE)
                        }
                    }
                } catch (err: any) {
                    console.log(err)
                    if (err.response.status === 404) {
                        console.log('Still 404...')
                    } else {
                        setPageState(PageState.ERROR)
                        toast.error('Failed to load signal names')
                    }
                }
            }, 3000)
            return () => {
                console.log('Clear')
                clearInterval(interval)
            }
        }
    }, [pageState])

    const assignSignalNamesToSelect = async (onSuccess: PageState | undefined = undefined) => {
        try {
            const res = await getSignalNames()
            //.then((res) => {
            const s = res?.data.signals.map((entry) => {
                return { value: entry.name, label: `${entry.name}  (${entry.occurrences})` }
            })
            setAvailableSignalNames(s)
            if (onSuccess) {
                setPageState(onSuccess)
            }
            //  })
        } catch (e: any) {
            console.log(e)
            if (e.response.status === 404) {
                setPageState(PageState.NO_SIGNALS)
            } else {
                setPageState(PageState.ERROR)
            }
        }
    }

    const fetchFrameEntries = async () => {
        try {
            const frameEntries = await CloudApi.listFrameEntries(
                props.currentProject!.uid,
                props.currentRecording!.sessionId
            )
            setPageState(PageState.DONE)
            return frameEntries
        } catch (e: any) {
            console.log(e)
            if (e.response.status === 404) {
                setPageState(PageState.NO_SIGNALS)
            } else {
                setPageState(PageState.ERROR)
            }
        }
    }

    const getSignalNames = async () => {
        try {
            const names = await CloudApi.listSignalNames(props.currentProject!.uid, props.currentRecording!.sessionId)
            console.log(names)
            setPageState(PageState.DONE)
            return names
        } catch (e: any) {
            console.log(e)
            if (e.response.status === 404) {
                setPageState(PageState.NO_SIGNALS)
            } else {
                setPageState(PageState.ERROR)
                toast.error('Failed to load signal names')
            }
        }
    }

    const getFrameEntries = async (onSuccess: PageState | undefined = undefined) => {
        try {
            const res = await fetchFrameEntries()
            const frameEntries = (res?.data ?? []).map((entry) => {
                return {
                    ...entry,
                    signals: entry.signals.map((it) => {
                        return { ...it, frameName: entry.name }
                    }),
                }
            })
            setAvailableFrameEntries(frameEntries ?? [])
            if (onSuccess) {
                setPageState(onSuccess)
            }
        } catch (e: any) {
            console.log(e)
            if (e.response.status === 404) {
                setPageState(PageState.NO_SIGNALS)
            } else {
                setPageState(PageState.ERROR)
            }
        }
    }

    const addTimeDeviationPanel = () => {
        const panelKey = { key: `${Date.now()}` } as PanelKey
        console.log(`Adding TimeDeviatioPanel W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            timeDeviationPanels: [
                ...(dashboard?.timeDeviationPanels ?? []),
                { panelKey, selectedSignals: [] } as TimeDeviationPanel,
            ],
        } as StoredDashboard)
    }

    const removeTimeDeviationPanel = (panelKey: PanelKey) => {
        console.log(`Removing Jitter W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            timeDeviationPanels: (dashboard?.timeDeviationPanels ?? []).filter(
                (it) => it.panelKey.key !== panelKey.key
            ),
        } as StoredDashboard)
    }

    const addMapPanel = () => {
        const panelKey = { key: `${Date.now()}` } as PanelKey
        console.log(`Adding Map W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            mapPanels: [
                ...(dashboard?.mapPanels ?? []),
                { panelKey, latitudeSignal: undefined, longitudeSignal: undefined } as MapPanel,
            ],
        } as StoredDashboard)
    }

    const removeMapPanel = (panelKey: PanelKey) => {
        console.log(`Removing Map W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            mapPanels: (dashboard?.mapPanels ?? []).filter((it) => it.panelKey.key !== panelKey.key),
        } as StoredDashboard)
    }

    const addSignalTimeSeriesPanel = () => {
        const panelKey = { key: `${Date.now()}` } as PanelKey
        console.log(`Adding Signal Time Series W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            timeSeriesPanels: [
                ...(dashboard?.timeSeriesPanels ?? []),
                { panelKey, selectedSignals: [], hiddenSignals: [] } as TimeSeriesPanel,
            ],
        } as StoredDashboard)
    }

    const removeSignalTimeSeriesPanel = (panelKey: PanelKey) => {
        console.log(`Removing Signal Time Series W/ key=${panelKey}`)
        setDashboard({
            ...dashboard,
            timeSeriesPanels: (dashboard?.timeSeriesPanels ?? []).filter((it) => it.panelKey.key !== panelKey.key),
        } as StoredDashboard)
    }

    const addVisualizationDropdown = () => {
        return (
            <>
                <Dropdown className="" show={showDropdown}>
                    <Dropdown.Menu className="py-2 text-left remotive-dropdown-light border-0 shadow">
                        <Dropdown.Item
                            as={Button}
                            className="m-0 py-1 px-3 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                addTimeDeviationPanel()
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <JitterIcon sx={{ fontSize: 20 }} />
                                <p className="ms-2 m-0 remotive-font">Cycle time distribution</p>
                            </div>
                        </Dropdown.Item>
                        <Dropdown.Item
                            as={Button}
                            className="m-0 py-1 px-3 rounded-0 text-dark text-center remotive-font-md d-flex align-items-center justify-content-start"
                            // We can't use a regular onClick here because a full click event does not occur before onBlur() is called. OnBlur is fired from the button that opens/closes this dropdown
                            onMouseDownCapture={() => {
                                addMapPanel()
                            }}
                        >
                            <div className="d-flex align-items-center">
                                <MapIcon sx={{ fontSize: 17 }} />
                                <p className="ms-2 m-0 remotive-font">Map</p>
                            </div>
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </>
        )
    }

    const addVisualizationButton = () => {
        return (
            <div>
                <div className="me-0">
                    <button
                        disabled={pageState !== PageState.DONE}
                        style={{
                            borderRadius: '40px 0px 0px 40px',
                        }}
                        className="btn p-1 m-0 mt-1 remotive-btn-primary"
                        onClick={() => {
                            addSignalTimeSeriesPanel()
                        }}
                    >
                        <div className="d-flex p-0 justify-content-center align-items-center ps-2 pe-1">
                            <AddIcon sx={{ fontSize: 31 }} />
                            <p className="mx-2 m-0 remotive-font">Signal time series</p>
                        </div>
                    </button>
                    <button
                        disabled={pageState !== PageState.DONE}
                        style={{
                            borderRadius: '0px 40px 40px 0px',
                        }}
                        className="btn border-0 py-1 m-0 mt-1 ps-0 pe-1 remotive-btn-primary-dark border-start border-2"
                        onBlur={() => {
                            setShowDropdown(false)
                        }}
                        onClick={() => {
                            setShowDropdown(!showDropdown)
                        }}
                    >
                        {showDropdown ? <DropupIcon sx={{ fontSize: 35 }} /> : <DropdownIcon sx={{ fontSize: 35 }} />}
                    </button>
                </div>
            </div>
        )
    }

    const addNewVisualization = () => {
        return (
            <>
                <div className="my-5 d-flex flex-column justify-content-center align-items-center">
                    {addVisualizationButton()}
                    {addVisualizationDropdown()}
                </div>
            </>
        )
    }

    const renderMapPanels = () => {
        if (dashboard?.mapPanels) {
            return dashboard.mapPanels
                .sort((panelA, panelB) => panelA.panelKey.key.localeCompare(panelB.panelKey.key))
                .map((it) => {
                    return (
                        <div key={it.panelKey.key} className="col-12 col-xl-6 p-0">
                            <Card className="m-1 border-0 bg-white rounded-3 shadow-sm" style={{ height: 550 }}>
                                <MapContainer
                                    updatePanel={(panel) =>
                                        setDashboard({
                                            ...dashboard,
                                            mapPanels: dashboard.mapPanels
                                                .filter((panel) => panel.panelKey.key !== it.panelKey.key)
                                                .concat(panel)
                                                .sort(),
                                        })
                                    }
                                    availableSignalNames={availableSignalNames ?? []}
                                    latitudeSignal={it.latitudeSignal}
                                    longitudeSignal={it.longitudeSignal}
                                    panelKey={it.panelKey}
                                    removeThisPanelFunction={() => removeMapPanel(it.panelKey)}
                                    currentProject={props.currentProject}
                                    recordingSessionId={props.currentRecording?.sessionId}
                                />
                            </Card>
                        </div>
                    )
                })
        }
        return <></>
    }

    const renderJitterDetectionPanels = () => {
        if (dashboard?.timeDeviationPanels) {
            return dashboard?.timeDeviationPanels
                .sort((panelA, panelB) => panelA.panelKey.key.localeCompare(panelB.panelKey.key))
                .map((it) => (
                    <div key={it.panelKey.key} className="col-12 col-xl-6 p-0">
                        <Card className="m-1 border-0 bg-white rounded-3 shadow-sm" style={{ height: 550 }}>
                            <TimeDeviationContainer
                                availableFramesWithCycleTime={
                                    (availableFrameEntries ?? [])
                                        .map((it) => {
                                            if (it.cnt > 0 && it.cycleTime > 0) {
                                                return { value: it.name, label: it.name } as SignalOption
                                            }
                                            return undefined
                                        })
                                        .filter((it) => it !== undefined) as Array<SignalOption>
                                }
                                updatePanel={(panel) =>
                                    setDashboard({
                                        ...dashboard,
                                        timeDeviationPanels: dashboard.timeDeviationPanels
                                            .filter((panel) => panel.panelKey.key !== it.panelKey.key)
                                            .concat(panel),
                                    })
                                }
                                selectedSignals={it.selectedSignals}
                                removeThisPanelFunction={() => removeTimeDeviationPanel(it.panelKey)}
                                panelKey={it.panelKey}
                                currentProject={props.currentProject}
                                recordingSessionId={props.currentRecording?.sessionId}
                            />
                        </Card>
                    </div>
                ))
        }
        return <></>
    }

    const renderSignalTimeSeriesPanels = () => {
        if (dashboard?.timeSeriesPanels) {
            return dashboard?.timeSeriesPanels
                .sort((panelA, panelB) => panelA.panelKey.key.localeCompare(panelB.panelKey.key))
                .map((it) => (
                    <div key={it.panelKey.key} className="col-12 p-0 mb-2">
                        <Card className="m-1 h-100 border-0 bg-white rounded-3 shadow-sm" style={{ minHeight: 500 }}>
                            <TimeSeriesContainer
                                updatePanel={(panel) =>
                                    setDashboard({
                                        ...dashboard,
                                        timeSeriesPanels: dashboard.timeSeriesPanels
                                            .filter((panel) => panel.panelKey.key !== it.panelKey.key)
                                            .concat(panel),
                                    })
                                }
                                availableFrameEntries={availableFrameEntries ?? []}
                                selectedSignals={it.selectedSignals}
                                hiddenSignals={it.hiddenSignals}
                                removeThisPanelFunction={() => removeSignalTimeSeriesPanel(it.panelKey)}
                                panelKey={it.panelKey}
                                currentProject={props.currentProject}
                                recordingSessionId={props.currentRecording?.sessionId}
                                recordingSession={props.currentRecording}
                            />
                        </Card>
                    </div>
                ))
        }
        return <></>
    }

    const recordingContainsOnlyCANrecordings = () => {
        if (props.currentRecording) {
            const canRecordings = props.currentRecording.recordings.filter((r) =>
                r.metadata?.database?.endsWith('.dbc')
            )
            return canRecordings.length === props.currentRecording.recordings.length
        }
        return false
    }

    const prepareForAnalyticsHero = (text: string, isButtonDisabled: boolean) => {
        return (
            <div className="d-flex flex-column justify-content-center align-items-center my-5">
                <div style={{ height: 160, width: 60 }}>
                    <img
                        style={{ marginTop: 10 }}
                        width={'auto'}
                        height={80}
                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_ANALYZE.png'}
                    ></img>
                    <img
                        style={{ marginTop: -50, marginLeft: -80 }}
                        width={'auto'}
                        height={80}
                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_BROKER_APP.png'}
                    ></img>
                </div>
                <p className={`remotive-font-xl mb-0`}>{text}</p>
                <p className={`remotive-font-sm text-secondary mb-0`} style={{ maxWidth: 540 }}>
                    A recording has to be prepared before any signals can be visualized, this is only done once per
                    recording. The corresponding signal database file must have been previously uploaded.
                </p>
                <button
                    className="btn remotive-btn remotive-btn-success mt-4"
                    disabled={isButtonDisabled}
                    onClick={() => {
                        setPageState(PageState.CRUNCHING)
                        CloudApi.requestCrunchRecording(props.currentProject!, props.currentRecording!.sessionId)
                            .then((e) => console.log(e))
                            .catch((e) => console.log(e))
                    }}
                >
                    Prepare recording for analytics
                </button>
            </div>
        )
    }

    const copyBrokerUrl = () => {
        if (shareableDashboardLink !== undefined) {
            navigator.clipboard.writeText(shareableDashboardLink)
            setIsUrlCopied(true)
        }
    }

    const getCopyIcon = (isCopied: boolean) => {
        if (isCopied) {
            return 'Copied!'
        }
        return <CopyIcon sx={{ fontSize: 17 }} />
    }

    const urlEncodedDashboard = (dashboard: StoredDashboard) => {
        return encodeURIComponent(JSON.stringify(compressDashboard(dashboard)))
    }

    const prepareForAnalytics = () => {
        return (
            <>
                <div className="mx-0 d-flex align-items-center flex-fill flex-truncate">
                    <div className="my-3 w-100">
                        <div className="text-center h-25">
                            {props.currentProject &&
                                recordingContainsOnlyCANrecordings() &&
                                hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.currentBillableUnit,
                                    props.currentProject
                                ) && (
                                    <>
                                        {prepareForAnalyticsHero(
                                            'This recording has not yet been prepared for analytics',
                                            false
                                        )}
                                    </>
                                )}
                            {props.currentProject &&
                                recordingContainsOnlyCANrecordings() &&
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_RECORDING,
                                    props.currentBillableUnit,
                                    props.currentProject
                                ) && (
                                    <>
                                        {prepareForAnalyticsHero(
                                            'This recording has not yet been prepared, but you need to be project editor do this.',
                                            true
                                        )}
                                    </>
                                )}
                            {props.currentProject && !recordingContainsOnlyCANrecordings() && (
                                <h5>Currently only CAN recordings are available for analytics and debugging.</h5>
                            )}
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const optionsCard = () => {
        return (
            <div className="mx-2 p-1 py-2 w-100 text-truncate">
                <div className="d-flex justify-content-between align-items-center w-100 text-truncate">
                    <div className="m-1 text-truncate">
                        <div className="m-0 p-0 text-truncate">
                            <b className="me-2">
                                {props.currentRecording?.displayName !== undefined ? (
                                    'Options'
                                ) : (
                                    <span className="p-0 m-0">
                                        <Spinner className="me-2" size="sm" />
                                        Loading...
                                    </span>
                                )}
                            </b>
                        </div>
                        <p className="remotive-font-md text-secondary m-0 p-0 text-truncate">
                            All signals are decoded with the default configuration
                        </p>
                    </div>
                    <div className="me-3">
                        {shareableDashboardLink !== undefined && (
                            <button className="btn remotive-btn-primary remotive-btn-md border-0">
                                <OverlayTrigger
                                    trigger="click"
                                    rootClose
                                    show={showPopover}
                                    onToggle={(newState: boolean) => {
                                        if (shareableDashboardLink.length >= BACKEND_MAX_URL_LENGTH) {
                                            toast.error(
                                                formattedToastMessage(
                                                    'Dashboard error',
                                                    'The current dashboard is too large to be able to share via a url. Please remove some visualizations and try again.'
                                                ),
                                                { autoClose: 15_000 }
                                            )
                                        } else {
                                            setShowPopover(newState)
                                        }
                                    }}
                                    placement="top"
                                    overlay={popover}
                                >
                                    <div className="d-flex align-items-center">
                                        <ShareIcon sx={{ fontSize: 20 }} />
                                        <p className="m-0 mx-2 d-none d-lg-block">Share</p>
                                    </div>
                                </OverlayTrigger>
                            </button>
                        )}
                    </div>
                </div>
            </div>
        )
    }

    const popover = (
        <Popover id="popover-basic" className="border-0 remotive-primary-5-background shadow" style={{ minWidth: 350 }}>
            <Popover.Body className="pb-2" style={{ minWidth: 350 }}>
                <div className="mb-3 text-center">
                    <div className="d-flex justify-content-center remotive-font-md flex-column">
                        <>
                            <p className="text-start m-0 lexend-regular">Shareable link</p>
                            <InputGroup size="sm" className="mb-1">
                                <Form.Control
                                    className="bg-white remotive-primary-40-border"
                                    value={shareableDashboardLink !== undefined ? shareableDashboardLink : 'N/A'}
                                    disabled={true}
                                />
                                <Button className="remotive-btn-primary" onClick={() => copyBrokerUrl()}>
                                    {getCopyIcon(isUrlCopied)}
                                </Button>
                            </InputGroup>
                        </>
                    </div>
                </div>
            </Popover.Body>
        </Popover>
    )

    const optionsOrPrepareForAnalytics = () => {
        switch (pageState) {
            case PageState.LOADING:
                return (
                    <div style={{ minHeight: 144 }}>
                        <LoadingContainer />
                    </div>
                )

            case PageState.CRUNCHING:
                return (
                    <div className="my-5 d-flex justify-content-center">
                        <div className="text-center py-5" style={{ maxWidth: 700 }}>
                            <LoadingContainer
                                loadingText={'Preparing recording...'}
                                infoText={
                                    'Depending on the size of you recording this may take a while. Once a few samples are prepared' +
                                    ' you will be able to access those although all data has not been processed.'
                                }
                            />
                        </div>
                    </div>
                )

            case PageState.NO_SIGNALS:
                return prepareForAnalytics()

            default:
                return optionsCard()
        }
    }

    return (
        <>
            <Card className="shadow-sm rounded-4 border-0 text-start mb-1">
                <Card.Body className="p-1">{optionsOrPrepareForAnalytics()}</Card.Body>
            </Card>
            <div className="d-flex flex-wrap mt-3">
                {renderSignalTimeSeriesPanels()}
                {renderMapPanels()}
                {renderJitterDetectionPanels()}
            </div>
            {pageState === PageState.DONE && addNewVisualization()}
        </>
    )
}
