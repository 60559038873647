const ORGANISATION_REPLACEABLE_PATH_PARAM = ':organisationUid'
const PROJECT_REPLACEABLE_PATH_PARAM = ':projectUid'
const RECORDING_REPLACEABLE_PATH_PARAM = ':recordingSessionId'
const BROKER_REPLACEABLE_PATH_PARAM = ':brokerName'

export const PageRoutes = {
    LOGIN: '/',
    AGREEMENT: '/agreement',
    SCHEDULED_FOR_DELETION: '/org-scheduled-for-deletion',
    NOT_FOUND: '/not-found',
    ADMIN: '/admin',
    PROJECT_BROKERS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/brokers`,
    PROJECT_BROKER_DETAILS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/brokers/${BROKER_REPLACEABLE_PATH_PARAM}`,
    PROJECT_SETTINGS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/settings`,
    PROJECT_RECORDINGS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/recordings`,
    PROJECT_RECORDING_DETAILS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/recordings/${RECORDING_REPLACEABLE_PATH_PARAM}`,
    PROJECT_RECORDING_ANALYTICS: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/analytics/recordings/${RECORDING_REPLACEABLE_PATH_PARAM}`,
    PROJECT_SIGNAL_DATABASES: `/p/${PROJECT_REPLACEABLE_PATH_PARAM}/signal-databases`,
    ORGANISATION_HOME: `/orgs/${ORGANISATION_REPLACEABLE_PATH_PARAM}`,
    ORGANISATION_LICENSES: `/orgs/${ORGANISATION_REPLACEABLE_PATH_PARAM}/licenses`,
    ORGANISATION_USERS: `/orgs/${ORGANISATION_REPLACEABLE_PATH_PARAM}/users`,
    ORGANISATION_SETTINGS: `/orgs/${ORGANISATION_REPLACEABLE_PATH_PARAM}/settings`,
}

export const ProjectRoutes = {
    toBrokersList: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return PageRoutes.LOGIN
        }
        return PageRoutes.PROJECT_BROKERS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
    toBrokerDetails: (projectUid: string | undefined, brokerName: string | undefined): string => {
        if (projectUid === undefined || brokerName === undefined) {
            return PageRoutes.LOGIN
        }
        return PageRoutes.PROJECT_BROKER_DETAILS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid).replace(
            BROKER_REPLACEABLE_PATH_PARAM,
            brokerName
        )
    },
    toSettings: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return PageRoutes.LOGIN
        }
        return PageRoutes.PROJECT_SETTINGS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
    toRecordingsList: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return PageRoutes.LOGIN
        }
        return PageRoutes.PROJECT_RECORDINGS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
    toRecordingDetails: (projectUid: string | undefined, recordingSessionId: string | undefined): string => {
        if (projectUid === undefined || recordingSessionId === undefined) {
            return PageRoutes.LOGIN
        }
        return PageRoutes.PROJECT_RECORDING_DETAILS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid).replace(
            RECORDING_REPLACEABLE_PATH_PARAM,
            recordingSessionId
        )
    },
    toRecordingAnalytics: (projectUid: string | undefined, recordingSessionId: string | undefined): string => {
        if (projectUid === undefined || recordingSessionId === undefined) {
            return PageRoutes.LOGIN
        }
        return PageRoutes.PROJECT_RECORDING_ANALYTICS.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid).replace(
            RECORDING_REPLACEABLE_PATH_PARAM,
            recordingSessionId
        )
    },
    toSignalDatabases: (projectUid: string | undefined): string => {
        if (projectUid === undefined) {
            return PageRoutes.LOGIN
        }
        return PageRoutes.PROJECT_SIGNAL_DATABASES.replace(PROJECT_REPLACEABLE_PATH_PARAM, projectUid)
    },
}

export const OrganisationRoutes = {
    toHome: (organisationUid: string | undefined): string => {
        if (organisationUid === undefined) {
            return PageRoutes.LOGIN
        }
        return (PageRoutes.ORGANISATION_HOME as string).replace(ORGANISATION_REPLACEABLE_PATH_PARAM, organisationUid)
    },
    toLicenses: (organisationUid: string | undefined): string => {
        if (organisationUid === undefined) {
            return PageRoutes.LOGIN
        }
        return (PageRoutes.ORGANISATION_LICENSES as string).replace(
            ORGANISATION_REPLACEABLE_PATH_PARAM,
            organisationUid
        )
    },
    toSettings: (organisationUid: string | undefined): string => {
        if (organisationUid === undefined) {
            return PageRoutes.LOGIN
        }
        return (PageRoutes.ORGANISATION_SETTINGS as string).replace(
            ORGANISATION_REPLACEABLE_PATH_PARAM,
            organisationUid
        )
    },
    toUsers: (organisationUid: string | undefined): string => {
        if (organisationUid === undefined) {
            return PageRoutes.LOGIN
        }
        return (PageRoutes.ORGANISATION_USERS as string).replace(ORGANISATION_REPLACEABLE_PATH_PARAM, organisationUid)
    },
}
