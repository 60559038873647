import { useEffect } from 'react'
import { useState } from 'react'
import { Formik } from 'formik'
import { object, string, number, date, InferType } from 'yup'
import { Modal, Form, InputGroup, Spinner, Col, Row, Button, Accordion } from 'react-bootstrap'
import { CreateProjectRequest, Project, UserBillableUnitInfo } from '../../api/CloudApi/types'
import { ErrorIcon, PlayIcon } from '../../assets/Icons'
import ErrorContainer from '../ErrorContainer'
import LoadingContainer from '../LoadingContainer'
import { toast } from 'react-toastify'
import { CLOUD_RESOURCE_VALIDATION_REGEX_PATTERN } from '../../validation/CloudValidation'
import CloudApi from '../../api/CloudApi'

interface AddProjectModalProps {
    currentBillableUnit: UserBillableUnitInfo | undefined
    show: boolean
    handleCloseFunction: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

const NOT_STARTED_STATE = 'NOT_STARTED'
const IN_PROGRESS_STATE = 'IN_PROGRESS'
const DONE_STATE = 'DONE'
const ERROR_STATE = 'ERROR'
type RequestState = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR'

export default function AddProjectModal(props: AddProjectModalProps) {
    const [projectName, setProjectName] = useState<string>()
    const [projectId, setProjectId] = useState<string>()
    const [projectDescription, setProjectDescription] = useState<string>('')

    const [requestState, setRequestState] = useState<RequestState>(NOT_STARTED_STATE)

    const [validated, setValidated] = useState(false)
    const [knownErrorMessage, setKnownErrorMessage] = useState<string>()

    useEffect(() => {
        console.debug('Mounted add project modal!')
    }, [])

    useEffect(() => {
        if (requestState === DONE_STATE && projectId && !knownErrorMessage) {
            // Go to project dashboard
            window.location.replace(CloudApi.getProjectHomeUrl(projectId))
        }
    }, [requestState])

    const validateInput = (): boolean => {
        if (projectName === undefined || projectId === '') {
            alert('You must provide a project name')
            return false
        }
        if (projectId === undefined || projectId === '') {
            alert('You must provide a project ID')
            return false
        }
        return true
    }

    const resetState = () => {
        setRequestState(NOT_STARTED_STATE)
        setProjectName(undefined)
        setProjectId(undefined)
        setProjectDescription('')
        setKnownErrorMessage(undefined)
    }

    const createProject = async (projectId: string, projectName: string, projectDescription: string) => {
        if (props.currentBillableUnit?.organisation.uid) {
            console.log(props.currentBillableUnit.organisation.uid)
            setRequestState(IN_PROGRESS_STATE)
            setKnownErrorMessage(undefined)
            try {
                const createProjectRequest = {
                    displayName: projectName,
                    uid: projectId,
                    description: projectDescription,
                } as CreateProjectRequest
                await CloudApi.createProject(createProjectRequest, props.currentBillableUnit.organisation.uid)
                setRequestState(DONE_STATE)
            } catch (err: any) {
                // Handle known errors here
                if (err.response.status === 409) {
                    setKnownErrorMessage('Project ID already exists, please choose another ID')
                    setRequestState(NOT_STARTED_STATE)
                } else {
                    setRequestState(ERROR_STATE)
                }
            }
        }
    }

    const setProjectNameAndId = (projectName: string) => {
        if (projectName.length <= 100) {
            setProjectName(projectName)
            setValidProjectId(projectName)
        }
    }

    const setValidDescription = (description: string) => {
        if (description.length <= 300) {
            setProjectDescription(description)
        }
    }

    const setValidProjectId = (projectName: string) => {
        const validProjectIdPattern = new RegExp('^[a-z]([a-z0-9-]*[a-z0-9])?(\\[a-z]([a-z0-9-]*[a-z0-9])?)*$')
        const validProjectIdCharacterPattern = new RegExp('[a-z0-9-]')
        const projectId = projectName.replaceAll(' ', '-').toLowerCase()
        const sanitizedProjectId = Array.from(projectId)
            .map((character) => (validProjectIdCharacterPattern.test(character) ? character : ''))
            .join('')
        if (sanitizedProjectId.length <= 20 && validProjectIdPattern.test(sanitizedProjectId)) {
            setProjectId(sanitizedProjectId)
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    {
        /*
      lastName: yup.string().required(),
            username: yup.string().required(),
            city: yup.string().required(),
            state: yup.string().required(),
            zip: yup.string().required(),
            terms: yup.bool().required().oneOf([true], 'Terms must be accepted'),
    */
    }

    const getForm = () => {
        const schema = object().shape({
            projectName: string().required('Field is required').max(30).min(5),

            projectId: string()
                .required('Field is required')
                .max(20)
                .min(5)
                .matches(new RegExp(CLOUD_RESOURCE_VALIDATION_REGEX_PATTERN), 'Must be a valid project id'),

            desc: string().optional(),
        })

        return (
            <Formik
                // After an error this is used to fake that these values has already been "touched"
                initialTouched={{
                    projectName: projectName !== undefined,
                    projectId: projectId !== undefined,
                    desc: projectDescription !== undefined,
                }}
                validationSchema={schema}
                onSubmit={(event: any) => {
                    setProjectId(event.projectId)
                    setProjectName(event.projectName)
                    setProjectDescription(event.desc)
                    createProject(event.projectId, event.projectName, event.desc)
                }}
                initialValues={{
                    projectId: projectId ? projectId : '',
                    projectName: projectName ? projectName : '',
                    desc: '',
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className={'mb-3'}>
                            <Form.Group as={Row}>
                                <InputGroup size="sm" hasValidation={true}>
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        Name
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder={'Your project name'}
                                        type="text"
                                        className="remotive-font-sm w-75"
                                        onBlur={handleBlur} // This apparently
                                        name={'projectName'}
                                        value={values.projectName}
                                        onChange={handleChange}
                                        isValid={(!errors.projectName as boolean) && (touched.projectName as boolean)}
                                        isInvalid={errors.projectName !== undefined}
                                    />
                                    <div className="w-25"></div>
                                    <p className="remotive-font-xs text-secondary m-0 p-1 w-75">Project display name</p>
                                    <div className="w-25"></div>
                                    <Form.Control.Feedback className={'w-75'} type="invalid">
                                        <>{errors.projectName}</>
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <Row className={'mb-3'}>
                            <Form.Group as={Row} controlId="idValidationId">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        ID
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder={'your-project-id'}
                                        type="text"
                                        className="remotive-font-sm w-75"
                                        onBlur={handleBlur} // This apparently
                                        name={'projectId'}
                                        value={values.projectId}
                                        onChange={handleChange}
                                        isValid={(!errors.projectId as boolean) && (touched.projectId as boolean)}
                                        isInvalid={errors.projectId !== undefined}
                                    />

                                    <div className="w-25"></div>
                                    <p className="remotive-font-xs text-secondary m-0 p-1 w-75">
                                        Only lowercase, digits, and hyphens. Must begin with letter, and may not end
                                        with hyphen.
                                    </p>
                                    <div className="w-25"></div>
                                    <Form.Control.Feedback className={'w-75'} type="invalid">
                                        <>{errors.projectId}</>
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        {/* Optional items a collapsed by default */}
                        <Accordion defaultActiveKey={undefined} className="ms-2 mb-3">
                            <Accordion.Item eventKey="0" className="border-0">
                                <Accordion.Header>
                                    <div className="d-flex flex-column">
                                        <p className="m-0 p-0 remotive-font-md">Optional</p>
                                        <p className="m-0 p-0 remotive-font-xs text-secondary">{`Project description`}</p>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <Row className={'mb-3'}>
                                        <Form.Group as={Row} controlId={'descValidationId'}>
                                            <InputGroup size="sm">
                                                <InputGroup.Text
                                                    className={`remotive-font-md bg-transparent border-0 w-25 h-100`}
                                                >
                                                    Description
                                                </InputGroup.Text>
                                                <Form.Control
                                                    as="textarea"
                                                    style={{ minHeight: 140 }}
                                                    className="remotive-font-sm w-75"
                                                    placeholder="E.g This project is used for..."
                                                    name={'desc'}
                                                    value={values.desc}
                                                    onChange={handleChange}
                                                />
                                                <div className="w-25"></div>
                                                <p className="remotive-font-xs text-secondary m-0 p-1 w-75">Optional</p>
                                            </InputGroup>
                                        </Form.Group>
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className="d-flex justify-content-end">
                            <Button
                                className="btn remotive-btn remotive-btn-success align-center"
                                disabled={!isValid || (!touched.projectName && !touched.projectId)}
                                type={'submit'}
                            >
                                <div className="d-flex align-items-center text-light">
                                    <p className="text-light m-0">Create project</p>
                                </div>
                            </Button>
                        </div>
                        {knownErrorMessage && (
                            <div className={'p-3 remotive-warning-90-color'}>{knownErrorMessage}</div>
                        )}
                    </Form>
                )}
            </Formik>
        )
    }

    const getModalContent = () => {
        const title = 'Create project'
        switch (requestState) {
            case IN_PROGRESS_STATE:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <LoadingContainer loadingText="Creating project..." />
                        </Modal.Body>
                    </>
                )

            case ERROR_STATE:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-5">
                                <ErrorContainer errorText="There was an issue creating your project..." />
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            case DONE_STATE:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Done!</p>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">{getForm()}</Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal bsPrefix="" show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
