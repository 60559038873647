import { PlaybackStateImpl } from './PlaybackStateImpl'
import { PlaybackState, PlaybackStateFile } from './types'

export class CombinedPlaybackState implements PlaybackState {
    /*
        Very complicated class to wrap multiple Playbacks when playing multiple files
        at the same time. Does it best to keep them together
    */

    playbacks: Array<PlaybackStateImpl> = []

    debug() {
        return this.playbacks.map((p) => `playing? = ${p.isPlaying()} ${JSON.stringify(p.files())}`).join('\n')
    }

    tryAddPlayback(playback: PlaybackStateImpl): boolean {
        // Magic ugly stuff to have the currently playing/paused files running without having any
        // stopped files sneaking in but at the same time make sure to stop the playback properly
        if (this.playbacks.length > 0) {
            const existing = this.playbacks.filter((p) => p.isSame(playback))
            if (this.isStopped() && !playback.isStopped()) {
                // If there is a new playback and the current one is stopped we assume that its a new recording
                // and the old one should be replaced
                this.playbacks = [playback]
                return true
            } else if (existing.length === 0) {
                // This does not exist yet but lets only add it if it has the same mode as the others but not stopped.
                // If it does not exist and is stopped it is not part of this playback
                if (
                    this.playbacks[0].playbackMode.getMode() === playback.playbackMode.getMode() &&
                    !playback.isStopped()
                ) {
                    this.playbacks.push(playback)
                    return true
                }
            } else {
                // Replace state of the existing (which can only be of length = 1)
                existing[0].playbackMode = playback.playbackMode
                existing[0].setFiles(playback.files())
                return true
            }
        } else {
            // Never add a stopped if there are none yet.
            if (!playback.isStopped()) {
                this.playbacks.push(playback)
                return true
            }
        }
        return false
    }

    files(): Array<PlaybackStateFile> {
        return this.playbacks.map((p: PlaybackStateImpl) => p.files()).flat()
    }

    isPaused(): boolean {
        return this.playbacks.length > 0 && this.playbacks[0].isPaused()
    }

    isPlaying(): boolean {
        return this.playbacks.length > 0 && this.playbacks[0].isPlaying()
    }

    isRecording(): boolean {
        return this.playbacks.length > 0 && this.playbacks[0].isRecording()
    }

    isStopped(): boolean {
        return this.playbacks.length > 0 && this.playbacks[0].isStopped()
    }

    isSeeking(): boolean {
        return this.playbacks.length > 0 && this.playbacks[0].isSeeking()
    }

    pause = async () => {
        return Promise.all(this.playbacks.map((p) => p.pause()))
    }

    play = async () => {
        return Promise.all(this.playbacks.map((p) => p.play()))
    }

    stop = async () => {
        return Promise.all(this.playbacks.map((p) => p.stop()))
    }

    seek = async (seekOffset: number) => {
        return Promise.all(this.playbacks.map((p) => p.seek(seekOffset)))
    }

    startTimestampMs(): number {
        return this.playbacks.length > 0
            ? Math.max(...this.playbacks.map((p: PlaybackStateImpl) => p.startTimestampMs()))
            : 0
    }

    currentOffsetDurationMs(): number {
        return this.playbacks.length > 0
            ? Math.max(
                  ...this.playbacks.map((p: PlaybackStateImpl) => {
                      return p.currentOffsetDurationMs()
                  })
              )
            : 0
    }

    endTimestampMs(): number {
        return this.playbacks.length > 0
            ? Math.max(...this.playbacks.map((p: PlaybackStateImpl) => p.endTimestampMs()))
            : 0
    }

    totalDurationMs(): number {
        return this.playbacks.length > 0
            ? Math.max(...this.playbacks.map((p: PlaybackStateImpl) => p.totalDurationMs()))
            : 0
    }
}
