import { useRef, useEffect } from 'react'
import videojs from 'video.js'
import 'video.js/dist/video-js.css'

interface VideoPlayerProps {
    onVideoLoadedCallback: Function
    videoSource: string
    videoSourceType: 'video/mov' | 'video/mp4'
}

export const VideoPlayer = (props: VideoPlayerProps) => {
    const videoRef = useRef<HTMLDivElement>(null)
    const playerRef = useRef<any>()

    const defaufltOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
    }

    useEffect(() => {
        console.debug('Mounted video player!')
        return () => {
            console.debug('Unmounted video player!')
        }
    }, [])

    useEffect(() => {
        const options = {
            ...defaufltOptions,
            sources: [
                {
                    src: props.videoSource,
                },
            ],
        }

        // Make sure Video.js player is only initialized once
        if (!playerRef.current) {
            // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode.
            const videoElement = document.createElement('video-js')
            videoElement.classList.add('vjs-big-play-centered')
            videoRef.current?.appendChild(videoElement)
            playerRef.current = videojs(videoElement, options, () => {
                props.onVideoLoadedCallback()
            })
        } else {
            playerRef.current.autoplay(options.autoplay)
            playerRef.current.src(options.sources)
        }
    }, [props, videoRef])

    // Dispose the Video.js player when the functional component unmounts
    useEffect(() => {
        const player = playerRef.current
        return () => {
            if (player && !player.isDisposed()) {
                player.dispose()
                playerRef.current = null
            }
        }
    }, [playerRef])

    return (
        <div data-vjs-player>
            <div ref={videoRef} />
        </div>
    )
}

export default VideoPlayer
