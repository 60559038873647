import { Accordion, Button, Card, Container, Form, InputGroup } from 'react-bootstrap'
import { AuthenticatedUser, BrokerContainer, Project, UserBillableUnitInfo } from '../../api/CloudApi/types'
import NavigationBar from '../../components/navigation/NavigationBar'
import { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import {
    ArrowNorthIcon,
    ArrowWestIcon,
    BrokersIcon,
    CopyIcon,
    ErrorIcon,
    ExploreIcon,
    HideIcon,
    ShowIcon,
    SuccessIcon,
} from '../../assets/Icons'
import CloudApi from '../../api/CloudApi'
import LoadingContainer from '../../components/LoadingContainer'
import HelpOverlayModalModal from '../../components/modals/HelpOverlayModal'
import { isDemo, isGuidedDemo } from '../../utils/demo'
import HelpTextBox from '../../components/cards/DemoHelpCard'
import { BrokerLogStreamCard } from './BrokerLogStreamCard'
import { useProductAnalyticsClient } from '../../utils/ProductAnalytics'
import { OrganisationRoutes, ProjectRoutes } from '../../Routes'
import { BreadCrumb, BreadCrumbs } from '../../types/BreadCrumbs'
import CliHintContainer from '../../components/CliHintContainer'
import { storeRecentBroker } from '../../types/RecentBroker'
import { PageDetails } from '../../utils/pageDetails'

interface BrokerDetailsProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined
    projects: Array<Project>
    currentProject: Project | undefined
    currentUser: AuthenticatedUser | undefined
}

const COPY_MESSAGE_RESET_TIMER = 2000
const INPUT_FIELD_ICON_SIZE = 17
const BROKER_IS_RUNNING_MESSAGE = 'CONDITION_SUCCEEDED'

export default function BrokerDetails(props: BrokerDetailsProps) {
    const { brokerName } = useParams()

    const [isKeyHidden, setIsKeyHidden] = useState<boolean>(true)
    const [isApiKeyCopied, setIsApiKeyCopied] = useState<boolean>(false)
    const [isUrlCopied, setIsUrlCopied] = useState<boolean>(false)
    const [currentBroker, setCurrentBroker] = useState<BrokerContainer>()
    const [showHelpModal, setShowHelpModal] = useState(false)
    const [showDemoModal, setShowDemoModal] = useState(isGuidedDemo())

    const HIGHLIGHT_HELP_OBJECT_STYLE = showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}

    // TODO - Consider creating this once next time we add a metric and pass it as prop
    const productAnalyticsClient = useProductAnalyticsClient({
        user: props.currentUser,
        billableUnit: props.currentBillableUnit,
    })

    useEffect(() => {
        if (currentBroker?.name) {
            props.updateCurrentPageDetails({
                documentTitle: `Broker Details - ${currentBroker?.name}`,
                productAnalyticsEventName: 'Broker Details',
            })
        }
    }, [currentBroker])

    useEffect(() => {
        console.debug('Mounted broker details page!')
    }, [])

    useEffect(() => {
        if (
            props.currentProject !== undefined &&
            props.currentBillableUnit !== undefined &&
            currentBroker !== undefined
        ) {
            storeRecentBroker(currentBroker, props.currentProject, props.currentBillableUnit)
        }
    }, [props.currentProject, props.currentBillableUnit, currentBroker])

    useEffect(() => {
        if (props.currentProject) {
            const setBroker = async () => {
                const projectUid = props.currentProject?.uid
                if (projectUid !== undefined && brokerName !== undefined && props.currentUser) {
                    const broker = await CloudApi.getBroker(projectUid, brokerName, props.currentUser)
                    setCurrentBroker(broker)
                }
            }
            setBroker()
        }
    }, [props.currentProject])

    useEffect(() => {
        if (isUrlCopied) {
            const timer = setTimeout(() => setIsUrlCopied(false), COPY_MESSAGE_RESET_TIMER)
            return () => clearTimeout(timer)
        }
    }, [isUrlCopied])

    useEffect(() => {
        if (isApiKeyCopied) {
            const timer = setTimeout(() => setIsApiKeyCopied(false), COPY_MESSAGE_RESET_TIMER)
            return () => clearTimeout(timer)
        }
    }, [isApiKeyCopied])

    const getVisiblityIcon = () => {
        return isKeyHidden ? (
            <ShowIcon sx={{ fontSize: INPUT_FIELD_ICON_SIZE }} />
        ) : (
            <HideIcon sx={{ fontSize: INPUT_FIELD_ICON_SIZE }} />
        )
    }

    const getCopyIcon = (isCopied: boolean) => {
        if (isCopied) {
            return 'Copied!'
        }
        return <CopyIcon sx={{ fontSize: INPUT_FIELD_ICON_SIZE }} />
    }

    const copyBrokerUrl = () => {
        if (currentBroker !== undefined && currentBroker.url) {
            navigator.clipboard.writeText(currentBroker.url)
            setIsUrlCopied(true)
        }
    }

    const copyApiKey = () => {
        if (currentBroker !== undefined && currentBroker.keys.toString()) {
            navigator.clipboard.writeText(currentBroker.keys.toString())
            setIsApiKeyCopied(true)
        }
    }

    const brokerDetails = () => {
        if (currentBroker !== undefined) {
            const isRunning = currentBroker.status === BROKER_IS_RUNNING_MESSAGE
            return (
                <>
                    <div className="ms-2 mb-4 mt-2">
                        {isRunning ? (
                            <div className="d-flex align-items-center remotive-primary-60-color">
                                <SuccessIcon sx={{ fontSize: 28 }} className="me-1" />
                                <p className="remotive-primary-60-color m-0">Broker is up and running</p>
                            </div>
                        ) : (
                            <div className="d-flex align-items-center">
                                <ErrorIcon sx={{ fontSize: 27 }} className="remotive-warning-100-color me-1" />
                                <p className="m-0">There was an issue starting the broker...</p>
                            </div>
                        )}
                    </div>
                    <div className="rounded remotive-primary-10-background m-1 p-3">
                        <div className="d-flex">
                            <div>
                                <p className="fs-4 m-0">{currentBroker.shortName}</p>
                                <p className="remotive-font-sm text-secondary d-block">
                                    Creator: {currentBroker.creator?.displayName || 'N/A'}
                                    <br />
                                    Tag: {currentBroker.tag || ''}
                                </p>
                            </div>
                        </div>
                        <div style={{ maxWidth: '650px' }}>
                            <InputGroup className="mb-1" size="sm">
                                <InputGroup.Text className="remotive-primary-40-background text-light remotive-primary-40-border">
                                    URL
                                </InputGroup.Text>
                                <Form.Control
                                    disabled
                                    className="bg-white remotive-primary-40-border"
                                    value={currentBroker.url}
                                />
                                <Button
                                    className="remotive-btn-primary"
                                    disabled={!isRunning}
                                    onClick={() => copyBrokerUrl()}
                                >
                                    {getCopyIcon(isUrlCopied)}
                                </Button>
                            </InputGroup>
                            <InputGroup className="mb-3" size="sm">
                                <InputGroup.Text className="remotive-primary-40-background text-light remotive-primary-40-border">
                                    API Key
                                </InputGroup.Text>
                                <Form.Control
                                    type={isKeyHidden ? 'password' : 'text'}
                                    disabled
                                    className="bg-white remotive-primary-40-border"
                                    value={currentBroker.keys.toString()}
                                />
                                <Button
                                    className="remotive-btn-primary"
                                    disabled={!isRunning}
                                    onClick={() => setIsKeyHidden(!isKeyHidden)}
                                >
                                    {getVisiblityIcon()}
                                </Button>

                                <Button
                                    className="remotive-btn-primary"
                                    disabled={!isRunning}
                                    onClick={() => copyApiKey()}
                                >
                                    {getCopyIcon(isApiKeyCopied)}
                                </Button>
                            </InputGroup>
                            <div>
                                {helpTextGoToBroker()}
                                <a
                                    href={CloudApi.getBrokerAppUrl(currentBroker, isDemo(), props.currentProject?.uid)}
                                    target={'_blank'}
                                    style={HIGHLIGHT_HELP_OBJECT_STYLE}
                                    onClick={() =>
                                        productAnalyticsClient.track('Go to Broker', {
                                            brokerName: currentBroker.name,
                                        })
                                    }
                                >
                                    <button className="btn remotive-btn remotive-btn-success" disabled={!isRunning}>
                                        <div className="d-flex">
                                            <ExploreIcon sx={{ fontSize: 23 }} />
                                            <p className="ms-2 m-0">Go to broker</p>
                                        </div>
                                    </button>
                                </a>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        return <LoadingContainer />
    }

    const demoTextGoBack = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showDemoModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginLeft: -10, marginTop: 100 }}
                >
                    <div className="d-flex align-items-center">
                        <ArrowWestIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0 fs-6">1. Go here to choose a recording to play</p>
                    </div>
                </div>
            </>
        )
    }

    const helpTextGoToBroker = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex justify-content-center flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: 55, marginLeft: -15 }}
                >
                    <div className="d-flex flex-column  align-items-center">
                        <ArrowNorthIcon sx={{ fontSize: 30 }} className="me-3" />
                        <p className="text-light text-start m-0">
                            Click here to go the the broker <br />
                            application in a new window
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const demoMessage = () => {
        return (
            <HelpTextBox
                title={
                    <div className="text-start">
                        <p className="remotive-font-md mb-1">
                            <b>Here you will find detailed information about this broker.</b> If you need to access the
                            URL & api-key or simply just want to navigate to the broker application, this is the place!
                        </p>
                    </div>
                }
            />
        )
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex align-items-center">
                    <BrokersIcon className="remotive-primary-50-color fs-3 me-1" />
                    <p className="fs-3 lexend-light mb-0">Broker details</p>
                </div>
            </>
        )
    }

    const pageBody = () => {
        return (
            <>
                {isDemo() && demoMessage()}
                {brokerDetails()}
            </>
        )
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
                { title: 'Brokers /', route: ProjectRoutes.toBrokersList(props.currentProject?.uid) } as BreadCrumb,
            ],
            current: {
                title: 'Broker Details',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    const deleteCommand = () => {
        if (brokerName?.startsWith('personal-')) {
            return 'delete-personal'
        } else {
            return `delete ${brokerName}`
        }
    }

    // TODO - Fix so that we can better get the
    const cliCmdbrokerName = () => {
        if (brokerName?.startsWith('personal-')) {
            return 'personal'
        }
        return brokerName
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                setDemoOverlayFunction={setShowDemoModal}
                zIndexOnTop={showDemoModal}
                isDemoGuideActive={showDemoModal}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                {demoTextGoBack()}
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <Card className="shadow-sm border-0 rounded-4 text-start mb-4" style={{ minHeight: '300px' }}>
                        <Card.Body className="pb-3 pt-3">{pageBody()}</Card.Body>
                    </Card>
                    <BrokerLogStreamCard currentProject={props.currentProject} brokerName={brokerName} />
                </div>
                <CliHintContainer
                    hints={[
                        {
                            title: 'Tail log',
                            command: `remotive cloud brokers logs ${brokerName} --tail --project ${props.currentProject?.uid}`,
                        },
                        {
                            title: 'Get log history',
                            command: `remotive cloud brokers logs ${brokerName}  --history-minutes 10 --project ${props.currentProject?.uid}`,
                        },
                        {
                            title: 'Describe broker',
                            command: `remotive cloud brokers describe ${cliCmdbrokerName()} --project ${
                                props.currentProject?.uid
                            }`,
                        },
                        {
                            title: 'Delete broker',
                            command: `remotive cloud brokers ${deleteCommand()} --project ${props.currentProject?.uid}`,
                        },
                    ]}
                />
            </Container>

            {/* Modals below */}
            <HelpOverlayModalModal
                handleCloseFunction={() => {
                    setShowHelpModal(false)
                    setShowDemoModal(false)
                }}
                show={showHelpModal || showDemoModal}
            />
        </div>
    )
}
