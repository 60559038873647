import { Project, RecordingSession, UserBillableUnitInfo } from '../api/CloudApi/types'

type RecentRecording = {
    recordingSession: RecordingSession
    project: Project
    billableUnit: UserBillableUnitInfo
}

const LOCAL_STORAGE_KEY_RECENT_RECORDINGS = 'LS_RECENT_RECORDINGS'
const MAX_RECENT_RECORDINGS_TO_STORE = 3

export const getRecentRecordings = (currentBillableUnit?: UserBillableUnitInfo) => {
    try {
        const recentRecordingsAsString = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
        if (recentRecordingsAsString !== null) {
            const recentRecordings = (JSON.parse(recentRecordingsAsString) as Array<RecentRecording>).filter(
                (it) => it.billableUnit.organisation.uid === currentBillableUnit?.organisation.uid
            )
            return recentRecordings
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent recordings, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
    }
    return undefined
}

export const storeRecentRecording = (
    recording: RecordingSession,
    project: Project,
    billableUnit: UserBillableUnitInfo
) => {
    try {
        const recentRecordingsAsJSon = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
        const thisRecording = {
            recordingSession: recording,
            project: project,
            billableUnit: billableUnit,
        } as RecentRecording
        if (recentRecordingsAsJSon !== null) {
            const recentRecordings = JSON.parse(recentRecordingsAsJSon) as Array<RecentRecording>
            const recordingIsNotAlreadyInList =
                recentRecordings.find(
                    (it) =>
                        it.billableUnit.organisation.uid === billableUnit.organisation.uid &&
                        it.project.uid === project.uid &&
                        it.recordingSession.sessionId === recording.sessionId
                ) === undefined
            if (recordingIsNotAlreadyInList) {
                recentRecordings.unshift(thisRecording)
                localStorage.setItem(
                    LOCAL_STORAGE_KEY_RECENT_RECORDINGS,
                    JSON.stringify(recentRecordings.slice(0, MAX_RECENT_RECORDINGS_TO_STORE))
                )
            }
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS, JSON.stringify([thisRecording]))
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent recordings, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_RECORDINGS)
    }
}
