import { Card, Container, Spinner } from 'react-bootstrap'
import { toast } from 'react-toastify'
import {
    AuthenticatedUser,
    Project,
    ProjectServiceAccount,
    UserBillableUnitInfo,
    UserInfoWithRoles,
} from '../../api/CloudApi/types'
import NavigationBar from '../../components/navigation/NavigationBar'
import { useEffect, useRef, useState } from 'react'
import CloudApi from '../../api/CloudApi'
import {
    AddIcon,
    ArrowEastIcon,
    ArrowNorthIcon,
    ArrowSouthIcon,
    CredentialsIcon,
    DeleteIcon,
    EditIcon,
    ProjectSettingsIcon,
    RefreshIcon,
    ServiceAccountIcon,
    UsersIcon,
} from '../../assets/Icons'
import { NavLink } from 'react-router-dom'
import LoadingContainer from '../../components/LoadingContainer'
import CreateProjectUserModal from './CreateProjectUserModal'
import CreateProjectServiceAccountModal from './CreateProjectServiceAccountModal'
import ErrorContainer from '../../components/ErrorContainer'
import EditProjectUserModal from './EditProjectUserModal'
import HelpOverlayModalModal from '../../components/modals/HelpOverlayModal'
import { hasPermission, Permission } from '../../utils/permission'
import NotFoundContaner from '../../components/NotFoundContainer'
import { formatAsDatetime } from '../../utils/datetime'
import ManageAccessTokenModal from '../../components/modals/ManageAccessTokenModal'
import EditProjectServiceAccountModal from './EditProjectServiceAccountModal'
import { OrganisationRoutes } from '../../Routes'
import { BreadCrumb, BreadCrumbs } from '../../types/BreadCrumbs'
import CliHintContainer from '../../components/CliHintContainer'
import { PageDetails } from '../../utils/pageDetails'

interface ProjectSettingsProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    projects: Array<Project>
    currentUser: AuthenticatedUser | undefined
}

enum PageState {
    LOADING_USERS,
    LOADING,
    LOADING_ACCOUNTS,
    DONE,
    ERROR,
    DELETING_USER,
    DELETING_SERVICE_ACCOUNT,
    DELETING_PROJECT,
    DELETE_USER_ERROR,
    DELETE_SERVICE_ACCOUNT_ERROR,
    DELETE_PROJECT_ERROR,
}

export default function ProjectManagement(props: ProjectSettingsProps) {
    const [buUsers, setBuUsers] = useState<Array<UserInfoWithRoles>>()
    const [selectedProjectUser, setSelectedProjectUser] = useState<UserInfoWithRoles>()
    const [projectUsers, setProjectUsers] = useState<Array<UserInfoWithRoles>>()
    const [serviceAccountProjectUsers, setServiceAccountProjectUsers] = useState<Array<ProjectServiceAccount>>()
    const [showCreateProjectUserModal, setShowCreateProjectUserModal] = useState<boolean>(false)
    const [showCreateServiceAccountModal, setShowCreateServiceAccountModal] = useState(false)
    const [showServiceAccountKeyModal, setShowServiceAccountKeyModal] = useState(false)
    const [showEditProjectUserModal, setShowEditProjectUserModal] = useState<boolean>(false)
    const [showEditProjectServiceAccountModal, setShowEditProjectServiceAccountModal] = useState<boolean>(false)
    const [pageState, setPageState] = useState<PageState>(PageState.LOADING)
    const [showHelpModal, setShowHelpModal] = useState(false)

    const serviceAccountRef = useRef<ProjectServiceAccount>()

    const HIGHLIGHT_HELP_OBJECT_STYLE = showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}

    useEffect(() => {
        if (props.currentProject?.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Manage Project - ${props.currentProject?.displayName}`,
                productAnalyticsEventName: 'Project Settings',
            })
        }
    }, [props.currentProject])

    useEffect(() => {
        console.debug('Mounted Manage project page!')
    }, [])

    useEffect(() => {
        if (props.currentProject) {
            Promise.all([setUserState(), setServiceAccountsState()]).then(() => setPageState(PageState.DONE))
        }
    }, [props.currentProject])

    const setUserState = async () => {
        const projectUid = props.currentProject?.uid
        const buUid = props.currentBillableUnit?.organisation.uid
        if (projectUid && buUid) {
            setPageState(PageState.LOADING_USERS)
            try {
                const buUsers = (await CloudApi.listBuUsersForProject(projectUid)).data
                setBuUsers(buUsers)
                const projectUsers = (await CloudApi.listProjectUsers(projectUid)).data
                setProjectUsers(projectUsers)
                setPageState(PageState.DONE)
            } catch (err: any) {
                setPageState(PageState.ERROR)
            }
        }
    }

    const setServiceAccountsState = async () => {
        const projectUid = props.currentProject?.uid
        const buUid = props.currentBillableUnit?.organisation.uid
        if (projectUid && buUid) {
            setPageState(PageState.LOADING_ACCOUNTS)
            try {
                const serviceAccountProjectUsers = (await CloudApi.listProjectServiceAccounts(projectUid)).data
                setServiceAccountProjectUsers(serviceAccountProjectUsers)
                setPageState(PageState.DONE)
            } catch (err: any) {
                setPageState(PageState.ERROR)
            }
        }
    }

    const removeUserFromProject = async (userUid: UserInfoWithRoles) => {
        const projectUid = props.currentProject?.uid
        setShowHelpModal(false)
        if (props.currentUser?.uid === userUid.user.uid) {
            toast.warning('You are not allowed to remove yourself from the project')
            return
        }

        if (projectUid) {
            setPageState(PageState.DELETING_USER)
            try {
                await CloudApi.removeProjectUser(projectUid, userUid)
            } catch (err: any) {
                toast.error('Failed to remove user')
                setPageState(PageState.DELETE_USER_ERROR)
            }
            setUserState()
        }
    }

    const addProjectUser = () => {
        setShowHelpModal(false)
        setShowCreateProjectUserModal(true)
    }

    const editProjectUser = (userInfo: UserInfoWithRoles) => {
        setShowHelpModal(false)
        setSelectedProjectUser(userInfo)
        setShowEditProjectUserModal(true)
    }

    const deleteProject = async () => {
        if (props.currentProject && props.currentBillableUnit) {
            try {
                setPageState(PageState.DELETING_PROJECT)
                await CloudApi.deleteProject(props.currentProject)
                window.location.replace(CloudApi.getOrganisationHomeUrl(props.currentBillableUnit))
            } catch (err: any) {
                toast.error('Failed to delete project')
                setPageState(PageState.DELETE_PROJECT_ERROR)
            }
        }
    }

    const serviceAccountsCardHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between text-truncate">
                    <div className="d-flex d-inline align-items-center text-truncate">
                        <ServiceAccountIcon className="remotive-primary-50-color fs-6 ms-1 me-2" />
                        <p className="fs-6 m-0 d-inline lexend-light text-truncate">Service accounts</p>
                    </div>
                    <div className="d-inline mb-1">
                        {helpTextAddServiceAccount()}
                        <button
                            style={HIGHLIGHT_HELP_OBJECT_STYLE}
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_ADMIN,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => createProjectServiceAccount()}
                        >
                            <AddIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        <button
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                            onClick={() => {
                                setServiceAccountProjectUsers(undefined)
                                setServiceAccountsState()
                            }}
                        >
                            <RefreshIcon style={{ marginBottom: '3px', padding: '0px' }} sx={{ fontSize: 18 }} />
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const usersCardHeader = () => {
        return (
            <>
                <div className="text-start d-flex justify-content-between text-truncate">
                    <div className="d-flex d-inline align-items-center text-truncate">
                        <UsersIcon className="remotive-primary-50-color fs-6 ms-1 me-2" />
                        <p className="fs-6 m-0 d-inline lexend-light text-truncate">Project users</p>
                    </div>
                    <div className="d-inline mb-1">
                        {helpTextAddUser()}
                        <button
                            style={HIGHLIGHT_HELP_OBJECT_STYLE}
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary me-1"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_ADMIN,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => addProjectUser()}
                        >
                            <AddIcon style={{ marginBottom: '3px', padding: '1px' }} sx={{ fontSize: 18 }} />
                        </button>
                        <button
                            className="btn rounded-circle p-0 px-1 m-0 remotive-btn-primary"
                            onClick={() => {
                                setProjectUsers(undefined)
                                setBuUsers(undefined)
                                setUserState()
                            }}
                        >
                            <RefreshIcon style={{ marginBottom: '3px', padding: '0px' }} sx={{ fontSize: 18 }} />
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const serviceAccountList = () => {
        if (serviceAccountProjectUsers && serviceAccountProjectUsers.length > 0) {
            return (
                <>
                    {serviceAccountProjectUsers.map((serviceAccount, index) => {
                        return (
                            <div
                                key={`HEJ! ${serviceAccount.name}`}
                                className="rounded remotive-primary-10-background m-1 mx-0 px-1"
                                style={index === 0 ? HIGHLIGHT_HELP_OBJECT_STYLE : {}}
                            >
                                <div className="d-flex mb-1">
                                    <div className="col-3 col-md-4 p-1 border-end text-start">
                                        <p className="m-0 remotive-font-md text-truncate">{serviceAccount.name}</p>
                                        <p className="m-0 remotive-font-sm text-secondary text-truncate">
                                            {`${serviceAccount.roles.length} roles`}
                                        </p>
                                    </div>
                                    <div className="col-4 p-1 px-2 border-end text-start">
                                        <p className="m-0 remotive-font-md text-truncate">Created by</p>
                                        <p className="m-0 remotive-font-sm text-secondary text-truncate">
                                            {`${serviceAccount.creator || '-'} on ${
                                                formatAsDatetime(serviceAccount.created) || '-'
                                            }`}
                                        </p>
                                    </div>
                                    <div className="col-5 col-md-4 px-1 d-flex justify-content-between align-items-center">
                                        {index === 0 ? helpTextEditServiceAccount() : <></>}
                                        <button
                                            className={
                                                'btn remotive-btn-sm remotive-btn-primary d-flex align-items-center'
                                            }
                                            onClick={() => manageServiceAccountKeys(serviceAccount)}
                                            disabled={
                                                !hasPermission(
                                                    Permission.PROJECT_EDITOR_ADMIN,
                                                    props.currentBillableUnit,
                                                    props.currentProject
                                                )
                                            }
                                        >
                                            <CredentialsIcon sx={{ fontSize: 15 }} />
                                            <span className="d-none d-sm-block ms-1">Tokens</span>
                                        </button>
                                        <div>
                                            <button
                                                className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                                                onClick={() => editProjectServiceAccount(serviceAccount)}
                                                disabled={
                                                    !hasPermission(
                                                        Permission.PROJECT_EDITOR_ADMIN,
                                                        props.currentBillableUnit,
                                                        props.currentProject
                                                    )
                                                }
                                            >
                                                <EditIcon sx={{ fontSize: 20 }} />
                                            </button>
                                            <button
                                                className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                                                onClick={() => deleteProjectServiceAccount(serviceAccount)}
                                                disabled={
                                                    !hasPermission(
                                                        Permission.PROJECT_EDITOR_ADMIN,
                                                        props.currentBillableUnit,
                                                        props.currentProject
                                                    )
                                                }
                                            >
                                                <DeleteIcon sx={{ fontSize: 20 }} />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )
        }
        return (
            <div className="d-flex flex-column align-items-center ">
                <NotFoundContaner
                    infoText="We couldn't find any service accounts..."
                    secondaryText="Create a service account to access RemotiveCloud without personal credentials"
                />
                <button
                    onClick={() => createProjectServiceAccount()}
                    className="btn remotive-btn-md remotive-btn-primary mb-3 d-flex align-items-center"
                >
                    <AddIcon className="me-2" /> Create service account
                </button>
            </div>
        )
    }

    const createProjectServiceAccount = () => {
        setShowHelpModal(false)
        setShowCreateServiceAccountModal(true)
    }

    const editProjectServiceAccount = (projectServiceAccount: ProjectServiceAccount) => {
        setShowHelpModal(false)
        serviceAccountRef.current = projectServiceAccount
        setShowEditProjectServiceAccountModal(true)
    }

    const manageServiceAccountKeys = (projectServiceAccount: ProjectServiceAccount) => {
        setShowHelpModal(false)
        serviceAccountRef.current = projectServiceAccount
        setShowServiceAccountKeyModal(true)
    }

    const deleteProjectServiceAccount = async (projectServiceAccount: ProjectServiceAccount) => {
        setShowHelpModal(false)
        if (
            props.currentProject?.uid &&
            window.confirm(
                `Are you sure you want to delete the service account ${projectServiceAccount.name}? This can not be undone.`
            )
        ) {
            setPageState(PageState.DELETING_SERVICE_ACCOUNT)
            try {
                await CloudApi.removeProjectServiceAccount(props.currentProject.uid, projectServiceAccount)
                toast.success(`Successfully deleted the service account ${projectServiceAccount.name}`)
                setServiceAccountsState()
            } catch (err: any) {
                toast.error(`Failed to remove the service account ${projectServiceAccount.name}`)
                setPageState(PageState.DELETE_SERVICE_ACCOUNT_ERROR)
            }
        }
    }

    const userList = () => {
        if (projectUsers !== undefined) {
            return (
                <>
                    {projectUsers.map((userInfo, index) => {
                        return (
                            <div
                                key={userInfo.user.uid}
                                className="rounded remotive-primary-10-background m-1 mx-0 px-1"
                                style={index === 0 ? HIGHLIGHT_HELP_OBJECT_STYLE : {}}
                            >
                                <div className="d-flex mb-1">
                                    <div className="col-5 p-1 border-end text-start">
                                        <p className="m-0 remotive-font-md text-truncate">
                                            {userInfo.user.displayName}
                                        </p>
                                        <p className="m-0 remotive-font-sm text-secondary">
                                            {`${userInfo.roles.length} roles`}
                                        </p>
                                    </div>
                                    <div className="col-4 col-md-5 p-1 px-2 border-end text-start">
                                        <p className="m-0 remotive-font-md text-truncate">Email</p>
                                        <p className="m-0 remotive-font-sm text-secondary text-truncate">
                                            {userInfo.user.email || '-'}
                                        </p>
                                    </div>
                                    <div className="col-3 col-md-2 px-1 d-flex justify-content-center align-items-center">
                                        {index === 0 ? helpTextEditUser() : <></>}

                                        <>
                                            <button
                                                className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                                                disabled={
                                                    userInfo.user.uid === props.currentUser?.uid ||
                                                    !hasPermission(
                                                        Permission.PROJECT_EDITOR_ADMIN,
                                                        props.currentBillableUnit,
                                                        props.currentProject
                                                    )
                                                }
                                                onClick={() => editProjectUser(userInfo)}
                                            >
                                                <EditIcon sx={{ fontSize: 20 }} />
                                            </button>
                                            <button
                                                className={'btn remotive-btn-no-bg p-1 px-0 border-0'}
                                                disabled={
                                                    userInfo.user.uid === props.currentUser?.uid ||
                                                    !hasPermission(
                                                        Permission.PROJECT_EDITOR_ADMIN,
                                                        props.currentBillableUnit,
                                                        props.currentProject
                                                    )
                                                }
                                                onClick={() => removeUserFromProject(userInfo)}
                                            >
                                                <DeleteIcon sx={{ fontSize: 20 }} />
                                            </button>
                                        </>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </>
            )
        }
        return <LoadingContainer />
    }

    const serviceAccountsList = () => {
        return (
            <>
                <div className="mb-2">
                    <p className="text-start remotive-font-md m-0">{`Administrate service accounts in ${props.currentProject?.displayName}.`}</p>
                </div>
                <div className="">{serviceAccountList()}</div>
            </>
        )
    }

    const usersList = () => {
        return (
            <>
                <div className="">
                    <p className="text-start remotive-font-md">{`Administrate users and roles in ${props.currentProject?.displayName}.`}</p>
                    <div className="">{userList()}</div>
                </div>
            </>
        )
    }

    const deleteCardHeader = () => {
        return (
            <>
                <div className="text-start mb-1 d-flex align-items-center">
                    <ProjectSettingsIcon className="remotive-primary-50-color fs-6 ms-1 me-2" />
                    <p className="fs-6 m-0 lexend-light">Project</p>
                </div>
            </>
        )
    }

    const deleteButton = () => {
        const isDisabled = false // TODO Check brokers, settings and signal databases arrays here
        return (
            <>
                <div className="d-flex justify-content-center row">
                    <p className="text-start fs-6 m-1">
                        Deleting a project is irreversible. You need to manually delete all recordings and signal
                        databases and also remove all brokers before deleting a project.
                    </p>
                    <div className="m-2 d-flex justify-content-center">
                        <div>{helpTextDeleteProject()}</div>
                        <button
                            style={HIGHLIGHT_HELP_OBJECT_STYLE}
                            className="btn btn-danger remotive-btn-md align-top"
                            onClick={() => deleteProject()}
                            disabled={isDisabled}
                        >
                            <DeleteIcon className="d-inline align-center" sx={{ fontSize: 20 }} />
                            <p className="m-0 d-inline"> Delete</p>
                        </button>
                    </div>
                </div>
            </>
        )
    }

    const helpTextAddServiceAccount = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginLeft: -280 }}
                >
                    <div className="d-flex">
                        <p className="text-light text-start m-0">
                            Click to add a service account <br />
                            to the currently active project
                        </p>
                        <ArrowEastIcon sx={{ fontSize: 30 }} className="ms-3" />
                    </div>
                </div>
            </>
        )
    }

    const helpTextAddUser = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: -1, marginLeft: -300 }}
                >
                    <div className="d-flex">
                        <p className="text-light text-start m-0">
                            Click to add an organisation user <br />
                            to the currently active project
                        </p>
                        <ArrowEastIcon sx={{ fontSize: 30 }} className="ms-3" />
                    </div>
                </div>
            </>
        )
    }

    const helpTextDeleteProject = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginLeft: -100, marginTop: -65 }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <p className="text-light text-start m-0">Use this button to delete the entire project</p>
                        <ArrowSouthIcon sx={{ fontSize: 30 }} className="mb-3" />
                    </div>
                </div>
            </>
        )
    }

    const helpTextEditUser = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex align-items-end flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: 160, marginLeft: -110 }}
                >
                    <div className="d-flex flex-column align-items-end">
                        <ArrowNorthIcon sx={{ fontSize: 30 }} className="mb-3" />
                        <p className="text-light text-start m-0">
                            Use these buttons to <br />
                            edit or delete a user
                        </p>
                    </div>
                </div>
            </>
        )
    }

    const helpTextEditServiceAccount = () => {
        return (
            <>
                <div
                    className={`lexend-bold remotive-font-md text-light position-absolute d-flex align-items-end flex-column d-${
                        showHelpModal ? 'block' : 'none'
                    }`}
                    style={{ zIndex: '9999', marginTop: 140, marginLeft: -80 }}
                >
                    <div className="d-flex flex-column align-items-center">
                        <ArrowNorthIcon sx={{ fontSize: 30 }} className="mb-3" />
                        <p className="text-light text-start m-0">Use this button to manage tokens</p>
                    </div>
                </div>
            </>
        )
    }

    const deleteBody = () => {
        switch (pageState) {
            case PageState.LOADING:
            case PageState.LOADING_USERS:
                return <LoadingContainer />

            case PageState.ERROR:
                return <ErrorContainer />

            case PageState.DONE:
                return deleteButton()

            case PageState.DELETING_PROJECT:
                return (
                    <>
                        <div className="text-center h-100 mt-5 mb-5">
                            <p className={`fs-5 m-1`}>Deleting project...</p>
                            <Spinner className={`remotive-primary-50-color`} size="sm" />
                        </div>
                    </>
                )

            case PageState.DELETE_PROJECT_ERROR:
                return <ErrorContainer />

            default:
                return deleteButton()
        }
    }

    const serviceAccountsBody = () => {
        switch (pageState) {
            case PageState.LOADING:
            case PageState.LOADING_ACCOUNTS:
                return <LoadingContainer />

            case PageState.ERROR:
                return <ErrorContainer errorText="We couldn't fetch any service accounts..." />

            case PageState.DONE:
                return serviceAccountsList()

            case PageState.DELETING_SERVICE_ACCOUNT:
                return (
                    <>
                        <div className="text-center h-100 mt-5 mb-5">
                            <p className={`fs-5 m-1`}>Deleting service account...</p>
                            <Spinner className={`remotive-primary-50-color`} size="sm" />
                        </div>
                    </>
                )

            case PageState.DELETE_SERVICE_ACCOUNT_ERROR:
                return <ErrorContainer errorText="We couldn't delete the service account..." />

            default:
                return serviceAccountsList()
        }
    }

    const usersBody = () => {
        switch (pageState) {
            case PageState.LOADING:
                return <LoadingContainer />

            case PageState.ERROR:
                return <ErrorContainer errorText="We couldn't fetch any users..." />

            case PageState.DONE:
                return usersList()

            case PageState.DELETING_USER:
                return (
                    <>
                        <div className="text-center h-100 mt-5 mb-5">
                            <p className={`fs-5 m-1`}>Deleting user...</p>
                            <Spinner className={`remotive-primary-50-color`} size="sm" />
                        </div>
                    </>
                )

            case PageState.DELETE_USER_ERROR:
                return <ErrorContainer errorText="We couldn't delete the project..." />

            default:
                return userList()
        }
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex align-items-center">
                    <ProjectSettingsIcon className="remotive-primary-50-color fs-3 me-1" />
                    <p className="fs-3 lexend-light mb-0 text-truncate">Manage project</p>
                </div>
            </>
        )
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
            ],
            current: {
                title: 'Manage Project',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                isDemoGuideActive={false}
            />
            <Container fluid className="mt-5 pb-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>
                <div className="mt-2">
                    <div>{usersCardHeader()}</div>
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-4">
                        <Card.Body className="pb-2 pt-2">{usersBody()}</Card.Body>
                    </Card>

                    <div>{serviceAccountsCardHeader()}</div>
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-4">
                        <Card.Body className="pb-2 pt-2">{serviceAccountsBody()}</Card.Body>
                    </Card>

                    <div>{deleteCardHeader()}</div>
                    <Card className="shadow-sm rounded-4 border-0 text-start mb-4">
                        <Card.Body className="pb-2 pt-2">{deleteBody()}</Card.Body>
                    </Card>
                </div>
                <CliHintContainer
                    hints={[
                        {
                            title: 'Delete project',
                            command: `remotive cloud projects delete --project ${props.currentProject?.uid}`,
                        },
                    ]}
                />
            </Container>

            {/* Modals */}
            <CreateProjectUserModal
                show={showCreateProjectUserModal}
                handleCloseFunction={() => setShowCreateProjectUserModal(false)}
                reloadUsersList={() => setUserState()}
                currentBuUsers={buUsers || []}
                currentProjectUsers={projectUsers || []}
                currentProject={props.currentProject}
                currentBillableUnit={props.currentBillableUnit}
            />
            <CreateProjectServiceAccountModal
                show={showCreateServiceAccountModal}
                handleCloseFunction={() => setShowCreateServiceAccountModal(false)}
                reloadServiceAccountsList={() => setServiceAccountsState()}
                currentProject={props.currentProject}
            />
            <EditProjectUserModal
                show={showEditProjectUserModal}
                handleCloseFunction={() => setShowEditProjectUserModal(false)}
                reloadUsersList={() => setUserState()}
                currentProject={props.currentProject}
                selectedProjectUser={selectedProjectUser}
            />
            <EditProjectServiceAccountModal
                show={showEditProjectServiceAccountModal}
                handleCloseFunction={() => setShowEditProjectServiceAccountModal(false)}
                reloadServiceAccountsList={() => setServiceAccountsState()}
                currentProject={props.currentProject}
                currentAccount={serviceAccountRef.current}
            />
            <ManageAccessTokenModal
                currentProject={props.currentProject}
                currentServiceAccount={serviceAccountRef.current}
                show={showServiceAccountKeyModal}
                handleCloseFunction={() => setShowServiceAccountKeyModal(false)}
            />
            <HelpOverlayModalModal handleCloseFunction={() => setShowHelpModal(false)} show={showHelpModal} />
        </div>
    )
}
