import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Modal, Form, InputGroup, Spinner } from 'react-bootstrap'
import CloudApi from '../../api/CloudApi'
import { CreateProjectServiceAccountDTO, Project, ProjectServiceAccount } from '../../api/CloudApi/types'
import { ErrorIcon } from '../../assets/Icons'

interface EditProjectServiceAccountModalProps {
    currentProject: Project | undefined
    currentAccount: ProjectServiceAccount | undefined
    reloadServiceAccountsList: () => void
    show: boolean
    handleCloseFunction: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

enum Role {
    OWNER = 'project/admin',
    EDITOR = 'project/user',
    VIEWER = 'project/viewer',
}

export default function EditProjectServiceAccountModal(props: EditProjectServiceAccountModalProps) {
    const [selectedRole, setSelectedRole] = useState<'project/admin' | 'project/user' | 'project/viewer'>(Role.VIEWER)
    const [accountEditState, setAccountEditState] = useState<'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR'>(
        'NOT_STARTED'
    )

    useEffect(() => {
        console.debug('Mounted edit service account modal!')
    }, [])

    useEffect(() => {
        const roles = props.currentAccount?.roles
        if (roles) {
            setSelectedRole((roles[0] as Role) ?? Role.VIEWER)
        }
    }, [props.currentAccount])

    useEffect(() => {
        if (accountEditState === 'DONE') {
            closeModal()
            props.reloadServiceAccountsList()
        }
    }, [accountEditState])

    const validateInput = (): boolean => {
        if (selectedRole === undefined) {
            toast.error('You must select a role')
            return false
        }
        return true
    }

    const resetState = () => {
        setAccountEditState('NOT_STARTED')
    }

    const editServiceAccount = async () => {
        const projectUid = props.currentProject?.uid
        const accountName = props.currentAccount?.name
        if (validateInput() && selectedRole && projectUid && accountName) {
            setAccountEditState('IN_PROGRESS')
            const dto = { roles: [selectedRole] } as CreateProjectServiceAccountDTO
            try {
                await CloudApi.editProjectServiceAccount(projectUid, dto, accountName)
                toast.success(`Edited the service account ${accountName}`)
                setAccountEditState('DONE')
            } catch (err) {
                setAccountEditState('ERROR')
                toast.error('Failed to edit service account')
            }
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    const rolesList = () => {
        return (
            <>
                <div>
                    <Form.Check
                        type="radio"
                        checked={selectedRole === Role.OWNER}
                        className="remotive-font-md"
                        label="Owner"
                        onChange={() => setSelectedRole(Role.OWNER)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2"> Full project access and can delete project</p>
                </div>
                <div>
                    <Form.Check
                        type="radio"
                        className="remotive-font-md"
                        checked={selectedRole === Role.EDITOR}
                        label="Editor"
                        onChange={() => setSelectedRole(Role.EDITOR)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">Full project access</p>
                </div>
                <div>
                    <Form.Check
                        type="radio"
                        className="remotive-font-md"
                        checked={selectedRole === Role.VIEWER}
                        label="Viewer"
                        onChange={() => setSelectedRole(Role.VIEWER)}
                        style={{ marginBottom: '-4px' }}
                    ></Form.Check>
                    <p className="remotive-font-sm text-secondary mb-2">
                        Can start/stop brokers, view all resources but not edit or delete any resources
                    </p>
                </div>
            </>
        )
    }

    const getModalContent = () => {
        const title = 'Edit service account'
        switch (accountEditState) {
            case 'IN_PROGRESS':
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Editing service account...</p>
                                <Spinner className="remotive-primary-50-color" size="sm" />
                            </div>
                        </Modal.Body>
                    </>
                )

            case 'ERROR':
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-3 mt-3 text-center">
                                <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                                <p className="fs-5 m-0">There was an issue editing the service account...</p>
                                <p className="remotive-font-sm text-secondary" style={{ marginTop: '-5px' }}>
                                    Please try again later
                                </p>
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-4">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        Name
                                    </InputGroup.Text>
                                    <Form.Control
                                        disabled
                                        className="rounded border-light-gray"
                                        value={props.currentAccount?.name}
                                    />
                                </InputGroup>
                            </div>
                            <div className="mb-2">
                                <InputGroup size="sm">
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        <p className="h-100">Role</p>
                                    </InputGroup.Text>
                                    <div className="w-75">{rolesList()}</div>
                                </InputGroup>
                            </div>
                            <div className="d-flex justify-content-end">
                                <button
                                    className="btn remotive-btn remotive-btn-success align-center"
                                    onClick={() => editServiceAccount()}
                                >
                                    <div className="d-flex align-items-center text-light">
                                        <p className="text-light m-0">Save</p>
                                    </div>
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
