import { useEffect, useRef } from 'react'
import { useState } from 'react'
import { Formik } from 'formik'
import { object, string } from 'yup'
import { Modal, Form, InputGroup, Row, Button } from 'react-bootstrap'
import { BillableUnit, CreateBuRequest } from '../../api/CloudApi/types'
import ErrorContainer from '../ErrorContainer'
import LoadingContainer from '../LoadingContainer'
import CloudApi from '../../api/CloudApi'
import { toast } from 'react-toastify'

interface AddBuModalProps {
    show: boolean
    handleCloseFunction: () => void
}

const MODAL_THEME_BACKGROUND = 'remotive-primary-70-background'
const MODAL_THEME_COLOR = 'text-light'

const NOT_STARTED_STATE = 'NOT_STARTED'
const IN_PROGRESS_STATE = 'IN_PROGRESS'
const DONE_STATE = 'DONE'
const ERROR_STATE = 'ERROR'
type RequestState = 'NOT_STARTED' | 'IN_PROGRESS' | 'DONE' | 'ERROR'

export default function AddBuModal(props: AddBuModalProps) {
    const buNameRef = useRef<string | undefined>(undefined)
    const buIdRef = useRef<string | undefined>(undefined)
    const [requestState, setRequestState] = useState<RequestState>(NOT_STARTED_STATE)
    const [knownErrorMessage, setKnownErrorMessage] = useState<string>()

    useEffect(() => {
        console.debug('Mounted add bu modal!')
    }, [])

    useEffect(() => {
        if (requestState === DONE_STATE && buIdRef.current !== undefined && !knownErrorMessage) {
            // Go to new bu dashboard
            window.location.replace(CloudApi.getOrganisationHomeUrlFromUid(buIdRef.current))
        }
    }, [requestState])

    const resetState = () => {
        setRequestState(NOT_STARTED_STATE)
        setKnownErrorMessage(undefined)
        buNameRef.current = undefined
        buIdRef.current = undefined
    }

    const addBu = async () => {
        setRequestState(IN_PROGRESS_STATE)
        setKnownErrorMessage(undefined)
        try {
            const createBuRequest = {
                displayName: buNameRef.current,
            } as CreateBuRequest
            console.log(createBuRequest)
            const response = await CloudApi.createBillableUnit(createBuRequest)
            console.log(response.data)
            buIdRef.current = response.data.uid
            setRequestState(DONE_STATE)
        } catch (err: any) {
            console.log(err)
            toast.error('Something went wrong, please try again')
            setRequestState(ERROR_STATE)
        }
    }

    const closeModal = () => {
        props.handleCloseFunction()
        resetState()
    }

    const getForm = () => {
        const schema = object().shape({
            buName: string()
                .required('A name is required')
                .max(50, 'Name is too long!')
                .min(3, 'Name should be at least 3 characters'),
        })

        return (
            <Formik
                // After an error this is used to fake that these values has already been "touched"
                initialTouched={{
                    buName: buNameRef.current !== undefined,
                }}
                validationSchema={schema}
                onSubmit={(event: any) => {
                    buNameRef.current = event.buName
                    addBu()
                }}
                initialValues={{
                    buName: buNameRef.current ? buNameRef.current : '',
                }}
            >
                {({ handleSubmit, handleChange, handleBlur, values, touched, isValid, errors }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                        <Row className={'mb-3'}>
                            <Form.Group as={Row}>
                                <InputGroup size="sm" hasValidation={true}>
                                    <InputGroup.Text className={`remotive-font-md bg-transparent border-0 w-25`}>
                                        Name
                                    </InputGroup.Text>
                                    <Form.Control
                                        placeholder={'Organisation name...'}
                                        type="text"
                                        className="remotive-font-sm w-75"
                                        onBlur={handleBlur}
                                        name={'buName'}
                                        value={values.buName}
                                        onChange={handleChange}
                                        isValid={(!errors.buName as boolean) && (touched.buName as boolean)}
                                        isInvalid={errors.buName !== undefined}
                                    />
                                    <div className="w-25"></div>
                                    <Form.Control.Feedback className="w-75" type="invalid">
                                        <p className="remotive-font-xs text-secondary m-0 p-1 w-75">
                                            <>{errors.buName}</>
                                        </p>
                                    </Form.Control.Feedback>
                                </InputGroup>
                            </Form.Group>
                        </Row>
                        <div className="d-flex justify-content-end">
                            <Button
                                className="btn remotive-btn remotive-btn remotive-btn-success"
                                disabled={values.buName === '' || !isValid}
                                type={'submit'}
                            >
                                <div className="d-flex align-items-center text-light">
                                    <p className="text-light m-0">Add</p>
                                </div>
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        )
    }

    const getModalContent = () => {
        const title = 'Add organisation'
        switch (requestState) {
            case IN_PROGRESS_STATE:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <LoadingContainer loadingText="Creating organisation..." />
                        </Modal.Body>
                    </>
                )

            case ERROR_STATE:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="mb-5">
                                <ErrorContainer errorText="There was an issue creating your organisation..." />
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn remotive-btn remotive-btn-primary" onClick={() => closeModal()}>
                                    Close
                                </button>
                            </div>
                        </Modal.Body>
                    </>
                )

            case DONE_STATE:
                return (
                    <>
                        <Modal.Header className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR}`}>
                            <Modal.Title className="lexend-regular">{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">
                            <div className="text-center h-100 mt-3 mb-3">
                                <p className="fs-5 m-1">Done!</p>
                            </div>
                        </Modal.Body>
                    </>
                )

            default:
                return (
                    <>
                        <Modal.Header
                            closeButton
                            closeVariant="white"
                            className={`${MODAL_THEME_BACKGROUND} ${MODAL_THEME_COLOR} lexend-regular`}
                        >
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body className="lexend-regular">{getForm()}</Modal.Body>
                    </>
                )
        }
    }

    return (
        <>
            <Modal show={props.show} onHide={() => closeModal()}>
                {getModalContent()}
            </Modal>
        </>
    )
}
