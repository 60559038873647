import { AuthenticatedUser } from '../api/CloudApi/types'
import { useEffect, useState } from 'react'
import CloudApi from '../api/CloudApi'
import { PageDetails } from '../utils/pageDetails'

interface AdminProperties {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    currentUser: AuthenticatedUser | undefined
}

export default function Admin(props: AdminProperties) {
    const [status, setStatus] = useState('Not started')

    useEffect(() => {
        props.updateCurrentPageDetails({
            documentTitle: 'Admin Panel',
            productAnalyticsEventName: 'Admin Panel',
        })
    }, [])

    const redirectToLogin = () => {
        window.location.replace('/')
    }

    if (!props.currentUser) {
        return <p>Loading...</p>
    }

    if (!props.currentUser?.email.endsWith('remotivelabs.com')) {
        redirectToLogin()
    }

    const migrate = () => {
        setStatus('Migration in progress.. Do not close this window')
        CloudApi.migrate()
            .then((res) => setStatus('Migration completed'))
            .catch((e: any) => setStatus(e.response.message))
    }

    return (
        <div>
            {props.currentUser?.email.endsWith('remotivelabs.com') && (
                <div>
                    <button onClick={migrate}>Migrate licenses from GCS</button>
                    <br />
                    <br />
                    {status}
                </div>
            )}
        </div>
    )
}
