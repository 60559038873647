import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { AuthenticatedUser, Project, UserBillableUnitInfo } from '../../api/CloudApi/types'
import { ProfileIcon } from '../../assets/Icons'

interface UserDetailsModalProps {
    currentBillableUnit: UserBillableUnitInfo | undefined
    currentProject: Project | undefined
    currentUser: AuthenticatedUser | undefined
    show: boolean
    handleCloseFunction: () => void
}

export default function UserDetailsModal(props: UserDetailsModalProps) {
    useEffect(() => {
        console.debug('Mounted user details modal!')
    }, [])

    const closeModal = () => {
        props.handleCloseFunction()
    }

    const profilePicture = () => {
        const size = 96
        return props.currentUser?.picture ? (
            <>
                <img
                    width={size}
                    height={size}
                    className="rounded-circle me-1 mb-0"
                    src={props.currentUser.picture}
                ></img>
            </>
        ) : (
            <>
                <ProfileIcon className="me-1" sx={{ fontSize: size }} />
            </>
        )
    }

    const userDetails = () => {
        return (
            <>
                <p className="m-0">
                    <b>
                        {props.currentUser?.firstName} {props.currentUser?.lastName}
                    </b>
                </p>
                <p>{props.currentUser?.email}</p>
            </>
        )
    }

    const actingAsInfo = () => {
        return (
            <>
                <p className="m-0 remotive-font-sm">
                    You are currently in the project <b>{props.currentProject?.displayName}</b> in the organisation{' '}
                    <b>{props.currentBillableUnit?.organisation.displayName}</b>.
                </p>
            </>
        )
    }

    return (
        <>
            <Modal className="m-0 p-0 border-0" show={props.show} onHide={() => closeModal()}>
                <Modal.Header className="border-0" closeButton></Modal.Header>
                <Modal.Body className="text-center lexend-regular p-3 mt-2 mb-2 pt-3">
                    <>
                        <div className="mb-3">{profilePicture()}</div>
                        <div className="mt-4">{userDetails()}</div>
                        <div>{actingAsInfo()}</div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}
