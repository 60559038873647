import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'

interface BookMeetingModalProps {
    show: boolean
    handleCloseFunction: Function
}

export default function BookMeetingModal(props: BookMeetingModalProps) {
    useEffect(() => {
        console.debug('Mounted book meeting modal!')
    }, [])

    const hubspotMeetingIframe = () => {
        return (
            <>
                <iframe
                    title={'hubspot'}
                    style={{
                        width: '100%',
                        height: '700px',
                    }}
                    src={'https://meetings-eu1.hubspot.com/per-sigurdson/remotivelabs-platform-intro?embed=true'}
                />
            </>
        )
    }

    return (
        <Modal
            className="m-0 p-0"
            size="lg"
            contentClassName="no-bg-modal"
            show={props.show}
            onHide={() => props.handleCloseFunction()}
        >
            <Modal.Header className="border-0" closeButton closeVariant="white" />
            {hubspotMeetingIframe()}
        </Modal>
    )
}
