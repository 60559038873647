import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import VideoPlayer from './VideoPlayer'
import { MediaFile } from '../api/CloudApi/types'

interface ViewFileModalProps {
    onVideoLoadedCallback: Function
    fileToView: MediaFile | undefined
    show: boolean
    handleCloseFunction: () => void
}

export default function ViewFileModal(props: ViewFileModalProps) {
    useEffect(() => {
        console.debug('Mounted view file modal!')
        return () => {
            console.debug('Unmounted view file modal!')
        }
    }, [])

    const closeModal = () => {
        props.handleCloseFunction()
    }

    return (
        <>
            <Modal
                style={{ backgroundColor: '#0000 !important' }}
                className="m-0 p-0"
                size="xl"
                show={props.show}
                onHide={() => closeModal()}
            >
                <Modal.Body className="p-0 m-0">
                    <VideoPlayer
                        onVideoLoadedCallback={props.onVideoLoadedCallback}
                        videoSource={props.fileToView?.downloadUrl || 'Error'}
                        videoSourceType={'video/mp4'}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}
