import { AuthenticatedUser } from '../api/CloudApi/types'

interface Toggle {
    isAnalyticsFeatureEnabled(): boolean
}

export function useFeatureToggle(user?: AuthenticatedUser): Toggle {
    const allowedAnalysisEmailSuffixes = ['@remotivelabs.com', '@ford.com', '@capgemini.com']

    const isAnalyticsFeatureEnabled = () => {
        if (user) {
            const suffix = user.email.substring(user.email.lastIndexOf('@'), user.email.length)
            return allowedAnalysisEmailSuffixes.includes(suffix)
        }
        return false
    }
    return {
        isAnalyticsFeatureEnabled,
    }
}
