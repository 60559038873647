import { useEffect, useState } from 'react'
import { Container, Tab, Tabs, Form, Card } from 'react-bootstrap'
import { useParams, useSearchParams } from 'react-router-dom'
import CloudApi from '../../api/CloudApi'
import {
    AuthenticatedUser,
    BrokerContainer,
    Project,
    RecordingFile,
    RecordingSession,
    UserBillableUnitInfo,
} from '../../api/CloudApi/types'
import { CrossIcon, EditIcon, RecordingsIcon, SaveIcon, ArrowNorthIcon, ArrowSouthIcon } from '../../assets/Icons'
import ErrorContainer from '../../components/ErrorContainer'
import LoadingContainer from '../../components/LoadingContainer'
import NavigationBar from '../../components/navigation/NavigationBar'
import { toast } from 'react-toastify'
import LoadingOverlayModal from '../../components/modals/LoadingOverlayModal'
import HelpOverlayModalModal from '../../components/modals/HelpOverlayModal'
import HelpTextBox from '../../components/cards/DemoHelpCard'
import { isDemo, isGuidedDemo, setGuidedDemo } from '../../utils/demo'
import { hasPermission, Permission } from '../../utils/permission'
import { OrganisationRoutes, ProjectRoutes } from '../../Routes'
import { useFeatureToggle } from '../../utils/FeatureToggle'
import PlaybackTab from './PlaybackTab'
import { BreadCrumb, BreadCrumbs } from '../../types/BreadCrumbs'
import { PageState } from '../../types/PageState'
import { AnalyticsTab } from './Analytics/AnalyticsTab'
import { NotesTab } from './NotesTab'
import { FilesTab } from './FilesTab'
import { enumFromStringValue } from '../../utils/enumFromStringValue'
import CliHintContainer, { CliHint } from '../../components/CliHintContainer'
import { storeRecentRecording } from '../../types/RecentRecording'
import { PageDetails } from '../../utils/pageDetails'
import { TAB_PARAM } from '../../utils/queryParams'

interface RecordingDetailsProps {
    updateCurrentPageDetails: (pageDetails: PageDetails) => void
    billableUnits: Array<UserBillableUnitInfo>
    currentBillableUnit: UserBillableUnitInfo | undefined
    projects: Array<Project>
    currentProject: Project | undefined
    currentUser: AuthenticatedUser | undefined
}

enum TabEnum {
    PLAYBACK = 'playback',
    ANALYZE = 'analyze',
    FILES = 'files',
    NOTES = 'notes',
}

export default function RecordingDetails(props: RecordingDetailsProps) {
    const { recordingSessionId } = useParams()
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedRecordingFiles, setSelectedRecordingFiles] = useState<Array<RecordingFile>>()
    const [pageState, setPageState] = useState<PageState>(PageState.LOADING)
    const [selectedTab, setSelectedTab] = useState<TabEnum>(
        enumFromStringValue(TabEnum, searchParams.get(TAB_PARAM) ?? '') || TabEnum.PLAYBACK
    )
    const [currentRecording, setCurrentRecording] = useState<RecordingSession>()
    const [brokers, setBrokers] = useState<Array<BrokerContainer>>()

    const [isEditTitleActive, setIsEditTitleActive] = useState<boolean>(false)

    const [editedName, setEditedName] = useState<string>()

    const [showHelpModal, setShowHelpModal] = useState(false)
    const [showDemoModal, setShowDemoModal] = useState(isGuidedDemo())
    const [uploadPercent, setUploadPercent] = useState(0)
    const featureToggle = useFeatureToggle(props.currentUser)

    const HIGHLIGHT_HELP_OBJECT_STYLE = showHelpModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}
    const HIGHLIGHT_DEMO_OBJECT_STYLE = showDemoModal ? { zIndex: 9999, position: 'relative' as 'relative' } : {}

    useEffect(() => {
        if (currentRecording?.displayName) {
            props.updateCurrentPageDetails({
                documentTitle: `Recording Details - ${currentRecording?.displayName}`,
                productAnalyticsEventName: 'Recording Details',
            })
        }
    }, [currentRecording])

    useEffect(() => {
        console.debug('Mounted recording session page!')
        setGuidedDemo(false) // Demo is finished, reset to default state
    }, [])

    useEffect(() => {
        if (props.currentProject) {
            setPageState(PageState.LOADING)
            setRecordingSession()
            setBrokerState()
        }
    }, [props.currentProject])

    useEffect(() => {
        if (
            props.currentProject !== undefined &&
            props.currentBillableUnit !== undefined &&
            currentRecording !== undefined
        ) {
            storeRecentRecording(currentRecording, props.currentProject, props.currentBillableUnit)
        }
    }, [props.currentProject, props.currentBillableUnit, currentRecording])

    useEffect(() => {
        if (selectedTab !== undefined && searchParams !== undefined) {
            searchParams.set(TAB_PARAM, selectedTab)
            setSearchParams(searchParams)
        }
    }, [selectedTab])

    const setBrokerState = async () => {
        const projectUid = props.currentProject?.uid
        if (projectUid && recordingSessionId !== undefined) {
            try {
                const brokers = await CloudApi.listNonPersonalBrokers(projectUid, props.currentUser!!)
                setBrokers(brokers)
                setPageState(PageState.DONE)
            } catch (err) {
                setPageState(PageState.ERROR)
            }
        }
    }

    const setRecordingSession = async () => {
        const projectUid = props.currentProject?.uid
        if (projectUid !== undefined && recordingSessionId !== undefined) {
            try {
                const recordingSession = (await CloudApi.getRecordingSession(projectUid, recordingSessionId)).data
                setCurrentRecording(recordingSession)
                setEditedName(recordingSession.displayName)
                setSelectedRecordingFiles(recordingSession.recordings || [])
                if (recordingSession.recordings.length > 0) {
                    setPageState(PageState.DONE)
                } else {
                    // If there are no recording files, this recording session shouldn't be viewable. Force user to go back to recordings list
                    window.location.replace(`/p/${props.currentProject?.uid}/recordings`)
                }
            } catch (err) {
                setPageState(PageState.ERROR)
            }
        }
    }

    const editTitle = () => {
        setShowHelpModal(false)
        setShowDemoModal(false)
        setIsEditTitleActive(true)
    }

    const saveEditedTitle = async (newName: string) => {
        const projectUid = props.currentProject?.uid
        const description = currentRecording?.description
        if (projectUid && recordingSessionId !== undefined && description !== undefined) {
            try {
                setPageState(PageState.SAVING)
                await CloudApi.editRecordingSession(projectUid, recordingSessionId, newName, description)
                setIsEditTitleActive(false)
                toast.success(`Successfully updated recording title`)
                setRecordingSession() // Fetch recording session again with the updated details
            } catch (err) {
                toast.error(`Failed to update recording title, please try again`)
                setPageState(PageState.DONE)
            }
        }
    }

    const titleText = () => {
        if (!isEditTitleActive) {
            return (
                <div className="d-flex align-items-center">
                    <RecordingsIcon className="remotive-primary-50-color fs-3 me-1" />
                    <p className="fs-3 lexend-light mb-0 text-truncate">
                        {currentRecording?.displayName || 'Loading...'}{' '}
                    </p>
                </div>
            )
        } else {
            return (
                <Form.Control
                    className="remotive-font-sm w-50"
                    type="text"
                    value={editedName}
                    onChange={(event) => setEditedName(event.target.value)}
                />
            )
        }
    }

    const editTitleButton = () => {
        if (isEditTitleActive) {
            return (
                <>
                    <button
                        className="p-0 m-0 bg-transparent border-0 mx-2"
                        onClick={() => {
                            setIsEditTitleActive(false)
                            setEditedName(currentRecording?.displayName || 'Unnamed')
                        }}
                    >
                        <CrossIcon className="align-top" sx={{ fontSize: 24 }} />
                    </button>
                    <button
                        className="p-0 m-0 bg-transparent border-0"
                        onClick={() => {
                            saveEditedTitle(editedName || currentRecording?.displayName || 'Unnamed')
                        }}
                    >
                        <SaveIcon className="align-top" sx={{ fontSize: 23 }} />
                    </button>
                </>
            )
        } else {
            return (
                <button
                    className="p-0 m-0 bg-transparent border-0 mx-2"
                    onClick={() => editTitle()}
                    disabled={
                        !hasPermission(
                            Permission.PROJECT_EDITOR_RECORDING,
                            props.currentBillableUnit,
                            props.currentProject
                        )
                    }
                >
                    <EditIcon className="align-top" sx={{ fontSize: 17 }} />
                </button>
            )
        }
    }

    const pageHeader = () => {
        return (
            <>
                <div className="text-start d-flex text-truncate">
                    {titleText()}
                    {editTitleButton()}
                </div>
            </>
        )
    }

    const helpTextDescriptionTab = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    showHelpModal ? 'block' : 'none'
                }`}
                style={{ zIndex: '9999', marginTop: 60, marginLeft: 40 }}
            >
                <ArrowNorthIcon sx={{ fontSize: 30 }} className="mb-2" />
                <p>
                    Add notes and useful information <br />
                    related to this recording here
                </p>
            </div>
        )
    }

    const getTabDetails = (tab: TabEnum) => {
        switch (tab) {
            case TabEnum.FILES:
                return {
                    title: 'Files',
                    tooltip: 'Manage related files',
                }

            case TabEnum.NOTES:
                return {
                    title: 'Notes',
                    tooltip: 'Write and share information',
                }

            case TabEnum.ANALYZE:
                return {
                    title: 'Analyze',
                    tooltip: 'Search and visualize signals',
                }

            default:
            case TabEnum.PLAYBACK:
                return {
                    title: 'Playback',
                    tooltip: 'Replay signals in real-time',
                }
        }
    }

    const tabItem = (tab: TabEnum) => {
        const tabDetails = getTabDetails(tab)
        return (
            <div
                style={{ marginBottom: -15 }}
                title={tabDetails.tooltip}
                className={`${selectedTab === tab ? 'bg-white shadow' : ''} rounded-top-4 px-2 pt-2 pb-4`}
            >
                <button onClick={() => setSelectedTab(tab)} className={`btn remotive-btn-tab border-0 pb-0`}>
                    <p
                        style={{ minWidth: 80 }}
                        className={`d-none d-md-block m-0 remotive-font ${
                            selectedTab === tab ? 'lexend-bold remotive-primary-100-color' : ''
                        }`}
                    >
                        {tabDetails.title}
                    </p>
                    <p
                        className={`d-md-none m-0 remotive-font-sm ${
                            selectedTab === tab ? 'lexend-bold remotive-primary-100-color' : ''
                        }`}
                    >
                        {tabDetails.title}
                    </p>
                </button>
            </div>
        )
    }

    const helpTextFilesTab = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    showHelpModal ? 'block' : 'none'
                }`}
                style={{ ...HIGHLIGHT_HELP_OBJECT_STYLE, marginTop: 20, marginLeft: 50 }}
            >
                <ArrowNorthIcon sx={{ fontSize: 30 }} className="mb-2" />
                <p className="text-light m-0">
                    All files connected to this recording <br />
                    including related recording, media <br />
                    and configuration files
                </p>
            </div>
        )
    }

    const helpTextViewTab = () => {
        return (
            <div
                className={`lexend-bold remotive-font-md text-light position-absolute d-flex flex-column d-${
                    showHelpModal ? 'block' : 'none'
                }`}
                style={{ ...HIGHLIGHT_HELP_OBJECT_STYLE, marginTop: -100, marginLeft: 50 }}
            >
                <p className="text-light m-0">Search signals and visualize them with graphs</p>
                <ArrowSouthIcon sx={{ fontSize: 30 }} className="mb-2" />
            </div>
        )
    }

    const tabs = () => {
        return (
            <>
                {helpTextDescriptionTab()}
                <div className={`mb-0 border-0 d-flex flex-column`}>
                    <div className="d-flex flex-row">
                        {tabItem(TabEnum.PLAYBACK)}
                        {featureToggle.isAnalyticsFeatureEnabled() && (
                            <div>
                                {tabItem(TabEnum.ANALYZE)}
                                {helpTextViewTab()}
                            </div>
                        )}
                        <div>
                            {tabItem(TabEnum.FILES)}
                            {helpTextFilesTab()}
                        </div>
                        {tabItem(TabEnum.NOTES)}
                    </div>
                </div>
            </>
        )
    }

    const demoMessage = () => {
        return (
            <div className="mt-4">
                <HelpTextBox
                    title={
                        <div className="text-start">
                            <p className="remotive-font-md mb-1">
                                Here is a recorded drivecycle from Malmö, Sweden that we use for demo purposes. You are
                                now ready to play this recorded drivecycle on a broker.
                                <b>
                                    {' '}
                                    Simply press the "play" button below, this will start the recording and ask you to
                                    go to the broker application. See you there!
                                </b>
                            </p>
                        </div>
                    }
                    bodyContent={
                        <div className="mt-1">
                            <p className={`text-start remotive-font-md m-0`}>
                                1. Take a look at the card below and see what is configurable before playing <br />
                                2. Press the play button below the configuration card to <b>play</b> this recording on a
                                broker
                            </p>
                        </div>
                    }
                />
            </div>
        )
    }

    const playbackTab = (show: boolean) => {
        return (
            <div className={show ? '' : 'd-none'}>
                <PlaybackTab
                    currentUser={props.currentUser}
                    currentRecording={currentRecording}
                    currentProject={props.currentProject}
                    selectedRecordingFiles={selectedRecordingFiles}
                    setSelectedRecordingFiles={setSelectedRecordingFiles}
                    showDemoModal={showDemoModal}
                    setShowDemoModal={setShowDemoModal}
                    showHelpModal={showHelpModal}
                    setShowHelpModal={setShowHelpModal}
                    brokers={brokers}
                    refreshBrokers={() => setBrokerState()}
                />
            </div>
        )
    }

    const analyticsTab = (show: boolean) => {
        if (!featureToggle.isAnalyticsFeatureEnabled()) {
            return <></>
        }
        return (
            <div className={show ? 'pb-5' : 'd-none'}>
                <AnalyticsTab
                    currentRecording={currentRecording}
                    currentProject={props.currentProject}
                    currentBillableUnit={props.currentBillableUnit}
                />
            </div>
        )
    }

    const pageBody = () => {
        switch (pageState) {
            case PageState.ERROR:
                return (
                    <Card className="border-0 rounded-4 bg-white">
                        <ErrorContainer errorText="We couldn't fetch your recording session details..." />
                    </Card>
                )

            case PageState.DELETING:
            case PageState.UPLOADING:
            case PageState.LOADING_VIDEO:
            case PageState.SAVING:
            case PageState.DONE:
                switch (selectedTab) {
                    default:
                    case TabEnum.PLAYBACK:
                        return (
                            <>
                                {playbackTab(true)}
                                {analyticsTab(false)}
                            </>
                        )

                    case TabEnum.ANALYZE:
                        return (
                            <>
                                {playbackTab(false)}
                                {analyticsTab(true)}
                            </>
                        )

                    case TabEnum.FILES:
                        return (
                            <>
                                {playbackTab(false)}
                                {analyticsTab(false)}
                                <div className="pb-5">
                                    <FilesTab
                                        currentRecording={currentRecording}
                                        currentProject={props.currentProject}
                                        currentBillableUnit={props.currentBillableUnit}
                                        setPageState={setPageState}
                                        setRecordingSession={setRecordingSession}
                                        setLoadingPercent={setUploadPercent}
                                    />
                                </div>
                            </>
                        )

                    case TabEnum.NOTES:
                        return (
                            <>
                                {playbackTab(false)}
                                {analyticsTab(false)}
                                <div className="pb-5">
                                    <NotesTab
                                        currentRecording={currentRecording}
                                        currentProject={props.currentProject}
                                        currentBillableUnit={props.currentBillableUnit}
                                        showDemoModal={showDemoModal}
                                        setShowDemoModal={setShowDemoModal}
                                        showHelpModal={showHelpModal}
                                        setShowHelpModal={setShowHelpModal}
                                        setPageState={setPageState}
                                        setRecordingSession={setRecordingSession}
                                    />
                                </div>
                            </>
                        )
                }

            default:
            case PageState.LOADING:
                return (
                    <Card
                        className="shadow-sm rounded-4 border-0 text-start mb-1"
                        style={{ ...HIGHLIGHT_DEMO_OBJECT_STYLE, minHeight: 200 }}
                    >
                        <Card.Body className="p-1">
                            <LoadingContainer />
                        </Card.Body>
                    </Card>
                )
        }
    }

    const breadCrumbs = () => {
        return {
            trail: [
                {
                    title: 'Home /',
                    route: OrganisationRoutes.toHome(props.currentBillableUnit?.organisation.uid),
                } as BreadCrumb,
                {
                    title: 'Recordings /',
                    route: ProjectRoutes.toRecordingsList(props.currentProject?.uid),
                } as BreadCrumb,
            ],
            current: {
                title: 'Recording session',
                route: undefined,
            } as BreadCrumb,
        } as BreadCrumbs
    }

    const getLoadingText = (pageState: PageState) => {
        switch (pageState) {
            case PageState.DELETING:
                return 'Deleting...'

            case PageState.SAVING:
                return 'Saving...'

            case PageState.UPLOADING:
                return 'Uploading...'

            case PageState.LOADING_VIDEO:
                return 'Loading video...'

            default:
                return ''
        }
    }

    return (
        <div className="d-flex">
            <NavigationBar
                billableUnits={props.billableUnits}
                currentUser={props.currentUser}
                currentBillableUnit={props.currentBillableUnit}
                projects={props.currentBillableUnit?.projects || []}
                currentProject={props.currentProject}
                breadCrumbs={breadCrumbs()}
                setHelpOverlayFunction={setShowHelpModal}
                setDemoOverlayFunction={setShowDemoModal}
                isDemoGuideActive={showDemoModal}
            />
            <Container fluid className="mt-5 d-flex flex-column">
                <div className="mt-3">{pageHeader()}</div>
                <div>{isDemo() && demoMessage()}</div>
                <div className="mt-2 text-start">{tabs()}</div>
                <div className="text-start">{pageBody()}</div>
            </Container>

            {/* Modals below */}
            <LoadingOverlayModal
                percent={uploadPercent}
                text={getLoadingText(pageState)}
                show={
                    pageState === PageState.SAVING ||
                    pageState === PageState.UPLOADING ||
                    pageState === PageState.DELETING ||
                    pageState === PageState.LOADING_VIDEO
                }
            />

            <HelpOverlayModalModal
                handleCloseFunction={() => {
                    setShowHelpModal(false)
                    setShowDemoModal(false)
                }}
                show={showHelpModal || showDemoModal}
            />
        </div>
    )
}
