import { NavLink } from 'react-router-dom'
import { ClickableArrowIcon, DeleteIcon, ErrorIcon } from '../assets/Icons'
import { ProjectRoutes } from '../Routes'
import { BrokerContainer, Project, UserBillableUnitInfo } from '../api/CloudApi/types'
import { Permission, hasPermission } from '../utils/permission'
import CloudApi from '../api/CloudApi'

interface BrokerListItemProps {
    currentProject: Project
    currentBillableUnit: UserBillableUnitInfo
    broker: BrokerContainer
    deleteBrokerFunction?: (brokerName: string) => void
    useProjectNameInsteadOfUrl?: boolean
}

export default function BrokerListItem(props: BrokerListItemProps) {
    const getSubtitle = () => {
        return props.useProjectNameInsteadOfUrl ? (
            <>
                In project <b>{props.currentProject.displayName}</b>
            </>
        ) : (
            props.broker.url
        )
    }

    const brokerListItem = () => {
        return (
            <div className="d-flex mb-1">
                <div className="col-7 col-md-8 p-1 border-end text-start">
                    <NavLink
                        reloadDocument={props.useProjectNameInsteadOfUrl}
                        to={ProjectRoutes.toBrokerDetails(props.currentProject?.uid, props.broker.name)}
                        className={
                            'text-dark text-decoration-none remotive-btn-no-bg d-flex align-items-center justify-content-between'
                        }
                    >
                        <div className="text-truncate">
                            <p className="m-0 remotive-font-md text-truncate">{props.broker.shortName}</p>
                            <p className="m-0 remotive-font-sm text-secondary text-truncate d-flex align-items-center">
                                {getSubtitle() || (
                                    <>
                                        <ErrorIcon sx={{ fontSize: 15 }} className="remotive-warning-100-color me-1" />
                                        This broker has not properly started, please remove and try again.
                                    </>
                                )}
                            </p>
                        </div>
                        <ClickableArrowIcon className="ms-2" />
                    </NavLink>
                </div>
                <div className="col-4 col-md-3 p-1 px-2 text-start">
                    <p className="remotive-font-md text-truncate mb-0">Creator</p>
                    <p className="remotive-font-sm text-secondary mb-0 text-truncate">
                        {props.broker.creator?.displayName || '-'}
                    </p>
                </div>
                <div className="col-1 px-1 d-flex align-items-center justify-content-end">
                    {props.deleteBrokerFunction !== undefined && (
                        <button
                            className="btn remotive-btn-no-bg p-1 px-0 border-0 me-1"
                            disabled={
                                !hasPermission(
                                    Permission.PROJECT_EDITOR_BROKER,
                                    props.currentBillableUnit,
                                    props.currentProject
                                )
                            }
                            onClick={() => props.deleteBrokerFunction!(props.broker.name)}
                        >
                            <DeleteIcon sx={{ fontSize: 20 }} />
                        </button>
                    )}
                </div>
            </div>
        )
    }

    return brokerListItem()
}
