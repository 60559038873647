export enum PageState {
    LOADING,
    LOADING_VIDEO,
    DONE,
    ERROR,
    UPLOADING,
    DELETING,
    SAVING,
    CRUNCHING,
    NO_SIGNALS
}