import { NotFoundIcon } from '../assets/Icons'

interface NotFoundContanerProps {
    infoText?: string
    secondaryText?: string
}

export default function NotFoundContaner(props: NotFoundContanerProps) {
    return (
        <>
            <div className="text-center mt-5 mb-5">
                <NotFoundIcon className="remotive-primary-50-color" sx={{ fontSize: 35 }} />
                <p className="fs-5 m-0 p-0">{props.infoText || "We couldn't find what you were looking for..."}</p>
                <p className="text-secondary remotive-font-md">{props.secondaryText || ''}</p>
            </div>
        </>
    )
}
