import { BrokerContainer, RecordingSession } from '../api/CloudApi/types'
import BrokerApi from '../api/BrokerApi'
import { BrokerCloudContext, BrokerDetails } from '../api/BrokerApi/types'
import { useEffect } from 'react'
import { getBrokerUrl, getUserAuthToken } from '../utils/broker'
import BrokerAppWrapper from './BrokerAppWrapper'

interface BrokerAppWithMountedRecordingProps {
    brokerContainer?: BrokerContainer
    availableBrokers: Array<BrokerContainer> | undefined
    setBrokerContainer: (brokerContainer: BrokerContainer | undefined) => void
    currentRecordingSession: RecordingSession | undefined
}

export default function BrokerAppWithMountedRecording(props: BrokerAppWithMountedRecordingProps) {
    useEffect(() => {
        const checkIfSomethingIsPlaying = async () => {
            if (props.brokerContainer !== undefined) {
                // Do nothing, it will simply render the broker app
            } else {
                await setBrokerContainerFromLocalStorage()
            }
        }

        if (props.currentRecordingSession !== undefined && props.availableBrokers !== undefined) {
            checkIfSomethingIsPlaying()
        }
    }, [props.currentRecordingSession, props.availableBrokers])

    const getCloudContext = async (
        brokerContainerToCheck: BrokerContainer
    ): Promise<BrokerCloudContext | undefined> => {
        const brokerGrpcClient = new BrokerApi(
            {
                brokerUrl: brokerContainerToCheck.url,
                brokerAccessToken: getUserAuthToken(),
                isCloudBroker: () => true,
            } as BrokerDetails,
            () => console.log('unused'),
            () => console.log('unused')
        )
        try {
            const contents = await brokerGrpcClient.downloadAsString('.cloud.context')
            if (contents) {
                const context: BrokerCloudContext = JSON.parse(contents)
                return context
            } else {
                return undefined
            }
        } catch (error: any) {
            console.log('Failed to download cloud context')
            console.log(error)
        }
    }

    const setBrokerContainerFromLocalStorage = async () => {
        console.log('Setting broker from local storage')
        const brokerUrl = getBrokerUrl()
        const brokerContainer = (props.availableBrokers ?? []).find((it) => it.url === brokerUrl)
        console.log(`BrokerUrl=${brokerUrl} and container=${brokerContainer}`)
        if (brokerContainer !== undefined) {
            const isMounted = await isRecordingMounted(brokerContainer)
            if (isMounted) {
                props.setBrokerContainer(brokerContainer)
            } else {
                props.setBrokerContainer(undefined)
            }
        }
    }

    const isRecordingMounted = async (brokerContainerToCheck: BrokerContainer) => {
        const cloudContext = await getCloudContext(brokerContainerToCheck)
        const thisRecordingSessionId = props.currentRecordingSession?.sessionId
        console.log(`From cloudCOntext=${cloudContext?.recordingSessionId}, this sessionId=${thisRecordingSessionId}`)
        if (cloudContext !== undefined && thisRecordingSessionId !== undefined) {
            return cloudContext.recordingSessionId === thisRecordingSessionId
        }
        return false
    }

    return props.brokerContainer !== undefined ? <BrokerAppWrapper brokerContainer={props.brokerContainer} /> : <></>
}
