import { useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import { AuthenticatedUser, Project, UserBillableUnitInfo } from '../../api/CloudApi/types'
import { ProfileIcon } from '../../assets/Icons'
import {
    RL_DOCS_BASE_URL,
    RL_DOCS_CLOUD_BROKERS_URL,
    RL_DOCS_CLOUD_RECORDINGS_UPLOAD_URL,
    RL_DOCS_CLOUD_SIGNAL_DB_UPLOAD_URL,
    RL_DOCS_EVALUTION_LICENSE_URL,
} from '../../assets/ExternalLInks'

interface WelcomeModalProps {
    show: boolean
    handleCloseFunction: () => void
}

export default function WelcomeModal(props: WelcomeModalProps) {
    useEffect(() => {
        console.debug('Mounted welcome modal!')
    }, [])

    const closeModal = () => {
        props.handleCloseFunction()
    }

    const uploadRecordingStep = () => {
        return (
            <div className="d-flex align-items-center flex-column">
                <div style={{ height: 160 }}>
                    <img
                        width={'auto'}
                        style={{ marginTop: 10 }}
                        height={100}
                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_SELECT_RECORDING.png'}
                    ></img>
                </div>
                <p className="remotive-font lexend-bold mt-3 mb-1">1. Upload or select a recording</p>
                <p style={{ maxWidth: 400 }} className="text-start remotive-font-sm px-4">
                    Upload your own recording or select an available drive cycle recording to replay the signal data.
                </p>
            </div>
        )
    }

    const playRecordingStep = () => {
        return (
            <div className="d-flex align-items-center flex-column">
                <div style={{ height: 160 }}>
                    <img
                        width={'auto'}
                        style={{ marginTop: 45 }}
                        height={100}
                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_PLAY.png'}
                    ></img>
                </div>
                <p className="remotive-font lexend-bold mt-3 mb-1">2. Play the recording</p>
                <p style={{ maxWidth: 400 }} className="text-start remotive-font-sm px-4">
                    Change configuration or use the default, then simply play the recording.
                </p>
            </div>
        )
    }

    const workWithDataStep = () => {
        return (
            <div className="d-flex align-items-center flex-column">
                <div style={{ height: 160, width: 60 }}>
                    <img
                        style={{ marginTop: 10 }}
                        width={'auto'}
                        height={80}
                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_ANALYZE.png'}
                    ></img>
                    <img
                        style={{ marginTop: -50, marginLeft: -80 }}
                        width={'auto'}
                        height={80}
                        src={'https://releases.beamylabs.com/cloud-console-assets/WELCOME_BROKER_APP.png'}
                    ></img>
                </div>
                <p className="remotive-font lexend-bold mt-3 mb-1">3. Work with the data</p>
                <p style={{ maxWidth: 400 }} className="text-start remotive-font-sm px-4">
                    Select signal visualizations like a graph or a map, or stream signal data to any external
                    application.
                </p>
            </div>
        )
    }

    const keyResourcesText = () => {
        return (
            <div className="my-4 mt-5 px-5 d-flex flex-column align-items-center justify-content-center ">
                <p className="fs-5 lexend-bold">Key resources to get you started</p>
                <div style={{ maxWidth: 800 }} className="text-start remotive-font">
                    <li className="mb-2">
                        <a className="remotive-success-70-color" href={RL_DOCS_BASE_URL}>
                            Our user guide
                        </a>
                        , learn how to{' '}
                        <a className="remotive-success-70-color" href={RL_DOCS_CLOUD_BROKERS_URL}>
                            run cloud brokers
                        </a>
                        ,{' '}
                        <a className="remotive-success-70-color" href={RL_DOCS_CLOUD_RECORDINGS_UPLOAD_URL}>
                            upload recordings
                        </a>
                        ,{' '}
                        <a className="remotive-success-70-color" href={RL_DOCS_CLOUD_SIGNAL_DB_UPLOAD_URL}>
                            upload database files
                        </a>{' '}
                        & more
                    </li>
                    <li>
                        Want to record signals yourself? Learn how to get a{' '}
                        <a className="remotive-success-70-color" href={RL_DOCS_EVALUTION_LICENSE_URL}>
                            free 30 days hardware evaluation licence
                        </a>
                    </li>
                </div>
            </div>
        )
    }

    const closeAction = () => {
        props.handleCloseFunction()
    }

    const closeButton = () => {
        return (
            <button onClick={() => closeAction()} className="mb-4 mt-3 btn remotive-btn remotive-btn-success">
                Okay, let's get stuff done!
            </button>
        )
    }

    return (
        <>
            <Modal
                size="xl"
                contentClassName='rounded-5'
                className="m-0 p-0 border-0 lexend-regular"
                show={props.show}
                onHide={() => closeModal()}
            >
                <Modal.Body className="">
                    <>
                        <p className="text-center fs-3 lexend-bold mt-5 mb-1">Welcome to RemotiveCloud!</p>
                        <p className="text-center fs-5 lexend-bold mb-4 text-secondary">
                            Get started in three simple steps
                        </p>
                        <div className="mb-3 d-flex flex-wrap">
                            <div className="col-12 col-lg-4 my-3">{uploadRecordingStep()}</div>
                            <div className="col-12 col-lg-4 my-3">{playRecordingStep()}</div>
                            <div className="col-12 col-lg-4 my-3">{workWithDataStep()}</div>
                        </div>
                        <>{keyResourcesText()}</>
                        <div className="d-flex justify-content-center">{closeButton()}</div>
                    </>
                </Modal.Body>
            </Modal>
        </>
    )
}
