import { BrokerContainer, Project, UserBillableUnitInfo } from '../api/CloudApi/types'

type RecentBroker = {
    broker: BrokerContainer
    project: Project
    billableUnit: UserBillableUnitInfo
}

const LOCAL_STORAGE_KEY_RECENT_BROKERS = 'LS_RECENT_BROKERS'
const MAX_RECENT_BROKERS_TO_STORE = 3

export const getRecentBrokers = (currentBillableUnit?: UserBillableUnitInfo) => {
    try {
        const recentBrokersAsString = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_BROKERS)
        if (recentBrokersAsString !== null && currentBillableUnit !== undefined) {
            const recentBrokers = (JSON.parse(recentBrokersAsString) as Array<RecentBroker>).filter(
                (it) => it.billableUnit.organisation.uid === currentBillableUnit.organisation.uid
            )
            return recentBrokers
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent brokers, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_BROKERS)
    }
    return undefined
}

export const storeRecentBroker = (broker: BrokerContainer, project: Project, billableUnit: UserBillableUnitInfo) => {
    try {
        const recentBrokersAsText = localStorage.getItem(LOCAL_STORAGE_KEY_RECENT_BROKERS)
        const thisBroker = {
            broker: broker,
            project: project,
            billableUnit: billableUnit,
        } as RecentBroker
        if (recentBrokersAsText !== null) {
            const recentBrokers = JSON.parse(recentBrokersAsText) as Array<RecentBroker>
            const brokerIsNotAlreadyInList =
                recentBrokers.find(
                    (it) =>
                        it.billableUnit.organisation.uid === billableUnit.organisation.uid &&
                        it.project.uid === project.uid &&
                        it.broker.name === broker.name
                ) === undefined
            if (brokerIsNotAlreadyInList) {
                recentBrokers.unshift(thisBroker)
                localStorage.setItem(
                    LOCAL_STORAGE_KEY_RECENT_BROKERS,
                    JSON.stringify(recentBrokers.slice(0, MAX_RECENT_BROKERS_TO_STORE))
                )
            }
        } else {
            localStorage.setItem(LOCAL_STORAGE_KEY_RECENT_BROKERS, JSON.stringify([thisBroker]))
        }
    } catch (e: any) {
        console.error('Failed to parse stored recent brokers, remove it from local storage completely')
        localStorage.removeItem(LOCAL_STORAGE_KEY_RECENT_BROKERS)
    }
}
