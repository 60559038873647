import { Project, UserBillableUnitInfo } from '../api/CloudApi/types'

export enum Permission {
    // View
    PROJECT_VIEWER_BROKER = 'project/broker/view',
    PROJECT_VIEWER_RECORDING = 'project/recording/view',
    PROJECT_VIEWER_CONFIG = 'project/config/view',
    PROJECT_VIEWER_ADMIN = 'project/admin/view',
    ORG_VIEWER_LICENSE = 'org/license/view',
    ORG_VIEWER_USER = 'org/user/view',
    // Edit
    PROJECT_EDITOR_BROKER = 'project/broker/edit',
    PROJECT_EDITOR_RECORDING = 'project/recording/edit',
    PROJECT_EDITOR_CONFIG = 'project/config/edit',
    PROJECT_EDITOR_ADMIN = 'project/admin/edit',
    ORG_EDITOR_PROJECT = 'org/project/edit',
    ORG_EDITOR_USER = 'org/user/edit',
}

export function hasPermission(
    permission: Permission,
    billableUnit: UserBillableUnitInfo | undefined,
    project: Project | undefined
) {
    return (
        (billableUnit && billableUnit.organisation.permissions.includes(permission.toString())) ||
        (project && project.permissions?.includes(permission.toString()))
    )
}
