export function isDemo() {
    return (
        window.location.hostname.startsWith('demo') ||
        window.location.hostname.startsWith('console.demo') ||
        localStorage.getItem('isDemo') === 'true'
    )
}

export function isGuidedDemo() {
    return localStorage.getItem('isGuidedDemo') === 'true'
}

export function setGuidedDemo(isGuidedDemo: boolean) {
    localStorage.setItem('isGuidedDemo', `${isGuidedDemo}`)
}
