import { ErrorIcon } from '../assets/Icons'

interface ErrorContainerProps {
    errorText?: string

    errorSubText?: string
}

export default function ErrorContainer(props: ErrorContainerProps) {
    return (
        <>
            <div className="text-center h-100 mt-5 mb-5">
                <div className="">
                    <ErrorIcon sx={{ fontSize: 35 }} className="me-1 remotive-warning-100-color" />
                    <p className="fs-5 m-0">{props.errorText || 'An unknown error occurred...'}</p>
                </div>
                <p className="remotive-font-md mb-0 text-secondary" style={{ marginTop: '-5px' }}>
                    {props.errorSubText || 'Please try again later.'}
                </p>
            </div>
        </>
    )
}
