import { MetaData, NetworkInfo, SignalId } from 'remotivelabs-grpc-web-stubs'

export class Frame {
    public name: string
    public namespace: string
    public metadata: MetaData | undefined
    public signals: Array<Signal>

    constructor(
        name: string | undefined,
        namespace: string | undefined,
        metadata: MetaData | undefined,
        signals: Array<Signal> = []
    ) {
        this.name = name ? name : 'No name'
        this.namespace = namespace ? namespace : 'No namespace'
        this.metadata = metadata
        this.signals = signals
    }

    public add(signal: Signal) {
        this.signals.push(signal)
    }
}

export class Signal {
    public name: string
    public metadata: MetaData | undefined

    constructor(name: string | undefined, metadata: MetaData | undefined) {
        this.name = name ? name : 'No name'
        this.metadata = metadata
    }
}

export enum SignalType {
    NUMBER,
    RAW,
    ARBITRATION,
}

export class SignalValue {
    constructor(
        public value: number | number[] | string[] | string,
        public name: string,
        public timestampeMs: number,
        public nameSpace: string
    ) {}
}

export class Namespace {
    public name: string
    public type: string

    constructor(interfacee: NetworkInfo) {
        this.name = interfacee.getNamespace() ? (interfacee.getNamespace()?.getName() as string) : 'No name'
        this.type = interfacee.getType()
    }
}

export class FullDiskError extends Error {}

export interface BrokerDetails {
    brokerUrl: string
    brokerApiKey: string | undefined
    brokerAccessToken: string | undefined
    isCloudBroker: () => boolean
    isConnectedToBrokerAccessPoint: () => boolean
}

export interface ConfigurationInfo {
    jsonString: string
    infoMessage: string | undefined
}

// TODO - Files and paths

export interface PlaybackStateFile {
    path(): string

    namespace(): string

    downloadUrl(): string | undefined
}

export interface RecordingState {
    isRecording(): boolean

    isStopped(): boolean

    files(): Array<PlaybackStateFile>

    currentOffsetDurationMs(): number

    startTimestampMs(): number

    stop(): void
}

export interface PlaybackState {
    debug(): string

    currentOffsetDurationMs(): number

    startTimestampMs(): number

    endTimestampMs(): number

    totalDurationMs(): number

    isPlaying(): boolean

    isStopped(): boolean

    isPaused(): boolean

    isRecording(): boolean

    isSeeking(): boolean

    files(): Array<PlaybackStateFile>

    play(): Promise<any>

    pause(): Promise<any>

    stop(): Promise<any>

    seek(seekOffsetMs: number): void
}

export type CreateRecordingSession = {
    recordings: Array<CreateRecording>
}

export type CreateRecording = {
    namespace: string
    filename: string
}

export type BrokerCloudContext = {
    project: string
    recordingSessionId: string
    organisation: string | undefined
}

export type Subscription = {
    signalId: SignalId
    subscriptionId: string
    onSignalCallback: (signalValue: SignalValue, signalType: SignalType) => void
    callbackOnlyOnChange: boolean
    onEndCallback: () => void | undefined
}

export type SubscriptionId = {
    id: string
}
